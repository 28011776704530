import React from 'react';
import { Tag } from 'antd';

export default function FiltersView({ value, onChange, ignoredKeys }) {
  const filters = { ...value };
  if (ignoredKeys) {
    ignoredKeys.forEach(ignoredKey => {
      delete filters[ignoredKey];
    }); 
  }

  function handleRemove(key) {
    const newValue = { ...value };
    delete newValue[key];
    onChange(newValue);
  }

  return (
    <>
      {filters && Object.keys(filters).filter(key => !!value[key]).map(key => (
        <Tag closable onClose={() => handleRemove(key)} key={key} color='blue'>
          {key}: {Array.isArray(value[key]) ? value[key].join(', ') : value[key]}
        </Tag>
      ))}
    </>
  );
}
