import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { produce } from "immer";
import { Prompt } from "react-router-dom";
import { isEmpty, find, remove, isEqual } from "lodash";
import {
  Segment,
  Form,
  Button,
  Message,
  Icon,
} from "semantic-ui-react";
import { Alert, Collapse } from "antd";
import { NotificationManager } from "react-notifications";
import config from "react-global-configuration";

import apiEnrichments from "../../api/apiEnrichments";
import Href from "../../shared/fields/Href";
import PrivateComponent from "../../common/PrivateComponent";
import ListOfListsField from "../../shared/fields/ListOfListsField";
import ColumnsEditor from "./ColumnsEditor/ColumnsEditor";
import CatalogItemActions from "./CatalogItemActions";
import EnrichmentsMetadata from "./EnrichmentsMetadata";
import EnrichmentsData from "./EnrichmentsData";
import { METADATA_READ } from "../../shared/data/permissions";
import ScrollButton from "../../shared/fields/ScrollButton";
import { TagsEditor } from "./metadata-new/tags/TagsEditor";
import { useQuery } from "react-query";
import apiClient from "../../api/apiClient";

const { Panel } = Collapse;
const requiredFielsNames = [
  "display_name",
  "enrichment_type",
  "enrichment_version",
  "creator",
  "owners",
  "quality_score",
  "description",
  "use_cases",
  "categories",
  "region_tags",
];

let requiredFiels = {};
requiredFielsNames.forEach((f) => (requiredFiels[f] = true));

export default function CatalogItemEdit({
  enrichmentName,
  metadataStage,
  auth,
}) {
  const dispatch = useDispatch();
  const [metadataItemOriginal, setMetadataItemOriginal] = useState({});
  const [metadataItem, setMetadataItem] = useState({});
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  const { data: ontologiesOptions } = useQuery({
    queryKey: [`/edm_static_ontologies`],
    queryFn: () =>
      apiClient.get(`/edm_static_ontologies`).then((response) => {
        return response.data;
      }),
    select: (data) => data.items.map((item) => item.name),
  });

  const isDirty = useMemo(
    () => !isEqual(metadataItemOriginal, metadataItem),
    [metadataItemOriginal, metadataItem]
  );

  const isAdmin = useMemo(
    () => auth.permissions.includes(METADATA_READ),
    [auth.permissions]
  );

  useEffect(() => {
    document.title = `Edit ${enrichmentName} from ${metadataStage}`;
    onLoad();
    // eslint-disable-next-line
  }, []);

  async function onLoad() {
    loadEnrichmentMetadata();
  }

  async function loadEnrichmentMetadata(showSpiner = true) {
    if (showSpiner) {
      setLoading(true);
    }

    try {
      const { data } = await apiEnrichments.getEnrichmentMetadata(
        metadataStage,
        enrichmentName
      );
      if (isEmpty(data)) {
        NotificationManager.error(`Metadata not found for ${enrichmentName}`);
        return;
      }

      setMetadataItemOriginal(data);
      setMetadataItem(data);
    } catch (error) {
      NotificationManager.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  async function saveData() {
    const commitMessage = prompt("Commit message");
    if (commitMessage === null) return;

    if (commitMessage.trim() === "") {
      NotificationManager.info("A commit message cannot be empty");
      return;
    }

    setSaving(true);

    try {
      const result = await apiEnrichments.emliteUpdateEnrichmentMetadata(
        metadataStage,
        enrichmentName,
        auth.profile.user,
        auth.profile.email,
        commitMessage,
        metadataItem
      );

      if (result.status === "failure") {
        NotificationManager.error(result.message);
      } else {
        setSaveSuccess(true);
        await loadEnrichmentMetadata(false);

        setTimeout(() => {
          setSaveSuccess(false);
        }, 3000);
      }
    } catch (error) {
      NotificationManager.error(error.response.data.errorMessage);
    } finally {
      setSaving(false);
    }
  }

  function onColumnsChange(columns) {
    setMetadataItem(
      produce((d) => {
        d.signals = columns;
      })
    );
  }

  useEffect(() => {
    if (isDirty) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }, [isDirty]);

  if (isEmpty(metadataItem)) return null;

  return (
    <Segment>
      <ScrollButton />
      {metadataItem.properties.deprecated && (
        <Alert
          type="warning"
          showIcon
          message="Deprecated"
          style={{ margin: "0 0 24px" }}
        />
      )}

      <Form success={saveSuccess} loading={loading}>
        <PrivateComponent
          requiredPermissions={METADATA_READ}
          currentPermissions={auth.permissions}
        >
          <CatalogItemActions
            fullSize
            metadataStage={metadataStage}
            enrichments_metadata={metadataItem}
          />
        </PrivateComponent>

        {isDirty && metadataItem.enrichment_type !== "legacy" && (
          <Button
            htmlType="submit"
            floated="right"
            onClick={saveData}
            loading={saving}
            color="green"
            type="primary"
            size="mini"
          >
            <Icon name="save" /> Save
          </Button>
        )}

        <Message success header="Data Saved" />
        <Message error header="Required fields are missing..." />

        {metadataStage === config.get("primaryStage") && (
          <PrivateComponent
            requiredPermissions={METADATA_READ}
            currentPermissions={auth.permissions}
          >
            <Href href={`${enrichmentName}/tenants`}>
              <Button>Tenants Settings</Button>
            </Href>
            <br />
          </PrivateComponent>
        )}
        <br />
        <br />

        <Collapse
          defaultActiveKey={JSON.parse(
            localStorage.getItem("metadata/activeKeys")
          )}
          onChange={(v) =>
            localStorage.setItem("metadata/activeKeys", JSON.stringify(v))
          }
        >
          <Panel header="Properties" key="1">
            <EnrichmentsMetadata
              originalValue={metadataItemOriginal.properties}
              value={metadataItem.properties}
              onChange={(nextProperties) => {
                setMetadataItem(
                  produce((d) => {
                    d.properties = nextProperties;
                  })
                );
              }}
            />
          </Panel>
          <Panel header="Tags" key="2">
            <TagsEditor
              metadataStage={metadataStage}
              tags={metadataItem.tags}
              onDelete={(tagName) => {
                setMetadataItem(
                  produce((d) => {
                    remove(d.tags, (tag) => Object.keys(tag)[0] === tagName);
                  })
                );
              }}
              onChange={(tagName, values) => {
                setMetadataItem(
                  produce((d) => {
                    const tagDraft = find(d.tags, (tag) => {
                      const name = Object.keys(tag)[0];
                      return name === tagName;
                    });

                    if (!tagDraft) {
                      // New tag, set it + selected values.
                      d.tags.push({
                        [tagName]: values,
                      });
                    } else {
                      // Tag exists, edit selected values.
                      tagDraft[tagName] = values;
                    }
                  })
                );
              }}
              // metadataStage={metadataStage}
              // enrichmentName={enrichmentName}
              // restrictedUseCases={restrictedUseCases}
              // loading={loading}
            />
          </Panel>

          <Panel header="Supported Ontologies" key="3">
            <Form.Field>
              <ListOfListsField
                selectableItems={ontologiesOptions ?? []}
                value={metadataItem.properties.input_ontologies ?? []}
                onChange={(value) => {
                  setMetadataItem(
                    produce((draft) => {
                      draft.properties.input_ontologies = value;
                    })
                  );
                }}
              />
            </Form.Field>
          </Panel>

          <Panel header="Signals" key="4">
            <ColumnsEditor
              ontologies={[]}
              columns={metadataItem.signals}
              onChange={onColumnsChange}
              metadataStage={metadataStage}
              enrichmentName={enrichmentName}
            />
          </Panel>

          <Panel header="Data Sample" key="6">
            {!isEmpty(metadataItem) && (
              <EnrichmentsData
                metadataStage={metadataStage}
                enrichmentName={enrichmentName}
                pageSize={20}
              />
            )}
          </Panel>
        </Collapse>
      </Form>

      <Prompt
        when={isDirty}
        message="You have unsaved changes, are you sure you want to leave?"
      />
    </Segment>
  );
}
