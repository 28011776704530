import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Confirm, Checkbox, Dropdown } from 'semantic-ui-react';

import { removeEnrichmentsMetadata, copyEnrichmentsMetadata, deprecateEnrichment } from '../../redux/actions/enrichmentsActions';
import { addEnrichmentSettingsToAllTenants } from '../../redux/actions/tenantsActions';
import { enrichmentsMetadataStages } from '../../shared/data/tables';
import CatalogItemCopyParams from './CatalogItemCopyParams';
import RemoveButton from '../../shared/fields/RemoveButton';
import ActionsButton from './ActionsButton';
import LegacyEnrichmentWarning from "../Enrichments/LegacyEnrichmentWarning";

const stagesOptions = enrichmentsMetadataStages.map((stage) => { return { value: stage, text: stage, key: stage }; });

export default function CatalogItemActions(props) {
  const { metadataStage, enrichments_metadata, fullSize, disabled, onRemove } = props;
  const dispatch = useDispatch();
  const [copyIsOpened, setCopyIsOpened] = useState(false);
  const [deprecateIsOpened, setDeprecateIsOpened] = useState(false);
  const [updatedSettingsIsOpened, setUpdatedSettingsIsOpened] = useState(false);
  const [removeData, setRemoveData] = useState(false);
  const [removeTenansSettings, setRemoveTenansSettings] = useState(false);
  const { name: enrichmentName } = enrichments_metadata.properties;
  const [actionStages, setActionStages] = useState([]);
  const [destinationEnrichmentName, setDestinationEnrichmentName] = useState(enrichmentName);
  const [enrichmentDeprecated, setEnrichmentDeprecated] = useState(false);

  useEffect(() => {
    setDestinationEnrichmentName(enrichmentName);
  }, [enrichmentName]);

  useEffect(() => {
    setEnrichmentDeprecated("deprecated_executor" in enrichments_metadata)
  }, [enrichments_metadata]);

  function clearActionStages() {
    setActionStages([]);
  }

  function onActionStagesChange(e, { value }) {
    setActionStages(value);
  }

  function remove() {
    actionStages.forEach(stage => {
      dispatch(removeEnrichmentsMetadata(stage, enrichmentName, removeData, removeTenansSettings, !fullSize));
    });
    clearActionStages();
    if (onRemove) onRemove();
  }

  function copy() {
    setCopyIsOpened(false);
    actionStages.forEach(stage => {
      dispatch(copyEnrichmentsMetadata(metadataStage, enrichmentName, stage, destinationEnrichmentName));
    });
    clearActionStages();
  }

  function getDeprecateHeader() {
    if (enrichmentDeprecated) {
      return <div>
        <h3 style={{ padding: 10 }}>This enrichment has been flagged as deprecated, confirming will restore it to its original functionality</h3>
        <h4 style={{ padding: 10 }}>Meaning that queries sent to this enrichment will be executed through the deployed enrichment flow</h4>
      </div>
    } else {
      return <div>
        <h3 style={{ padding: 10 }}>This enrichment will be deprecated, this procedure is reversible</h3>
        <h4 style={{ padding: 10 }}>Any query to this enrichment will return empty from now on</h4>
      </div>
    }
  }

  function deprecate() {
    setDeprecateIsOpened(false);
    dispatch(deprecateEnrichment(metadataStage, enrichmentName, enrichmentDeprecated));
    setEnrichmentDeprecated(!(enrichmentDeprecated))
    clearActionStages();
  }

  function onCopyParamsChange(destinationStages, destinationEnrichmentName) {
    setActionStages(destinationStages);
    setDestinationEnrichmentName(destinationEnrichmentName);
  }

  function updatedSettings() {
    setUpdatedSettingsIsOpened(false);
    dispatch(addEnrichmentSettingsToAllTenants(enrichmentName));
  }

  return (
    <>
      <RemoveButton
        disabled={disabled}
        fullSize={fullSize}
        onClick={remove}
        onCancel={() => {setRemoveData(false)}}
        confirmButton={actionStages.length === 0 ? false : undefined}
        confirmHeader={<h3 style={{ padding: 10 }}>You are going to remove [{enrichmentName}] from:</h3>}
        confirmContent={
          <div style={{ padding: 15, minHeight: 200 }}>
            <Dropdown placeholder='Stages' fluid multiple search selection options={stagesOptions} value={actionStages} onChange={onActionStagesChange}/>
            <div style={{ paddingLeft: 15, paddingTop: 15 }}>
              <Checkbox
                checked={removeData}
                onChange={() => setRemoveData(!removeData)}
                label='remove static data'
              />
            </div>
            <div style={{ paddingLeft: 15, paddingTop: 5, paddingBottom: 15 }}>
              <Checkbox
                checked={removeTenansSettings}
                onChange={() => setRemoveTenansSettings(!removeTenansSettings)}
                label='remove tenants settings'
              />
            </div>
            { removeData &&
              <h5 style={{ color: 'red', textAlign: 'center' }}>Make sure the static data is not connected to other environments</h5>
            }
            { removeTenansSettings &&
              <h5 style={{ color: 'red', textAlign: 'center' }}>All tenants settings for this enrichment will be removed</h5>
            }
          </div>
      }/>
      <ActionsButton disabled={disabled} icon='users' tooltip='Add enrichment to all tenants' onClick={() => setUpdatedSettingsIsOpened(true)}/>
      {/* <ActionsButton disabled={disabled} icon='copy' tooltip='Copy' onClick={openCopyConfirm}/> */}
      {/* <ActionsButton disabled={disabled} icon='cloud upload' tooltip='Update' onClick={onReupload}/> */}
      {/* <ActionsButton disabled={disabled} icon='archive' tooltip='Deprecate enrichment' onClick={onDeprecate}/> */}
      <div><span style={{color: 'red'}}>{enrichmentDeprecated ? 'Deprecated Enrichment' : ''}</span></div>
      {enrichments_metadata.enrichment_type === 'legacy' && <LegacyEnrichmentWarning/>}


      <Confirm
        style={{position: 'absolute'}}
        header={<h3 style={{ padding: 10 }}>You are going to copy enrichment [{enrichmentName}] to:</h3>}
        content={(
          <div style={{ padding: 15, minHeight: 200 }}>
            <CatalogItemCopyParams
              action='Copy'
              allowMultiple
              metadataStage={metadataStage}
              destinationStages={actionStages}
              destinationEnrichmentName={destinationEnrichmentName}
              onChange={onCopyParamsChange}
            />
          </div>
        )}
        confirmButton={actionStages.length === 0 ? false : undefined}
        open={copyIsOpened}
        onCancel={() => setCopyIsOpened(false)}
        onConfirm={copy}
      />
      <Confirm
        style={{position: 'absolute'}}
        header="You're going to add the enrichment to all tenants with disabled availability. Tenants that already have the settings will be ignored."
        open={updatedSettingsIsOpened}
        onCancel={() => setUpdatedSettingsIsOpened(false)}
        onConfirm={updatedSettings}
      />
      <Confirm
        style={{position: 'absolute'}}
        header={getDeprecateHeader()}
        open={deprecateIsOpened}
        onCancel={() => setDeprecateIsOpened(false)}
        onConfirm={deprecate}
      />
    </>
  );
}
