import React, { useState } from 'react';
import { Button, Transition, Confirm, Popup } from 'semantic-ui-react';

export default function RemoveButton(props) {
  const {
    disabled, loading, hideWhenDisabled,
    confirmHeader, tooltip, label, confirmContent, confirmButton
  } = props;
  const [isConfirm, setIsConfirm] = useState(false);

  function onConfirm() {
    setIsConfirm(false);
    props.onClick();
  }

  function onCancel() {
    setIsConfirm(false);
    if ('onCancel' in props) {
      props.onCancel();
    }
  }

  return (
    <>
      <Transition visible={!(hideWhenDisabled && disabled)} animation='scale' duration={500}>
        <Popup
          trigger={(
            <Button
              basic
              icon='trash'
              label={label}
              size='mini'
              floated='right'
              disabled={disabled || loading}
              loading={loading}
              onClick={() => setIsConfirm(true)}
            />
          )}
          content={tooltip || 'Remove'}
          inverted
          size='tiny'
        />
      </Transition>

      <Confirm open={isConfirm} confirmButton={confirmButton} onCancel={onCancel} header={confirmHeader} content={confirmContent} onConfirm={onConfirm}/>
    </>
  );
}
