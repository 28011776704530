import React, { Component } from 'react';
import { Segment, Tab } from 'semantic-ui-react';
import { invert } from 'lodash';

import history from '../../history';
import Tasks from './Tasks';

class Dashboard extends Component {
  panes = [
    { menuItem: 'Tasks', render: () => <Tasks/> },
  ];
  panesIds = {0: 'tasks', 1: 'cache'};

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: this.getActiveIndex(),
    };
  }

  getActiveIndex = () => {
    const type = this.props.match.params.type;
    if (type) {
      return (invert(this.panesIds))[type];
    } else {
      history.push({pathname: `dashboard/tasks`});
      return 0;
    }
  }

  handleTabChange = (e, {activeIndex}) => {
    history.push({pathname: `${this.panesIds[activeIndex]}`});
    this.setState({activeIndex});
  }

  render() {
    return (
      <Segment>
        <Tab menu={{pointing: true}} panes={this.panes} onTabChange={this.handleTabChange} activeIndex={this.state.activeIndex} />
      </Segment>
    );
  }
}

export default Dashboard;
