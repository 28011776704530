import React, { useState } from 'react';
import { Segment, Divider, Checkbox, Form } from 'semantic-ui-react';

import CopyToTenantsDropdown from './CopyToTenantsDropdown';

export default function CopyToTenantsParams(props) {
  const { tenantId, tenantName, allowConfig, allowEnrichments } = props;
  const allowAll = allowConfig && allowEnrichments;
  const [params, setParams] = useState({
    destinationTenantIds: [],
    copyConfig: allowConfig ? true : false,
    overwriteConfig: allowConfig ? true : false,
    copyEnrichmentsSettings: allowEnrichments ? true : false,
  });
  const {
    copyConfig,
    copyEnrichmentsSettings
  } = params;

  function onSetParams(newParams) {
    setParams(newParams);
    props.onChange(newParams);
  }

  function onDestinationTenantIdsChange(value) {
    const newParams = {...params};
    newParams.destinationTenantIds = value;

    onSetParams(newParams);
  }

  function onCopyConfigChange(value) {
    const newParams = {...params};
    newParams.copyConfig = value;

    onSetParams(newParams);
  }

  function oncopyEnrichmentsSettingsChange(value) {
    const newParams = {...params};
    newParams.copyEnrichmentsSettings = value;

    onSetParams(newParams);
  }


  return (
    <Segment basic>
      <Form>
        <Form.Field>
          <label>Destination tenants</label>
          <CopyToTenantsDropdown onChange={onDestinationTenantIdsChange} currentTenantId={tenantId} currentTenantName={tenantName}/>
        </Form.Field>

        { allowAll && (
          <>
            <Divider/>
            <Form.Field>
              <Checkbox
                label='Copy config'
                checked={copyConfig}
                onChange={() => onCopyConfigChange(!copyConfig)}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label='Copy enrichments settings'
                checked={copyEnrichmentsSettings}
                onChange={() => oncopyEnrichmentsSettingsChange(!copyEnrichmentsSettings)}
              />
            </Form.Field>
          </>
        )}
      </Form>
    </Segment>
  );
}
