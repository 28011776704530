import { NotificationManager } from 'react-notifications';

import apiClient from '../../api/apiClient';

export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS';
export const FETCH_TENANT_SUCCESS = 'FETCH_TENANT_SUCCESS';
export const FETCH_TENANTS_SUCCESS = 'FETCH_TENANTS_SUCCESS';
export const FETCH_TENANT_PROJECTS_SUCCESS = 'FETCH_TENANT_PROJECTS_SUCCESS';
export const START_REQUEST = 'START_REQUEST';
export const FINISH_REQUEST = 'FINISH_REQUEST';
export const FAILURE = 'FAILURE';

export const fetchTenantSuccess = tenant => ({ type: FETCH_TENANT_SUCCESS, tenant });

export const fetchTenantsSuccess = response => ({ type: FETCH_TENANTS_SUCCESS, hasMoreTenantConfigs: response.hasMore, tenantConfigs: response.items });

export const fetchProjectSuccess = project => ({ type: FETCH_PROJECT_SUCCESS, project });

export const fetchTenantProjectsSuccess = response => ({ type: FETCH_TENANT_PROJECTS_SUCCESS, hasMoreProjectConfigs: response.hasMore, projectConfigs: response.items });

export const fetchFailure = errorMessage => {
  NotificationManager.error(errorMessage);
  return { type: FAILURE, errorMessage };
};

export const startRequest = () => ({ type: START_REQUEST });

export const finishRequest = () => ({ type: FINISH_REQUEST });

export const fetchTenant = (tenantName) => (dispatch) => {
  dispatch(startRequest());
  apiClient.get(`/tenant_configs/${tenantName}`).then((response) => {
    dispatch(fetchTenantSuccess(response.data));
  }).catch((error) => {
    dispatch(fetchFailure(error.message));
  });
};

export const fetchTenants = () => (dispatch) => {
  dispatch(startRequest());
  apiClient.get('/tenants').then((response) => {
    dispatch(fetchTenantsSuccess(response.data));
  }).catch((error) => {
    dispatch(fetchFailure(error.message));
  });
};

export const updateTenants = (data) => (dispatch) => {
  dispatch(startRequest());
  apiClient.put('/tenants', data).then((response) => {
    NotificationManager.success(response.data);
    dispatch(finishRequest());
  }).catch((error) => {
    dispatch(fetchFailure(error.message));
  });
};

export const updateTenant = (tenantName, data) => (dispatch) => {
  dispatch(startRequest());
  apiClient.put(`/tenants/${tenantName}`, data).then((response) => {
    NotificationManager.success(response.data);
    dispatch(fetchTenant(tenantName));
  }).catch((error) => {
    dispatch(fetchFailure(error.message));
  });
};

export const addTenant = (tenantName, data) => (dispatch) => {
  dispatch(startRequest());
  apiClient.post(`/tenants/${tenantName}`, data).then((response) => {
    dispatch(fetchTenants());
    NotificationManager.success("tenant created");
  }).catch((error) => {
    dispatch(fetchFailure(error.message));
  });
};

export const addTenantSettings = (tenantName) => (dispatch) => {
  dispatch(startRequest());
  apiClient.post(`/tenants/${tenantName}/add_settings`).then((response) => {
    dispatch(finishRequest());
    NotificationManager.success(response.data);
  }).catch((error) => {
    dispatch(fetchFailure(error.message));
  });
};

export const removeTenant = (tenantName, fetchTenantsAfter=false) => (dispatch) => {
  dispatch(startRequest());
  apiClient.delete(`/tenants/${tenantName}`).then((response) => {
    NotificationManager.success(response.data);
    if (fetchTenantsAfter) dispatch(fetchTenants());
  }).catch((error) => {
    dispatch(fetchFailure(error.message));
  });
};

export const fetchTenantProject = (tenantName, projectId) => (dispatch) => {
  dispatch(startRequest());
  apiClient.get(`/tenants/${tenantName}/projects/${projectId}`).then((response) => {
    dispatch(fetchProjectSuccess(response.data));
  }).catch((error) => {
    dispatch(fetchFailure(error.message));
  });
};

export const fetchTenantProjects = (tenantName, searchText = '', pageSize = 20, lastProjectId = '') => (dispatch) => {
  dispatch(startRequest());
  apiClient.get(`/tenants/${tenantName}/projects?searchText=${searchText}&pageSize=${pageSize}&lastProjectId=${lastProjectId}`).then((response) => {
    dispatch(fetchTenantProjectsSuccess(response.data));
  }).catch((error) => {
    dispatch(fetchFailure(error.message));
  });
};

export const updateTenantProject = (tenantName, projectId, config) => (dispatch) => {
  dispatch(startRequest());
  apiClient.put(`/tenants/${tenantName}/projects/${projectId}`, { config }).then((response) => {
    NotificationManager.success(response.data);
    dispatch(fetchTenantProject(tenantName, projectId));
  }).catch((error) => {
    dispatch(fetchFailure(error.message));
  });
};

export const updateAllProjectsConfigs = (config) => (dispatch) => {
  dispatch(startRequest());
  apiClient.put(`/projects`, { config }).then((response) => {
    NotificationManager.success(response.data);
    dispatch(finishRequest());
  }).catch((error) => {
    dispatch(fetchFailure(error.message));
  });
};

export const addTenantProject = (tenantName, projectName, config) => (dispatch) => {
  dispatch(startRequest());
  apiClient.post(`/tenants/${tenantName}/projects/${projectName}`, { config }).then((response) => {
    dispatch(fetchTenantProjects(tenantName));
    NotificationManager.success(response.data);
  }).catch((error) => {
    dispatch(fetchFailure(error.message));
  });
};

export const removeTenantProject = (tenantName, projectId) => (dispatch) => {
  dispatch(startRequest());
  apiClient.delete(`/tenants/${tenantName}/projects/${projectId}`).then((response) => {
    dispatch(fetchTenantProjects(tenantName));
    NotificationManager.success(response.data);
  }).catch((error) => {
    dispatch(fetchFailure(error.message));
  });
};
