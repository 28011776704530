import React, { useEffect, useState } from "react";
import { Descriptions, Button, Tooltip } from "antd";
import { entries, isEqual, startCase } from "lodash";
import { Icon, Modal } from "semantic-ui-react";
import ReactJson from "react-json-view";
import { produce } from "immer";
import { EditFilled } from "@ant-design/icons";
import { NotificationManager } from 'react-notifications';

export default function EnrichmentsMetadata({
  originalValue,
  value,
  onChange,
}) {
  const [editorModal, setEditorModal] = useState({
    open: false,
    data: value,
  });

  const handleOpenEditor = () => {
    setEditorModal(
      produce((d) => {
        d.open = true;
      })
    );
  };

  const handleCloseEditor = () => {
    setEditorModal(
      produce((d) => {
        d.open = false;
        d.data = value;
      })
    );
  };

  const handleJsonEdit = ({ existing_src, updated_src }) => {
    if (updated_src.id !== value.id || updated_src.name !== value.name) {
      NotificationManager.error("Cannot modify ID / Name");
      return false;
    }

    setEditorModal(
      produce((d) => {
        d.data = updated_src;
      })
    );
  };

  const handleApplyEdits = () => {
    onChange(editorModal.data);
    handleCloseEditor();
  };

  const renderProperties = (values) => {
    return entries(values).map(([key, val]) => {
      if (key === "input_ontologies") return null;

      const isDirty = !isEqual(val, originalValue[key]);

      return (
        <Descriptions.Item
          key={key}
          label={
            <Tooltip
              title={
                isDirty && (
                  <>
                    <p style={{ color: "lightblue" }}>Old: {JSON.stringify(originalValue[key])}</p>
                    <p style={{ color: "orange" }}>Next: {JSON.stringify(val)}</p>
                  </>
                )
              }
            >
              <span>{startCase(key)}</span>
            </Tooltip>
          }
          labelStyle={{ color: isDirty ? "orange" : undefined }}
          span={1.5}
        >
          {String(val ?? "")}
        </Descriptions.Item>
      );
    });
  };

  // Sync data
  useEffect(() => {
    setEditorModal(
      produce((d) => {
        d.data = value;
      })
    );
  }, [value]);

  return (
    <>
      <Button
        icon={<EditFilled />}
        size="middle"
        onClick={handleOpenEditor}
        style={{ margin: "0 0 8px" }}
      >
        Edit
      </Button>
      <Descriptions size="small" bordered>
        {renderProperties(value)}
      </Descriptions>
      <Modal open={editorModal.open} onClose={handleCloseEditor}>
        <Modal.Header>Edit Properties</Modal.Header>
        <Modal.Content>
          <ReactJson
            src={editorModal.data}
            collapsed={2}
            style={{ overflowY: "scroll", maxHeight: "70vh" }}
            name={false}
            onEdit={handleJsonEdit}
            onAdd={handleJsonEdit}
            onDelete={handleJsonEdit}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button basic color="grey" inverted onClick={handleCloseEditor}>
            <Icon name="cancel" /> Cancel
          </Button>
          <Button basic color="green" inverted onClick={handleApplyEdits}>
            <Icon name="checkmark" /> Apply
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}
