import React from 'react';
import { Input, Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

export default function SearchFilterDropdown({ inputRef, title, selectedKeys, setSelectedKeys, handleReset, handleSearch, clearFilters, confirm, multiple }) {
  return (
    <div style={{ padding: 8 }}>
      <Input
        ref={inputRef}
        placeholder={title ? title : 'Search'}
        value={multiple ? selectedKeys[0] : selectedKeys}
        onChange={e => setSelectedKeys(e.target.value ? (multiple ? [e.target.value] : e.target.value) : (multiple ? [] : ''))}
        onPressEnter={() => handleSearch(selectedKeys, confirm)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          size='small'
          onClick={() => handleReset(clearFilters)}
          style={{ width: 90 }}>Reset</Button>
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined/>}
          size='small'
          style={{ width: 90 }}>Search</Button>
      </Space>
    </div>
  );
}
