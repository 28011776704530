import React, { useState, useEffect } from 'react';
import { Icon, Dimmer, Loader } from 'semantic-ui-react';
import { Collapse } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import apiStatusChecks from '../../api/apiStatusChecks';
import './statuses.css';


const { Panel } = Collapse;
const dayMilli = 60000 * 60 * 24

const Div = styled.div`
  display: flex;
  flex: 1;
`;


export default function EMStatusDashboard() {
  const [statuses, setStatuses] = useState([]);
  const [checksCategories, setChecksCategories] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadEMStatuses()
    setInterval(() => loadEMStatuses(), 60000)
  }, []);


  async function loadEMStatuses() {
    let defaultCategory = 'General checks'
    let checksCategories = {}
    let statuses = {}

    try {
      let today = new Date()
      today.setHours(0, 0, 0, 0);

      let weekAgo = new Date(today.getTime());
      weekAgo.setDate(weekAgo.getDate() - 7);

      let {data} = await apiStatusChecks.getStatusChecks(weekAgo.getTime() / 1000);

      (data || []).sort((a, b) => a.timestamp - b.timestamp).map(statusCheck => {
        let currCategory = statusCheck.category || defaultCategory
        checksCategories[statusCheck.check_name] = currCategory

        if (!statuses[currCategory])
          statuses[currCategory] = {}

        if (!statuses[currCategory][statusCheck.check_name])
          statuses[currCategory][statusCheck.check_name] = []
  
        let timestamp = statusCheck.timestamp * 1000
        let formatedDate = moment.utc(timestamp).local().format('DD/MM')
        statuses[currCategory][statusCheck.check_name].push({...statusCheck, formatedDate, timestamp})
      })
    } catch (err) {
      console.log(err)
    }

    setChecksCategories(checksCategories)
    setStatuses(statuses)
    setIsLoading(false)
  }


  function generateSevenDaysBar(isTitle, category, checkName) {
    let offset = 7;
    let days = []; 

    let today = new Date()
    today.setHours(0, 0, 0, 0);

    let startDate = new Date(today.getTime())
    startDate.setDate(startDate.getDate() - offset)

    let checkResults = (statuses[category] || {})[checkName]
    checkResults = [...(checkResults || [])]
    
    while (startDate.getTime() <= today.getTime()) {
      let formatedDate = moment.utc(startDate.getTime()).local().format('DD/MM')
      let currDayEvent = {formatedDate}

      let highestStatus = 0
      let checkTimestamp = checkResults[0] ? (checkResults[0].timestamp) : null
      
      while (checkTimestamp && startDate.getTime() <= checkTimestamp && 
             checkTimestamp < startDate.getTime() + dayMilli) {
        
        let currCheckResult = checkResults.shift()
        if (highestStatus < currCheckResult.result_status) {
          currDayEvent = {formatedDate, ...currCheckResult}
          highestStatus = currCheckResult.result_status
        }

        checkTimestamp = checkResults[0] ? (checkResults[0].timestamp) : null
      }

      days.push(currDayEvent)
      startDate.setDate(startDate.getDate() + 1);
    }

    return (
      <Div style={{height: 50 , width:'100%', paddingRight: 20}}>
        {days.map(curr => {
          let offset = 0
          if (curr.timestamp) {
            let localTime = moment.utc(curr.timestamp).local()
            let percentOfDay = localTime.hour() * 100 / 24
            offset = 50 - percentOfDay
          }

          return (
            <Div style={{justifyContent:'center', alignItems:'center', color:'#767676',borderLeft: isTitle ? null : '1px solid #d9d9d9'}}>
              {isTitle ? curr.formatedDate : getStatusIcon(curr, s => (s == 501 || s <= 200 && s < 400), {margin: `0 ${offset}% 0 0`})}
            </Div>)
          })}
      </Div>
    )
  }


  function getStatusIcon(check, ignoreFunc=status => false, customStyle={}) {
    if (!check.result_status || ignoreFunc(check.result_status))
      return null

    let lastStatus = check ? check.result_status : 500
    let iconName = 'times circle'
    let iconColor = '#ff0000cc'
    
    if (lastStatus / 100 == 2){
      iconName = 'check circle'
      iconColor = '#008000cc'
    } else if (lastStatus / 100 == 4){
      iconName = 'exclamation circle'
      iconColor = 'orange'
    } else if (lastStatus == 501) {
      iconName = 'code'
      iconColor = 'orange'
    }

    return <Icon style={{color: iconColor, fontSize:12, ...customStyle}} name={iconName}/>
  }


  function generateLastStatus(category, checkName) {
    let checkResults = (statuses[category] || {})[checkName] || []
    let lastCheckResult = checkResults[checkResults.length - 1]

    return getStatusIcon(lastCheckResult)
  }


  function generateEventLog(category, checkName) {
    let checkResults = (statuses[category] || {})[checkName] || []
    let filteredEventLog = {}
    
    checkResults.forEach(v => {
      if (!filteredEventLog[v.description])
        filteredEventLog[v.description] = []

      let currentEventList = filteredEventLog[v.description]
      if (!currentEventList.length || 
          currentEventList[currentEventList.length - 1].result_status != v.result_status || 
          currentEventList[currentEventList.length - 1].formatedDate != v.formatedDate) {
        currentEventList.push(v)
      }
    })

    return (
      <Div style={{color:'#000000aa', flexDirection:'column'}}>
        {Object.values(filteredEventLog).map(events => events.map(curr => 
          <Div style={{margin: '10px 0px'}}>
            <Div style={{justifyContent:'center', alignItems:'center'}}>{getStatusIcon(curr)}</Div>
            <Div style={{flex:2, alignItems:'center'}}>{moment.utc(curr.timestamp).local().format('LLL')}</Div>
            <Div style={{flex:8, flexDirection:'column'}}>
              <Div>
                <div style={{fontWeight: 700, marginRight: 5}}>description:</div>
                <div>{curr.description}</div>
              </Div>
              <Div>
                <div style={{fontWeight: 700, marginRight: 5}}>result description:</div>
                <div>{curr.result_description}</div>
              </Div>
              <Div>
                <div style={{fontWeight: 700, marginRight: 5}}>result latency:</div>
                <div>{Math.round(curr.duration * 100) / 100.0}s</div>
              </Div>
            </Div>
          </Div>
        ))}
      </Div>
    )
  }


  if (isLoading)
    return <Dimmer inverted active><Loader>Loading</Loader></Dimmer>

  return (
    <div style={{marginTop: 20}}>
      <Div style={{height:50, padding: '0px 16px'}}>
        <div style={{flex:1}}></div>
        <div style={{flex:3}}>{generateSevenDaysBar(true)}</div>
      </Div>

      {Object.keys(statuses).sort((a,b) => b.localeCompare(a)).map((categoryName, i) => {
        let currCategory = statuses[categoryName] || {}

        return (
          <>
            <h3 style={{marginTop: i == 0 ? -36 : 15, color:'#000000aa'}}>{categoryName}</h3>
            <Collapse
              className="site-collapse-custom-collapse"
              expandIconPosition='right'
              expandIcon={(extra) => generateLastStatus(checksCategories[extra.panelKey], extra.panelKey)}
              defaultActiveKey={JSON.parse(localStorage.getItem('metadata/activeKeys'))}
              onChange={(v) => localStorage.setItem('metadata/activeKeys', JSON.stringify(v))}
            >{
              Object.keys(currCategory).map(checkName =>
                <Panel className="site-collapse-custom-panel" header={<div style={{fontWeight:500, flex: 1}}>{checkName}</div>} key={checkName} extra={generateSevenDaysBar(false, categoryName, checkName)}>
                  {generateEventLog(categoryName, checkName)}
                </Panel>
            )}
            </Collapse>
          </>
        )
      })}
    </div>
  )
}
