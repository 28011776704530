import React, { Component } from 'react';

import CatalogItemEdit from './CatalogItemEdit';


class CatalogItem extends Component {

  constructor(props) {
    super(props);
    this.enrichmentName = this.props.match.params.table;
    this.metadataStage = this.props.match.params.metadataStage;
  }

  render() {
    return (
      <CatalogItemEdit enrichmentName={this.enrichmentName} metadataStage={this.metadataStage} auth={this.props.auth}/>
    );
  }
}

export default CatalogItem;
