import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { Button, Typography, Row } from 'antd';
import { saveAs } from 'file-saver';
import { DownloadOutlined } from '@ant-design/icons';
import { NotificationManager } from 'react-notifications';

import apiEnrichments from '../../api/apiEnrichments';
import EnrichmentDataTable from '../../common/EnrichmentDataTable';
import DangerousConfirm from '../../shared/Components/DangerousConfirm';
import {fetchEnrichmentsData} from "../../redux/actions/enrichmentsActions";

const { Text, Title } = Typography;

export default function EnrichmentsData({ metadataStage, enrichmentName, pageSize }) {
  const enrichmentsData = useSelector(state => state.enrichmentsReducer.data);
  const isLoading = useSelector(state => state.enrichmentsReducer.isDataLoading);
  const isDataFetched = useSelector(state => state.enrichmentsReducer.isDataFetched);
  const [clearingCache, setClearingCache] = useState(false);
  const [exporting, setExporting] = useState(false);
  const dispatch = useDispatch();

  function fetchData() {
    dispatch(fetchEnrichmentsData(metadataStage, enrichmentName, pageSize));
  }

  if (!isDataFetched && !isLoading){
    fetchData()
  }

  async function onExport() {
    try {
      setExporting(true);

      const response = await apiEnrichments.exportEnrichmentsDataSample(metadataStage, enrichmentName);
      saveAs(response.data, `${metadataStage}_${enrichmentName}.csv`);
    } catch(error) {
      NotificationManager.error(error.message);
    } finally {
      setExporting(false);
    }
  }

  async function handleClearCache() {
    try {
      setClearingCache(true);
      const { data } = await apiEnrichments.clearCache(metadataStage, enrichmentName);
      NotificationManager.success(data);
    } catch (error) {
      NotificationManager.error(error.message);
    } finally {
      setClearingCache(false);
    }
  }

  return (
    <>
      <EnrichmentDataTable
        loading={isLoading}
        dataSource={enrichmentsData}
      />
      <Button size='small' onClick={onExport} loading={exporting} icon={<DownloadOutlined/>} style={{ position: 'absolute', right: '17px' }}>Download CSV sample</Button>
      <br/>
      <br/>
      {true && (
        <>
          <Title level={5}>Danger Zone</Title>
          <Row>
            <DangerousConfirm
              title='Are you absolutely sure?'
              typeText={enrichmentName}
              okText='Delete table'
              onConfirm={handleClearCache}
              content={(
                <>
                  This will permanently delete the <Text strong>{enrichmentName}</Text> table. After it's deleted the new table will be created.
                  The changes will affect all environments.
                </>
              )}
            >
              <Button loading={clearingCache} style={{ position: 'absolute', right: '17px' }} type='primary' danger>Clear Cache</Button>
            </DangerousConfirm>
          </Row>
          <br/><br/>
        </>
      )}
    </>
  );
}
