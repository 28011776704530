import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';

import { fetchTenants } from './../../redux/actions/configsActions';

export default function CopyToTenantsDropdown(props) {
  const { currentTenantId, currentTenantName } = props;
  const tenants = useSelector(state => state.configsReducer.tenantConfigs);

  const tenantIds = {};
  tenants.forEach(tenant => tenantIds[tenant.tenant_name] = tenant.tenant_id);

  const isLoading = useSelector(state => state.configsReducer.isLoading);
  const [selectedTenantNames, setSelectedTenantNames] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [currentTenantId]);

  function fetchData() {
    dispatch(fetchTenants());
  }

  function onChange(event, { value }) {
    setSelectedTenantNames(value);
    props.onChange(value.map((r) => { return tenantIds[r]; }));
  }

  function getSelectableTenantOptions() {
    const tenantOptions = tenants.map((r) => { return { value: r.tenant_name, text: r.tenant_name, key: r.tenant_id };});

    if (currentTenantId) {
      return tenantOptions.filter((r) => { return r.key !== currentTenantId; });
    } else if (currentTenantName) {
      return tenantOptions.filter((r) => { return r.value !== currentTenantName; });
    }
  }

  return (
    <Dropdown
      fluid
      multiple
      search
      selection
      clearable
      placeholder='Select tenants'
      loading={isLoading}
      options={getSelectableTenantOptions()}
      value={selectedTenantNames}
      onChange={onChange}
    />
  );
}
