import React from 'react';
import { Table } from 'antd';

export default function EnrichmentDataTable(props) {
  const { dataSource, loading } = props;
  let headers = (dataSource && dataSource.length > 0) ? [...Object.keys(dataSource[0])]: [];
  let columns = headers.map((r, i) => {
    return {
      title: r,
      dataIndex: r,
      key: r,
      render: r => <>{(typeof r === 'string') ? r :JSON.stringify(r)}</>
    }
  });

  return (
    <Table
      size='small'
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      pagination={{ defaultPageSize: 20, hideOnSinglePage: true }}
      rowKey={record => record.key}
      scroll={{ x: 'max-content' }}
    />
  );
}
