import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Input, Header, Label, Button } from 'semantic-ui-react';
import { Tag } from 'antd';
import { NotificationManager } from 'react-notifications';
import { get, partition } from 'lodash';
import { saveAs } from 'file-saver';

import apiTenants from '../../api/apiTenants';
import { fetchTenants } from '../../redux/actions/configsActions';
import { removeTenant } from '../../redux/actions/configsActions';
import { filterData } from '../../common/utils';
import Href from '../../shared/fields/Href';
import NewTenantButton from './NewTenantButton';
import RemoveButton from '../../shared/fields/RemoveButton';
import ExportButton from '../../common/ExportButton';
import {isProtectedTenant} from "../../shared/data/tenants";


export default function TenantsTable(props) {
  const { collapsing, subCategory = '' } = props;
  const isLoading = useSelector(state => state.configsReducer.isLoading);
  const [isExporting, setIsExporting] = useState(false);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState('');
  const allTenantConfigs = useSelector(state => state.configsReducer.tenantConfigs);
  let tenants = filterData(allTenantConfigs, ['tenant_name', 'tenant_id', 'service_token'], filter);
  tenants = partition(tenants, (t) => {return isProtectedTenant(t)});

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  function fetchData() {
    if (tenants.length === 0) {
      dispatch(fetchTenants());
    }
  }

  function onRemoveConfirm(tenantName) {
    dispatch(removeTenant(tenantName, true));
  }

  async function onExport() {
    setIsExporting(true);
    try {
      const response = await apiTenants.exportTenantUsers();
      saveAs(response.data, 'tenants_users.csv');
    } catch(error) {
      NotificationManager.error(error.message);
    } finally {
      setIsExporting(false);
    }
  }

  function generateTenantRow(row, index) {
    return (<Table.Row key={row.tenant_name} id={index}>
      <Table.Cell>
        {isProtectedTenant(row) && collapsing ? (
            <Label ribbon>
              <Header size='small'><Href href={`/tenants/${row.tenant_name}/${subCategory}`}>{row.tenant_name}</Href></Header>
            </Label>
        ) : (
            <Header size='small'><Href href={`/tenants/${row.tenant_name}/${subCategory}`}>{row.tenant_name}</Href></Header>
        )}
      </Table.Cell>
      { collapsing && (
          <>
            <Table.Cell>
              <Href href={`/tenants/${row.tenant_name}`}>{row.tenant_id}</Href>
            </Table.Cell>
            <Table.Cell>
              {row.tenant_type}
            </Table.Cell>
            <Table.Cell>
              <div>{(get(row, 'tags') || []).map(tag => <Tag key={tag}>{tag}</Tag>)}</div>
            </Table.Cell>
            <Table.Cell>
              {!isProtectedTenant(row) && (
                  <RemoveButton onClick={() => onRemoveConfirm(row.tenant_name)} loading={isLoading} confirmHeader={`You are going to remove tenant "${row.tenant_name}"`}/>
              )}
            </Table.Cell>
          </>
      )}
    </Table.Row>)
  }

  return (
    <Table collapsing={!collapsing}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan='99'>
            <Input
              size='small'
              icon='search'
              placeholder='Tenants search'
              loading={isLoading}
              style={{ maxWidth: 160 }}
              value={filter}
              onChange={(e, { value }) => setFilter(value)}
            />
            { collapsing && (
              <>
                <NewTenantButton loading={isLoading}/>
                <ExportButton onClick={onExport}>
                  <Button
                    title='Export Tenants Users'
                    basic
                    icon='file'
                    size='small'
                    floated='right'
                    disabled={isLoading || isExporting}
                    loading={isExporting}
                  />
                </ExportButton>
              </>
            )}
          </Table.HeaderCell>
        </Table.Row>
        { collapsing && (
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Id</Table.HeaderCell>
            <Table.HeaderCell>Group</Table.HeaderCell>
            <Table.HeaderCell>Tags</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        )}
      </Table.Header>

      <Table.Body>
        { tenants[0].map((row, index) => (
            generateTenantRow(row, index)
        ))}
        { tenants[1].map((row, index) => (
            generateTenantRow(row, index)
        ))}
      </Table.Body>
    </Table>
  );
}
