import React from 'react';
import styled from 'styled-components';

import history from '../../history';
import { exploriumColors } from '../../common/colors';

const StyledA = styled.a`
  color: ${exploriumColors.exploriumGreenLink};
`;

export default function Href(props) {
  const { href, children, disabled } = props;

  function onOpen(event) {
    event.preventDefault();
    if (disabled) return;

    history.push(href);
  }

  return (
    <StyledA href={href} onClick={onOpen}>
      {children}
    </StyledA>
  );
}
