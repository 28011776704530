import React, {useEffect} from 'react';
import SwaggerUI from 'swagger-ui-react';

export default function ApiDocs(props) {
    useEffect(() => {
        document.title = 'API Docs';
    }, []);
  return (
    <>
      <SwaggerUI url='/swagger.json'/>
    </>
  );
}
