import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Table,
  Switch,
  Input,
  Button,
  Popconfirm,
  Form,
  InputNumber,
  Badge,
  Select,
} from "antd";
import {
  SearchOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { NotificationManager } from "react-notifications";
import { getOntologyDefaultIcon } from "../../../common/utils";
import ColumnsOntologies from "./ColumnsOntologies";
import SearchFilterDropdown from "../../../shared/Components/Table/SearchFilterDropdown";
import IconSelector from "./IconSelector";
import BulkActions from "./BulkActions";
import apiStatic from "../../../api/apiStatic";
import { produce } from "immer";
import { find, findIndex, isEmpty } from "lodash";
import { useQuery } from "react-query";
import apiClient from "../../../api/apiClient";

const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export default function ColumnsTableEditor(props) {
  const { ontologies, columns, enrichmentName, metadataStage } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [icons, setIcons] = useState([]);
  const { data: ontologiesOptions = [] } = useQuery({
    queryKey: [`/edm_static_ontologies`],
    queryFn: () =>
      apiClient.get(`/edm_static_ontologies`).then((response) => {
        return response.data;
      }),
    select: (data) => data.items.map((item) => item.name),
  });

  const { data: potentialSignals = [] } = useQuery({
    queryKey: [`/enrichments_metadata`, metadataStage, enrichmentName],
    queryFn: () =>
      apiClient
        .get(
          `/enrichments_metadata/${metadataStage}/${enrichmentName}/potential_signals`
        )
        .then((response) => {
          return response.data;
        }),
  });

  const [signalsToAdd, setSignalsToAdd] = useState([]);

  const searchInput = useRef();
  const data = Object.keys(columns).map((columnName) => {
    return { ...columns[columnName], column_name: columnName };
  });
  const availabilityStatusMap = {
    disabled: "error",
    deployed_projects_only: "warning",
    enabled: "success",
  };

  function AvailabilityDrop(props) {
    return (
      <div>
        <p>{props.desc ? props.desc : null}</p>
        <Select
          style={{ width: "100%" }}
          value={props.value}
          onChange={props.onChange}
          options={[
            { value: "enabled", label: "Enabled" },
            {
              value: "deployed_projects_only",
              label: "Deployed Projects Only",
            },
            { value: "disabled", label: "Disabled" },
          ]}
        />
      </div>
    );
  }
  function getEnrichmentOntologiesFilters(enrichment) {
    const unique = Array.from(
      new Set(
        data.map((column) => {
          return column.ontology;
        })
      )
    );
    unique.sort();
    return unique.map((ontology) => {
      return { text: ontology, value: ontology };
    });
  }

  const tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      width: "12%",
      key: "id",
      editable: false,
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "12%",
      key: "name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <SearchFilterDropdown
          multiple
          inputRef={searchInput}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          handleSearch={handleSearch}
          handleReset={handleReset}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "00c2b2" : "#454444" }} />
      ),
      onFilter: searchFilter,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) selectSearchFilter();
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
      editable: true,
    },
    {
      title: "Display Name",
      dataIndex: "display_name",
      width: "12%",
      key: "display_name",
      sorter: (a, b) =>
        (a.display_name || "").localeCompare(b.display_name || ""),
      editable: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "23%",
      key: "description",
      editable: true,
    },
    {
      title: "Ontology",
      dataIndex: "ontology",
      width: "8%",
      key: "ontology",
      filters: getEnrichmentOntologiesFilters(data),
      sorter: (a, b) => a.ontology.localeCompare(b.ontology),
      onFilter: (value, record) => record.ontology === value,
      editable: true,
    },
    {
      title: "Cutoff",
      dataIndex: "cutoff",
      width: "5%",
      key: "cutoff",
      render: (cutoff, record) => (
        <div style={{ textAlign: "center" }}>
          <Switch
            size="small"
            checked={cutoff}
            onChange={() => onTimeAxisChange(record.column_name)}
            disabled={record.ontology !== "DATETIME"}
          />
        </div>
      ),
    },
    {
      title: "Score",
      dataIndex: "sort_index",
      width: "5%",
      key: "sort_index",
      editable: true,
    },
    {
      title: (
        <AvailabilityDrop
          value={(() => {
            const allValues = new Set(columns.map((x) => x.chef_status));
            if (allValues.size === 1) {
              return [...allValues][0];
            }

            return null;
          })()}
          desc="Chef status"
          onChange={(value) => {
            setColumns(
              produce(columns, (draft) => {
                draft.forEach((record) => {
                  record.chef_status = value;
                });
              })
            );
          }}
        />
      ),
      dataIndex: "chef_status",
      width: "8%",
      key: "chef_status",
      render: (availability, record) => (
        <div style={{ textAlign: "center" }}>
          <Badge
            style={{ height: "10px", width: "10px" }}
            status={availabilityStatusMap[availability]}
          >
            <AvailabilityDrop
              value={availability}
              onChange={(value) => {
                setColumns(
                  produce(columns, (draft) => {
                    const index = findIndex(columns, { id: record.id });
                    if (index !== -1) {
                      draft[index].chef_status = value;
                    }
                  })
                );
              }}
            />
          </Badge>
        </div>
      ),
    },
    {
      title: (
        <AvailabilityDrop
          value={(() => {
            const allValues = new Set(columns.map((x) => x.ml_status));
            if (allValues.size === 1) {
              return [...allValues][0];
            }

            return null;
          })()}
          desc="ML status"
          onChange={(value) => {
            setColumns(
              produce(columns, (draft) => {
                draft.forEach((record) => {
                  record.ml_status = value;
                });
              })
            );
          }}
        />
      ),
      dataIndex: "ml_status",
      width: "8%",
      key: "ml_status",
      render: (availability, record) => (
        <div style={{ textAlign: "center" }}>
          <Badge
            style={{ height: "10px", width: "10px" }}
            status={availabilityStatusMap[availability]}
          >
            <AvailabilityDrop
              value={availability}
              onChange={(value) => {
                setColumns(
                  produce(columns, (draft) => {
                    const index = findIndex(columns, { id: record.id });
                    if (index !== -1) {
                      draft[index].ml_status = value;
                    }
                  })
                );
              }}
            />
          </Badge>
        </div>
      ),
    },
    {
      title: "Comments",
      dataIndex: "comments",
      width: "12%",
      key: "comments",
      editable: true,
      render: (value, record) => onCommentsChange(record.column_name, value),
    },
    {
      title: "",
      dataIndex: "operation",
      width: "3%",
      key: "operation",
      render: (text, record) =>
        data.length >= 1 ? (
          <div style={{ textAlign: "center" }}>
            <Popconfirm title="Remove?" onConfirm={() => handleDelete(record)}>
              <DeleteOutlined />
            </Popconfirm>
          </div>
        ) : null,
    },
  ];

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef();
    const form = useContext(EditableContext);
    useEffect(() => {
      if (editing && inputRef && inputRef.current) {
        inputRef.current.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async (e) => {
      try {
        const values = await form.validateFields();

        if (dataIndex === "sort_index" && !values[dataIndex]) {
          values[dataIndex] = 0;
        }

        toggleEdit();
        handleSave(record, { ...record, [dataIndex]: values[dataIndex] });
      } catch (errInfo) {
        console.log("Save failed:", errInfo);
      }
    };

    const saveIcon = (icon) => {
      try {
        toggleEdit();
        handleSave(record, {
          ...record,
          icon: icon ? icon : getOntologyDefaultIcon(record.ontology),
        });
      } catch (errInfo) {
        console.log("Save failed:", errInfo);
      }
    };

    const saveOntology = (value) => {
      try {
        toggleEdit();
        handleSave(record, { ...record, ontology: value });

        if (
          getOntologyDefaultIcon(record.ontology) === record.icon ||
          !record.icon
        ) {
          handleSave(record, {
            ...record,
            ontology: value,
            icon: getOntologyDefaultIcon(value),
          });
        }
      } catch (errInfo) {
        console.log("Save failed:", errInfo);
      }
    };

    let childNode = children;

    if (editable) {
      if (editing) {
        if (dataIndex === "ontology") {
          childNode = (
            <ColumnsOntologies
              inputRef={inputRef}
              onChange={saveOntology}
              onBlur={toggleEdit}
              value={record[dataIndex]}
              selectableOntologies={ontologiesOptions}
            />
          );
        } else if (dataIndex === "icon") {
          childNode = (
            <IconSelector
              onSelect={toggleEdit}
              onBlur={toggleEdit}
              onChange={saveIcon}
              value={record[dataIndex]}
              icons={icons}
            />
          );
        } else if (dataIndex === "sort_index") {
          childNode = (
            <InputNumber
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              min={0}
              max={1000}
              step={1}
              precision={1}
              value={record[dataIndex] || 0}
            />
          );
        } else {
          childNode = (
            <Input ref={inputRef} onPressEnter={save} onBlur={save} />
          );
        }
      } else {
        childNode = (
          <div className="editable-cell-value-wrap" onClick={toggleEdit}>
            &nbsp;{childNode}
          </div>
        );
      }
    }

    return (
      <td {...restProps}>
        <Form.Item style={{ margin: 0 }} name={dataIndex}>
          {childNode}
        </Form.Item>
      </td>
    );
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const finalTableColumns = tableColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });

  useEffect(() => {
    loadSignalsIcons();
    // eslint-disable-next-line
  }, []);

  async function loadSignalsIcons() {
    try {
      const { data } = await apiStatic.getSignalsIcons();
      setIcons(data.items);
    } catch (error) {
      NotificationManager.error(error.message);
    }
  }

  function handleSearch(selectedKeys, confirm) {
    confirm();
  }

  function handleReset(clearFilters) {
    clearFilters();
  }

  function searchFilter(value, record) {
    const loweredValue = value.toLowerCase();
    return (
      (record.column_name || "").toLowerCase().includes(loweredValue) ||
      (record.display_name || "").toLowerCase().includes(loweredValue) ||
      (record.description || "").toLowerCase().includes(loweredValue)
    );
  }

  function selectSearchFilter() {
    setTimeout(() => searchInput.current.select(), 100);
  }

  function setColumns(columns) {
    props.onChange(columns);
  }

  function isConnectable(value) {
    return ontologies[value].connectable === true;
  }

  function onTimeAxisChange(column) {
    columns[column].cutoff = !columns[column].cutoff;
    setColumns(columns);
  }

  function onCommentsChange(column, value) {
    let returnValue = value;
    if (
      columns[column].comments === null ||
      columns[column].comments === undefined
    ) {
      returnValue = "";
    } else if (value.trim() === "") {
      columns[column].comments = null;
    }
    return returnValue;
  }
  function handleDelete(record) {
    setColumns(
      produce(columns, (draft) => {
        const index = findIndex(columns, { id: record.id });
        if (index !== -1) {
          draft.splice(index, 1);
        }
      })
    );
  }

  function handleAdd() {
    setSignalsToAdd([]);
    setColumns(
      produce(columns, (draft) => {
        draft.push(
          ...signalsToAdd.map((id) => {
            const row = find(potentialSignals, { id });
            return row;
          })
        );
      })
    );
  }

  function handleSave(originRecord, record) {
    setColumns(
      produce(columns, (draft) => {
        const index = findIndex(columns, { id: record.id });
        if (index !== -1) {
          draft[index] = record;
        }
      })
    );
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  function onBulkChange(obj) {
    selectedRowKeys.forEach((key) => {
      columns[key] = { ...columns[key], ...obj };
    });

    setColumns(columns);
  }

  return (
    <>
      {selectedRowKeys.length > 0 && (
        <div style={{ paddingBottom: 5 }}>
          <BulkActions ontologies={ontologiesOptions} onChange={onBulkChange} />
        </div>
      )}
      <Table
        components={components}
        dataSource={data}
        columns={finalTableColumns}
        rowKey={(record) => record.column_name}
        rowClassName={() => "editable-row"}
        bordered
        size="small"
        tableLayout="fixed"
        scroll={{ x: 2000 }}
        pagination={{
          pageSizeOptions: [10, 20, 50, 100],
          showSizeChanger: true,
        }}
        rowSelection={rowSelection}
        style={{ margin: "0 0 24px" }}
      />
      <div style={{ display: "flex", gap: "8px" }}>
        <div>
          <Select
            style={{ width: "320px" }}
            placeholder="Select signal to add"
            mode="multiple"
            value={signalsToAdd}
            onChange={setSignalsToAdd}
            options={potentialSignals.filter(x => {
              return !columns.find(y => y.name === x.name);
            }).map((x) => ({
              value: x.id,
              label: x.display_name,
            }))}
          />
        </div>
        <Button
          onClick={handleAdd}
          style={{ marginBottom: 16 }}
          icon={<PlusOutlined />}
          disabled={isEmpty(signalsToAdd)}
        >
          Add Signal
        </Button>
      </div>
    </>
  );
}
