import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Form, Button, Input, Segment, Popup, Icon, Header, Checkbox, Dropdown } from 'semantic-ui-react';
import ReactJson from 'react-json-view';

import { arrayToOptions } from './../../common/utils';
import { tenantsTypes } from '../../shared/data/tenants';
import { addTenant } from '../../redux/actions/configsActions';

export default function NewTenantButton(props) {
  const { loading } = props;
  const [tenantName, setTenantName] = useState('');
  const [tenantType, setTenantType] = useState('client');
  const [tenantConfig, setTenantConfig] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  function onTenantNameChange(event, { value }) {
    setTenantName(value.replace(/(?!-)[^a-zA-Z0-9]/g, "").replace(/--/, '-'));
  }

  function onEdit({ updated_src }) {
    setTenantConfig(updated_src);
  }

  function onAddTenant() {
    const data = { config: tenantConfig, "tenant_type": tenantType };
    dispatch(addTenant(tenantName.replace(/^-/, '').replace(/-$/, ''), data));

    setTenantName('');
    setTenantConfig({});
    setIsOpen(false);
  }

  function handleOpen() {
    setIsOpen(true);
  }

  function handleClose() {
    setIsOpen(false);
  }

  return (
    <Popup
      pinned
      open={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      on='click'
      position='bottom right'
      trigger={(
        <Button
          basic
          icon='add user'
          floated='right'
          size='small'
          disabled={loading}
          loading={loading}
        />
      )}
      content={(
        <Container>
          <Header as='h3'>Create new tenant</Header>
          <Segment basic>
            <Form>
              <Form.Field>
                <Input size='mini' placeholder='Tenant Name' value={tenantName} disabled={loading} onChange={onTenantNameChange}/>{' '}
              </Form.Field>
              <Form.Field>
                <Dropdown
                  fluid
                  search
                  selection
                  placeholder="Select tenant type"
                  options={arrayToOptions(tenantsTypes)}
                  value={tenantType}
                  onChange={(e, { value }) => setTenantType(value)}
                />
              </Form.Field>
              <Form.Field>
                <ReactJson
                  disabled={loading}
                  src={tenantConfig}
                  collapsed={2}
                  name='config'
                  onEdit={onEdit}
                  onAdd={onEdit}
                  onDelete={onEdit}
                />
              </Form.Field>
              <Button positive size='mini' disabled={tenantName.length < 3} onClick={onAddTenant} loading={loading}>Create</Button>
            </Form>
          </Segment>
        </Container>
      )}
    />
  );
}
