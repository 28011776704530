import React, { useState } from 'react';
import { Dropdown, Menu, Image, Button } from 'semantic-ui-react';
import { NotificationContainer } from 'react-notifications';

import { isNewVersionAvailable, reloadBrowserCache } from '../../common/utils';
import PrivateComponent from '../../common/PrivateComponent';
import history from '../../history';
import config from 'react-global-configuration';
import {METADATA_READ, SECRETS_MANAGEMENT, API_MANAGEMENT} from "../../shared/data/permissions";

export default function MenuComponent(props) {
  const { location, auth } = props;
  const { pathname } = location;
  const [activeItem, setActiveItem] = useState(pathname.split('/')[1]);

  fetch('/meta.json', { cache: 'no-cache' }).then((response) => response.json()).then((meta) => {
    if (isNewVersionAvailable(meta)) reloadBrowserCache();
  });

  function onClick(event, { id }) {
    event.preventDefault();
    if (id === 'catalog') id = '';

    setActiveItem(id);
    history.push(`/${id}`);
  }

  return (
    <>
      <Menu inverted stackable>
        <Menu.Item
          href='/'
          id='catalog'
          name='catalog'
          onClick={onClick}
        >
          <Image src={config.get('logoUrl')} avatar/>
        </Menu.Item>
        <Menu.Item
          href='/'
          id='catalog'
          name='catalog'
          active={activeItem === '' || activeItem === 'catalog'}
          onClick={onClick}
        />
        <PrivateComponent requiredPermissions={METADATA_READ} currentPermissions={auth.permissions}>
          <Menu.Item
            href='/dashboard'
            name='dashboard'
            id='dashboard'
            active={activeItem === 'dashboard'}
            onClick={onClick}
          />
        </PrivateComponent>
        <PrivateComponent requiredPermissions={METADATA_READ} currentPermissions={auth.permissions}>
          <Menu.Item
            href='/tenants'
            name='Tenants'
            id='tenants'
            active={activeItem === 'tenants'}
            onClick={onClick}
          />
        </PrivateComponent>
        <PrivateComponent requiredPermissions={METADATA_READ} currentPermissions={auth.permissions}>
          <Menu.Item
              href='/feature-flags'
              name='Feature Flags'
              id='feature-flags'
              active={activeItem === 'feature-flags'}
              onClick={onClick}
          />
        </PrivateComponent>
        <PrivateComponent requiredPermissions={METADATA_READ} currentPermissions={auth.permissions}>
          <Menu.Item
            href='/'
            id='ontologies'
            name='ontologies'
            active={activeItem === 'ontologies'}
            onClick={onClick}
          />
        </PrivateComponent>
        <PrivateComponent requiredPermissions={SECRETS_MANAGEMENT} currentPermissions={auth.permissions}>
          <Menu.Item
            href='/'
            id='secrets'
            name='secrets'
            active={activeItem === 'secrets'}
            onClick={onClick}
          />
        </PrivateComponent>
        <PrivateComponent requiredPermissions={METADATA_READ} currentPermissions={auth.permissions}>
          <Menu.Item
            href='/api-docs'
            name='API Docs'
            id='api-docs'
            active={activeItem === 'api-docs'}
            onClick={onClick}
          />
        </PrivateComponent>
        <PrivateComponent requiredPermissions={API_MANAGEMENT} currentPermissions={auth.permissions}>
          <Menu.Item
            href='/api-tokens'
            name='API Tokens'
            id='api-tokens'
            active={activeItem === 'api-tokens'}
            onClick={onClick}
          />
        </PrivateComponent>
        {/*<PrivateComponent requiredPermissions={METADATA_READ} currentPermissions={auth.permissions}>*/}
        {/*  <Menu.Item*/}
        {/*    href='/em-status'*/}
        {/*    name='EM Status'*/}
        {/*    id='em-status'*/}
        {/*    active={activeItem === 'em-status'}*/}
        {/*    onClick={onClick}*/}
        {/*  />*/}
        {/*</PrivateComponent>*/}

        <Menu.Menu position='right'>
          { (auth.isAuthenticated()) ? (
            <Dropdown item trigger={(<span><Image avatar src={auth.profile.picture} /> {auth.profile.user}</span>)} simple>
              <Dropdown.Menu>
                <Dropdown.Header content={`Signed is as ${auth.profile.email} (${auth.profile.roles})`}/>
                <Dropdown.Divider />
                <Dropdown.Item icon='settings' text='Edit roles' target='_blank' href='https://manage.auth0.com/dashboard/us/explorium/users'/>
                <Dropdown.Item icon='user' text='Profile' onClick={() => history.push('/profile')}/>
                <Dropdown.Item icon='log out' text='Log Out' onClick={auth.logout}/>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Button secondary onClick={auth.login}>Log In</Button>
          )}
        </Menu.Menu>
      </Menu>

      <NotificationContainer/>
    </>
  )
}
