export const BACKOFFICE_EXECUTION = 'backoffice:execution';
export const API_MANAGEMENT = 'api:management';
export const TENANTS_BULK_OPERATIONS = 'tenants:bulk_operations';
export const TENANTS_READ = 'tenants:read';
export const TENANTS_WRITE = 'tenants:write';
export const TENANTS_DELETE = 'tenants:delete';
export const UPDATE_PROJECTS_CONFIG = 'projects_config:update';
export const METADATA_READ = 'metadata:read';
export const METADATA_WRITE = 'metadata:write';
export const METADATA_PROTECTED_WRITE = 'metadata:protected_write';
export const ONTOLOGIES_MANAGEMENT = 'ontologies:management';
export const SECRETS_MANAGEMENT = 'secrets:management';
export const TASKS_READ = 'tasks:read';
export const TASKS_MANAGEMENT = 'tasks:management';
export const STATIC_ENRICHMENT_SUBMISSION = 'static_enrichment:submission';