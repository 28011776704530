import React, { Component } from 'react';
import queryString from 'query-string';

class Login extends Component {
  constructor(props) {
    super(props);
    const redirect = queryString.parse(props.location.search)['redirect'];
    localStorage.setItem('redirect', redirect);
    this.props.auth.login();
  }

  render() {
    return (
      <h5>loading...</h5>
    );
  }
}

export default Login;
