import React from 'react';
import { Popup } from 'semantic-ui-react';

export default function PopupHint(props) {
  const { children, content } = props;

  return (
    <Popup
      inverted
      closeOnTriggerClick
      size='tiny'
      trigger={children}
      content={content}
    />
  );
}
