import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  fetchEnrichmentTenantsSettings,
  updateEnrichmentTenantSettings,
  batchUpdateEnrichmentTenantSettings,
  fetchEnrichmentsMetadata
} from '../../../redux/actions/enrichmentsActions';
import EnrichmentTenantsSettingsTable from './EnrichmentTenantsSettingsTable';

export default function EnrichmentTenantsSettings({ match, auth }) {
  const { table: enrichmentName, metadataStage } = match.params;
  const tenantsSettings = useSelector(state => state.enrichmentsReducer.tenantsSettings);
  const enrichmentMetadata = useSelector(state => state.enrichmentsReducer.metaData);
  const loading = useSelector(state => state.enrichmentsReducer.isLoading);

  const dispatch = useDispatch();

  function fetchData() {
    dispatch(fetchEnrichmentTenantsSettings(enrichmentName));
    dispatch(fetchEnrichmentsMetadata(metadataStage, enrichmentName));
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [enrichmentName]);

  function onUpdateEnrichmentTenantSettings(tenantId, tenantSettings) {
    dispatch(updateEnrichmentTenantSettings(enrichmentName, tenantId, tenantSettings));
  }

  function onUpdateManyEnrichmentTenantSettings(tenantsSettings) {
    dispatch(batchUpdateEnrichmentTenantSettings(enrichmentName, tenantsSettings));
  }

  return (
    <EnrichmentTenantsSettingsTable
        auth={auth}
        tenantsSettings={tenantsSettings.map(object => {
                    return {...object, defaultLimit: enrichmentMetadata?.properties?.enrichment_limit};
                    })}
        enrichmentMetadata={enrichmentMetadata}
        loading={loading}
        onUpdateManyEnrichmentTenantSettings={onUpdateManyEnrichmentTenantSettings}
        onUpdateEnrichmentTenantSettings={onUpdateEnrichmentTenantSettings}
    />
  );
}
