import { NotificationManager } from 'react-notifications';

import apiClient from '../../api/apiClient';
import apiTenants from '../../api/apiTenants';

export const FETCH_FAILURE = 'FETCH_FAILURE';
export const FETCH_REQUEST = 'FETCH_REQUEST';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_TENANT_ENRICHMENTS_SUCCESS = 'FETCH_TENANT_ENRICHMENTS_SUCCESS';
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS';
export const FETCH_SAMPLE_PROJECTS_SUCCESS = 'FETCH_SAMPLE_PROJECTS_SUCCESS';

export const fetchFailure = () => ({
  type: FETCH_FAILURE,
});

export const finishRequest = () => ({
  type: FETCH_SUCCESS,
});

export const startRequest = () => ({
  type: FETCH_REQUEST,
});

export const fetchClientsSuccess = (clients) => ({
  type: FETCH_CLIENTS_SUCCESS,
  clients,
});

export const fetchClients = () => (dispatch) => {
  dispatch(startRequest());
  apiClient.get('/tenants_names')
    .then((response) => {
      dispatch(fetchClientsSuccess(response.data));
    })
    .catch((error) => {
      NotificationManager.error(error.message);
      dispatch({ type: 'FAILURE', errorMessage: error.message });
      dispatch(fetchFailure());
    });
};

export const updateTenantEnrichmentsSettings = (tenantName, enrichmentsSettings, callback = null) => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiTenants.updateTenantEnrichmentsSettings(tenantName, enrichmentsSettings);
    NotificationManager.success(data);
    if (callback) {
      callback();
    } else {
      dispatch(fetchTenantEnrichmentsSettings(tenantName));
    }
  } catch(error) {
    NotificationManager.error(error.message);
  } finally {
    dispatch(finishRequest());
  }
};

export const updateTenantAllEnrichmentsSettings = (tenantName, settings, params = {}, callback = null) => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiTenants.updateTenantAllEnrichmentsSettings(tenantName, settings, params);
    NotificationManager.success(data);
    if (callback) callback();
  } catch(error) {
    NotificationManager.error(error.message);
  } finally {
    dispatch(finishRequest());
  }
};

export const fetchTenantEnrichmentsSettings = (tenantName, params = {}) => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiClient.get(`/client_enrichments/${tenantName}`, { params });
    dispatch({ type: FETCH_TENANT_ENRICHMENTS_SUCCESS, clientEnrichments: data.items, hasMoreClientEnrichments: data.hasMore, clientEnrichmentsCount: data.itemCount });
  } catch(error) {
    NotificationManager.error(error.message);
  } finally {
    dispatch(finishRequest());
  }
};

export const updateAllClientsEnrichment = (enrichment_settings, callback = null) => (dispatch) => {
  dispatch(startRequest());
  apiClient.put('/client_enrichments', enrichment_settings)
    .then((response) => {
      NotificationManager.success(response.data);
      dispatch(finishRequest());

      if (callback) callback();
    })
    .catch((error) => {
      NotificationManager.error(error.message);
      dispatch({ type: 'FAILURE', errorMessage: error.message });
      dispatch(fetchFailure());
    });
};

export const addEnrichmentSettingsToAllTenants = (enrichmentName) => (dispatch) => {
  dispatch(startRequest());
  apiClient.put('/add_enrichment_settings_to_all_tenants', { enrichment_name: enrichmentName })
    .then((response) => {
      NotificationManager.success(response.data);
      dispatch(finishRequest());
    })
    .catch((error) => {
      NotificationManager.error(error.message);
      dispatch({ type: 'FAILURE', errorMessage: error.message });
      dispatch(fetchFailure());
    });
};

export const fetchSampleProjectLists = () => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiTenants.fetchSampleProjectLists();
    dispatch({ type: FETCH_SAMPLE_PROJECTS_SUCCESS, sampleProjectLists: data.items });
  } catch(error) {
    NotificationManager.error(error.message);
    dispatch(fetchFailure());
  }
};
