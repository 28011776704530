import {
  START_DATABASES_REQUEST,
  START_TABLES_REQUEST,
  START_ITEMS_REQUEST,
  FINISH_DATABASES_REQUEST,
  FINISH_TABLES_REQUEST,
  FINISH_ITEMS_REQUEST,
  FETCH_DATABASES_SUCCESS,
  FETCH_TABLES_SUCCESS,
  FETCH_ITEMS_SUCCESS,
} from '../actions/glueActions';

export default (state = { databasesLoading: false, tablesLoading: false, itemsLoading: false, databases: [], tables: [], items: [] }, action) => {
  switch (action.type) {
    case START_DATABASES_REQUEST: {
      return { ...state, databasesLoading: true };
    }
    case START_TABLES_REQUEST: {
      return { ...state, tablesLoading: true };
    }
    case START_ITEMS_REQUEST: {
      return { ...state, itemsLoading: true };
    }
    case FINISH_DATABASES_REQUEST: {
      return { ...state, databasesLoading: false };
    }
    case FINISH_TABLES_REQUEST: {
      return { ...state, tablesLoading: false };
    }
    case FINISH_ITEMS_REQUEST: {
      return { ...state, itemsLoading: false };
    }
    case FETCH_DATABASES_SUCCESS: {
      return { ...state, databases: action.databases };
    }
    case FETCH_TABLES_SUCCESS: {
      return { ...state, tables: action.tables };
    }
    case FETCH_ITEMS_SUCCESS: {
      return { ...state, items: action.items };
    }
    default:
      return state;
  }
};
