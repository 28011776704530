import React, { useEffect, useRef, useState } from 'react';
import { Button, Popconfirm, Switch, Table, Form } from 'antd';
import { NotificationManager } from 'react-notifications';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined, PullRequestOutlined, SearchOutlined } from '@ant-design/icons';

import history from '../../history';
import apiOntologies from '../../api/apiOntologies';
import SearchFilterDropdown from '../../shared/Components/Table/SearchFilterDropdown';
import OntologyModalForm from './OntologyModalForm';
import { ChangeSetSelector } from './ChangeSetSelector';
import CreateChangeSetModalForm from './ChangeSetCreationForm';

export default function Ontologies() {
  const [form] = Form.useForm();
  const searchInput = useRef();
  const searchIcon = useRef();
  const defaultChangeRequestName = 'main';
  const [changeRequest, setChangeRequest] = useState(defaultChangeRequestName);
  const [availableChangeRequests, setAvailableChangeRequests] = useState([]);
  const [data, setData] = useState([]);
  const [parentOntologyNames, setParentOntologyNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createCRFormVisible, setCreateCRFormVisible] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [editFormVisible, setEditFormVisible] = useState(false);
  const [editingOntology, setEditingOntology] = useState({});
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '15%',
      filterIcon: filtered => <SearchOutlined ref={searchIcon} style={{color: filtered ? '00c2b2' : '#454444'}}/>,
      onFilter: searchFilter,
      onFilterDropdownVisibleChange: visible => {
        if (visible) selectSearchFilter();
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
      filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
        <SearchFilterDropdown
          multiple
          inputRef={searchInput}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          handleSearch={handleSearch}
          handleReset={handleReset}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      sorter: (a, b) => a.description.localeCompare(b.description),
      width: '27%',
    },
    {
      title: 'Parent',
      dataIndex: 'parent',
      filters: [...parentOntologyNames].map(record => {
        return {text: record, value: record}
      }),
      onFilter: (value, record) => record.parent === value,
      sorter: (a, b) => a.dtype.localeCompare(b.dtype),
      width: '9%',
    },
    {
      title: 'Dtype',
      dataIndex: 'dtype',
      sorter: (a, b) => a.dtype.localeCompare(b.dtype),
      width: '9%',
    },
    {
      title: 'Enrichable',
      dataIndex: 'enrichable',
      render: (enrichable) => <div style={{textAlign: 'center'}}><Switch size='small' checked={enrichable}/></div>,
      sorter: (a, b) => a.enrichable - b.enrichable,
      width: '9%',
    },
    {
      title: 'Displayable',
      dataIndex: 'displayable',
      render: (displayable) => <div style={{textAlign: 'center'}}><Switch size='small' checked={displayable}/></div>,
      sorter: (a, b) => a.displayable - b.displayable,
      width: '9%',
    },
    {
      title: 'PII',
      dataIndex: 'pii',
      render: (pii) => <div style={{textAlign: 'center'}}><Switch size='small' checked={pii}/></div>,
      sorter: (a, b) => a.pii - b.pii,
      width: '9%',
    },
    {
      title: 'Generic',
      dataIndex: 'generic',
      render: (generic) => <div style={{textAlign: 'center'}}><Switch size='small' checked={generic}/></div>,
      sorter: (a, b) => a.generic - b.generic,
      width: '9%',
    },
    {
      title: '',
      dataIndex: 'name',
      width: '3%',
      render: (name, record) => (
        <>
          <Popconfirm title={`Remove ${name} ontology?`} onConfirm={() => onRemoveOntology(name)} okText='Remove'
                      cancelText='Cancel'>
            <DeleteOutlined style={{cursor: 'pointer'}}/>
          </Popconfirm>
          <EditOutlined style={{cursor: 'pointer'}} onClick={() => onEdit(record)}/>
        </>
      ),
    }
  ];

  useEffect(() => {
    document.title = 'Ontologies';
    fetchData();
    // eslint-disable-next-line
  }, []);

  function onEdit(ontology) {
    setEditingOntology(ontology);
    setEditFormVisible(true);
  }

  function selectSearchFilter() {
    setTimeout(() => searchInput.current.select());
  }

  function handleSearch(selectedKeys, confirm) {
    confirm();
  }

  function handleReset(clearFilters) {
    clearFilters();
  }

  function searchFilter(value, record) {
    const loweredValue = value.toLowerCase();
    return (record.name || '').toLowerCase().includes(loweredValue);
  }

  document.addEventListener('keydown', (e) => {
    let charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.metaKey || e.ctrlKey) && charCode === 'f') onControlFPress(e);
  });

  function onControlFPress(e) {
    if (!searchInput.current || !searchInput.current.state.focused) {
      setTimeout(() => searchIcon.current.click());
      setTimeout(() => searchInput.current.focus(), 100);
      e.returnValue = false;
    }
  }

  async function onRemoveOntology(name) {
    try {
      setLoading(true);
      await apiOntologies.deleteEDMOntologies(name, changeRequest);
    } catch (error) {
      NotificationManager.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  async function fetchData() {
    try {
      setLoading(true);
      await Promise.all([fetchChangeSets(), fetchOntologies()]);
    } finally {
      setLoading(false);
    }
  }

  async function fetchOntologies(value=changeRequest) {
    try {
      const { data } = await apiOntologies.getEDMOntologies(value);
      const { items } = data;
      setData(items);
      setParentOntologyNames([...new Set(items.map(ontology => ontology.parent).filter(ontology => ontology.parent !== 'Ontology'))]);
    } catch (error) {
      NotificationManager.error(error.message);
    }
  }

  async function fetchChangeSets() {
    try {
      const { data } = await apiOntologies.getEDMChangeRequests();
      const { items } = data;
      setAvailableChangeRequests(['main', ...items.map(item => item.name)]);
    } catch (error) {
      NotificationManager.error(error.message);
    }
  }

  async function onCreateOntology(ontology) {
    setFormVisible(false);
    await createOntology(ontology);
  }

  async function createOntology(ontology) {
    try {
      setLoading(true);
      await apiOntologies.createEDMOntology(ontology, changeRequest);
    } catch (error) {
      NotificationManager.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  async function onCreateChangeRequest(ontology) {
    setEditFormVisible(false);
    await createChangeRequest(editingOntology.name, ontology);
    setEditingOntology({});
  }

  async function createChangeRequest(name, ontology) {
    try {
      setLoading(true);
      await apiOntologies.updateEDMOntology(name, ontology, changeRequest);
    } catch (error) {
      NotificationManager.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  function createChangeSet(value) {
    setAvailableChangeRequests([value.changeSet, ...availableChangeRequests]);
    onSetChangeRequest(value.changeSet);
    setCreateCRFormVisible(false);
  }

  async function onSetChangeRequest(value) {
    setChangeRequest(value);
    await fetchOntologies(value);
  }

  return (
    <>
      <br/><br/>
      <Button
        type='text'
        style={{flex: 1, float: 'right'}}
        onClick={() => setFormVisible(true)}
      >Add Ontology<PlusCircleOutlined/></Button>
      <Button
        type='text'
        style={{flex: 1, float: 'right'}}
        onClick={() => setCreateCRFormVisible(true)}
      >Create Change Set<PlusCircleOutlined/></Button>
      <Button
        type='text'
        style={{flex: 1, float: 'right'}}
        onClick={() => history.push('/ontologies/change-requests')}
      >Change requests<PullRequestOutlined/></Button>

      <Form>
        <Form.Item>
          <ChangeSetSelector
            onChange={onSetChangeRequest}
            availableChangeRequests={availableChangeRequests}
            value={defaultChangeRequestName}/>
        </Form.Item>
      </Form>
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        rowKey={record => record.name}
        size='middle'
        tableLayout='fixed'
        pagination={{
          defaultPageSize: 10,
          pageSizeOptions: [10, 20, 50, 100],
          showSizeChanger: true,
          position: ['bottomRight']
        }}
      />
      <OntologyModalForm
        editing
        title={`Edit Ontology`}
        okText='Create change request'
        visible={editFormVisible}
        initialValues={editingOntology}
        onCancel={() => setEditFormVisible(false)}
        onSubmit={onCreateChangeRequest}
        parents={parentOntologyNames}
        availableChangeRequests={availableChangeRequests}
        setChangeRequest={onSetChangeRequest}
        currentChangeRequest={changeRequest}/>
      <OntologyModalForm
        title='Create New Ontology'
        okText='Create'
        visible={formVisible}
        onCancel={() => setFormVisible(false)}
        onSubmit={onCreateOntology}
        parents={parentOntologyNames}
        availableChangeRequests={availableChangeRequests}
        setChangeRequest={onSetChangeRequest}
        currentChangeRequest={changeRequest}/>
      <CreateChangeSetModalForm
        onConfirm={createChangeSet}
        onCancel={() => setCreateCRFormVisible(false)}
        visible={createCRFormVisible}
        editing/>
    </>
  );
}
