import apiClient from './apiClient';

export default {
  getOntologies: () => apiClient.get(`/ontologies`),
  getEDMOntologies: (changeRequest) => apiClient.get(`/edm_ontologies?changeRequest=${changeRequest}`),
  deleteEDMOntologies: (name, changeRequest) => apiClient.delete(`/edm_ontologies/${name}?changeRequest=${changeRequest}`),
  createEDMOntology: (ontology, changeRequest) => apiClient.post(`/edm_ontologies?changeRequest=${changeRequest}`, ontology),
  updateEDMOntology: (name, ontology, changeRequest) => apiClient.put(`/edm_ontologies/${name}?changeRequest=${changeRequest}`, ontology),
  getEDMChangeRequests: () => apiClient.get(`/ontology_change_requests`),
  deleteEDMChangeRequest: (changeRequestName) => apiClient.delete(`/ontology_change_requests?changeRequestName=${changeRequestName}`),
};
