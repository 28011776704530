import { queryClient } from "../../../../react-query/queryClient";
import apiClient from "../../../../api/apiClient";

const mock = {};

export const tagsApi = {
  async saveOptions(stage, tagName, options) {
    await apiClient.put(`/tags/${stage}/update_custom_metadata/${tagName}`, {
      name: tagName,
      options,
    });

    queryClient.invalidateQueries(["tagsApi.getAllTags", stage]);
  },

  async getAllTags(stage) {
    return apiClient.get(`/tags/${stage}/all`).then(({ data }) => data);
  },

  async createTag(stage, data) {
    await apiClient.put(`/tags/${stage}/metadata/${data.name}`, data);
    queryClient.invalidateQueries(["tagsApi.getAllTags", stage]);
  },
};
