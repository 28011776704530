import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Moment from 'react-moment';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { Table, Progress, Button, Popup, Confirm, Label } from 'semantic-ui-react';

import { fetchTasks, terminateTask } from '../../redux/actions/taskActions';
import Href from '../../shared/fields/Href';
import TaskErrors from './TaskErrors';


export default function Tasks() {
  const [activeTask, setActiveTask] = useState({});
  const [isTerminating, setIsTerminating] = useState(false);
  const dispatch = useDispatch();
  const tasks = useSelector(state => state.taskReducer.tasks);
  document.title = 'Tasks';

  useEffect(() => {
    onFetch();
    const intervalId = setInterval(() => {
      onFetch();
    }, 10000);

    return () => {
      clearInterval(intervalId);
    }
    // eslint-disable-next-line
  }, []);

  function onFetch() {
    dispatch(fetchTasks());
  }

  function onTerminate(task) {
    setActiveTask(task);
    setIsTerminating(true);
  }

  function terminate() {
    setIsTerminating(false);
    const { id } = activeTask;
    dispatch(terminateTask(id));
  }

  function hasErrors(task) {
    if (!task.logs || task.logs.length === 0) {
      return false;
    }

    return task.logs.filter((log) => log.level !== "info").length > 0
  }

  function isTerminated(task) {
      return task.hasOwnProperty('terminated')
  }

  return (
    <>
      <Table fixed>
        <Table.Header>
          <Table.Row>
              <Table.HeaderCell width={3}>Type</Table.HeaderCell>
              <Table.HeaderCell width={2}>Id</Table.HeaderCell>
              <Table.HeaderCell width={4}>Metadata</Table.HeaderCell>
              <Table.HeaderCell width={2}>Progress</Table.HeaderCell>
              <Table.HeaderCell width={2}>Started</Table.HeaderCell>
              <Table.HeaderCell width={3}>Started by</Table.HeaderCell>
              <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          { isEmpty(tasks) === false && tasks.map((task, index) => {
            const { info, metadata, id, type } = task;

            return (
              <Table.Row key={task.id} id={index}>
                <Table.Cell>
                  <Href href={`/dashboard/tasks/${id}`}>{type}</Href>
                </Table.Cell>
                <Table.Cell>
                  <Href href={`/dashboard/tasks/${id}`}>{id}</Href>
                </Table.Cell>
                <Table.Cell>
                  { metadata ? (
                    Object.keys(metadata).map(key => (
                      <>
                        <Label basic size='tiny' key={key}>
                          {key}:
                          <Label.Detail>{metadata[key]}</Label.Detail>
                        </Label>
                        <br/>
                      </>
                    ))
                  ) : (
                    <div>{info}</div>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <Popup
                    flowing
                    hoverable
                    disabled={!hasErrors(task)}
                    content={(<TaskErrors errors={task.logs}/>)}
                    trigger={
                    <Progress
                        warning={isTerminated(task)}
                        success={!isTerminated(task) && !hasErrors(task) && task.progress === 100}
                        error={hasErrors(task)}
                        active={task.progress !== 100}
                        value={task.progress}
                        total={100}
                        progress='percent'
                    />
                  } />
                </Table.Cell>
                <Table.Cell><Moment format='YYYY/MM/DD HH:mm'>{moment.utc(task.started).local()}</Moment></Table.Cell>
                <Table.Cell>{task.user}</Table.Cell>
                <Table.Cell>
                { (!isTerminated(task) && task.progress !== 100 ) &&
                  <Popup content='Terminate' inverted trigger={
                    <Button size='small' icon='remove' onClick={() => onTerminate(task)}/>
                  } />
                }
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      <Confirm content={`Terminate ${activeTask.type} ${activeTask.info} started by ${activeTask.user}?`} open={isTerminating} onCancel={() => setIsTerminating(false)} onConfirm={terminate}/>
    </>
  );
}
