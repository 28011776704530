import React, { useRef, useState, useEffect } from 'react';
import {Table, Input, Space, Button as AntButton, Tag} from 'antd';
import {get} from 'lodash';
import { Dropdown } from 'semantic-ui-react';
import { SearchOutlined } from '@ant-design/icons';
import { Message} from 'semantic-ui-react';

import Href from '../../../shared/fields/Href';
import TagsView from '../../../shared/Components/Enrichments/TagsView';
import { tenantsTypes } from '../../../shared/data/tenants';
import Limit from '../../../shared/Components/Enrichments/Limit';
import AvailabilityDropdown from '../../Enrichments/Dropdown';
import LegacyEnrichmentWarning from "../../Enrichments/LegacyEnrichmentWarning";
import PremiumEnrichmentWarning from "../../Enrichments/PremiumEnrichmentWarning";
import {METADATA_PROTECTED_WRITE} from "../../../shared/data/permissions";
import {NotificationManager} from 'react-notifications';

export default function EnrichmentTenantsSettingsTable({ tenantsSettings, enrichmentMetadata, loading, onUpdateManyEnrichmentTenantSettings, onUpdateEnrichmentTenantSettings, addLimit = true, auth }) {
  const tenantsTags = [ ...new Set(tenantsSettings.map(el => el.tenant_tags || []).flat())].sort((a, b) => a.localeCompare(b));
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loadingTenantIds, setLoadingTenantIds] = useState({});
  const [editingIndex, setEditingIndex] = useState(-1);
  const prevValueRef = useRef();
  const searchInput = useRef();
  const searchIcon = useRef();
  const disableLegacyEnrichmentEdit = enrichmentMetadata["enrichment_type"] === 'legacy' && !auth.permissions.includes(METADATA_PROTECTED_WRITE);
  const disablePremiumEnrichmentEdit = enrichmentMetadata["is_premium"] && !auth.permissions.includes(METADATA_PROTECTED_WRITE);
  const disableEdit = disableLegacyEnrichmentEdit || disablePremiumEnrichmentEdit;
  const [limitBulkValue,setLimitBulkValue] = useState('')

  useEffect(() => {
    let newLoadingTenantIds = {...loadingTenantIds}
    Object.keys(loadingTenantIds).forEach(tenantId => {
      if (!loadingTenantIds[tenantId])
        return

      let tenantGotUpdate =
        getTenantAvailability(prevValueRef.current, tenantId) !=
        getTenantAvailability(tenantsSettings, tenantId)
      newLoadingTenantIds[tenantId] = !tenantGotUpdate
    })

    prevValueRef.current = tenantsSettings
    setLoadingTenantIds(newLoadingTenantIds)
  }, [tenantsSettings]);

  function getTenantAvailability(tenants, tenant_id) {
    return ((tenants || []).filter(t => t.tenant_id == tenant_id)[0] || {}).availability
  }

  function onAvailabilityChange(value, tenant) {
    setLoadingTenantIds({...loadingTenantIds, [tenant.tenant_id]: true})
    onUpdateEnrichmentTenantSettings(tenant.tenant_id, {
      availability: value,
      limit: tenant.limit,
    });
  }

  function onLimitChange(value, tenant) {
    onUpdateEnrichmentTenantSettings(tenant.tenant_id, {
      availability: tenant.availability,
      limit: value,
    });
  }

  function onApplyAvailabilityToAllSelected(availability) {
    const selectedTenants = tenantsSettings.filter((tenant) => {return selectedRowKeys.includes(tenant.tenant_id)});
    const tenantSettings = selectedTenants.map((tenant) => ({
      availability,
      limit: tenant.limit,
      tenant_id: tenant.tenant_id,
      enrichment_id: tenant.enrichment_name
    }));

    onUpdateManyEnrichmentTenantSettings(tenantSettings);
    setSelectedRowKeys([]);
    NotificationManager.success("Availability updated")
  }

  function onApplyLimitToAllSelected(limit) {
    const selectedTenants = tenantsSettings.filter((tenant) => {return selectedRowKeys.includes(tenant.tenant_id)});
    const tenantSettings = selectedTenants.map((tenant) => ({
      availability: tenant.availability,
      limit,
      tenant_id: tenant.tenant_id,
      enrichment_id: tenant.enrichment_name
    }));

    onUpdateManyEnrichmentTenantSettings(tenantSettings);
    setSelectedRowKeys([]);
  }

  const rowSelection = {
    fixed: true,
    selectedRowKeys,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      {
        key: 'unselect',
        text: 'Unselect all data',
        onSelect: () => setSelectedRowKeys([]),
      },
    ],
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const columns = [
    {
      title: 'Tenant Name',
      dataIndex: 'tenant_name',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={`Search`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <AntButton onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
              Reset
            </AntButton>
            <AntButton
              type='primary'
              onClick={() => handleSearch(selectedKeys, confirm)}
              icon={<SearchOutlined/>}
              size='small'
              style={{ width: 90 }}
            >Search</AntButton>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined ref={searchIcon} style={{ color: filtered ? '00c2b2' : '#454444', fontSize: 20 }}/>,
      onFilter: searchNameFilter,
      onFilterDropdownVisibleChange: visible => {
        if (visible) selectSearchFilter();
      },
      render: (tenant_name) => (<Href href={`/tenants/${tenant_name}`}>{tenant_name}</Href>),
      sorter: (a, b) => a.tenant_name > b.tenant_name
    },
    {
      title: 'Tenant Id',
      dataIndex: 'tenant_id',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={`Search`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <AntButton onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
              Reset
            </AntButton>
            <AntButton
              type='primary'
              onClick={() => handleSearch(selectedKeys, confirm)}
              icon={<SearchOutlined/>}
              size='small'
              style={{ width: 90 }}
            >Search</AntButton>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined ref={searchIcon} style={{ color: filtered ? '00c2b2' : '#454444', fontSize: 20 }}/>,
      onFilter: searchIdFilter,
      onFilterDropdownVisibleChange: visible => {
        if (visible) selectSearchFilter();
      },
      render: (tenant_id, record) => (<Href href={`/tenants/${record.tenant_name}`}>{tenant_id}</Href>),
      sorter: (a, b) => a.tenant_id > b.tenant_id,
    },
    {
      title: 'Tenant Group',
      dataIndex: 'tenant_type',
      render: (tenant_type, record) => (tenant_type && <Tag>{tenant_type}</Tag>),
      sorter: (a, b) => a.tenant_type > b.tenant_type,
      filters: [ { value: null, text: 'EMPTY' }, ...tenantsTypes.map((el) => { return { value: el, text: el }; })],
      onFilter: (value, record) => record.tenant_type === value,
    },
    {
      title: 'Tenant Tags',
      dataIndex: 'tenant_tags',
      render: (tenant_tags, record) => <TagsView value={tenant_tags}/>,
      filters: tenantsTags.map((el) => { return { value: el, text: el }; }),
      onFilter: (value, record) => record.tenant_tags ? record.tenant_tags.includes(value) : '',
    },
    {
      title: 'Availability',
      dataIndex: 'availability',
      filters: [
        {
          text: 'Disable',
          value: 0,
        },
        {
          text: 'Deployed projects only',
          value: 1,
        },
        {
          text: 'Active',
          value: 2,
        },
      ],
      render: (availability, record) => (
        loadingTenantIds[record.tenant_id] ? "loading..." :
        <AvailabilityDropdown
          resource_name='Enrichment'
          disabled={disableEdit}
          value={availability}
          onChange={(v) => onAvailabilityChange(v, record)}
        />
      ),
      onFilter: (value, record) => record.availability === value,
    }
  ];

  if (addLimit) {
    columns.push({
      title: 'Limit',
      dataIndex: 'limit',
      render: (limit, record) => (
        <Limit
          disabled={disableEdit}
          loading={loading}
          defaultLimit={record.defaultLimit}
          value={limit}
          editing={(editingIndex === record.tenant_id)}
          onStartEditing={() => setEditingIndex(record.tenant_id)}
          onCancelEditing={() => setEditingIndex(null)}
          onValueChange={(v) => onLimitChange(v, record)}
        />
      ),
    })
  }

  function handleSearch(selectedKeys, confirm) {
    confirm();
  }

  function handleReset(clearFilters) {
    clearFilters();
  }

  function searchNameFilter(value, record) {
    return (record.tenant_name || '').toLowerCase().includes(value.toLowerCase()) || (record.tenant_name || '').toLowerCase().includes(value.toLowerCase());
  }

  function searchIdFilter(value, record) {
    return (record.tenant_id || '').toLowerCase().includes(value.toLowerCase()) || (record.tenant_id || '').toLowerCase().includes(value.toLowerCase());
  }

  function selectSearchFilter() {
    setTimeout(() => searchInput.current.select());
  }

  document.addEventListener('keydown', (e) => {
    let charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.metaKey || e.ctrlKey) && charCode === 'f') onControlFPress(e);
  });

  function onControlFPress(e) {
    if (!searchInput.current || !searchInput.current.state.focused) {
      setTimeout(() => searchIcon.current.click());
      setTimeout(() => searchInput.current.focus(), 100);
      e.returnValue = false;
    }
  }

  const onUpdateLimitBulkClick= () =>{
    const isValidUpdatedValue = !isNaN(parseInt(limitBulkValue)) || limitBulkValue=='no limit'
    if (isValidUpdatedValue) {
      const updatedValue =limitBulkValue=='no limit'? null: parseInt(limitBulkValue);
      onApplyLimitToAllSelected(updatedValue);
      setLimitBulkValue('')
      NotificationManager.success("Limit updated")
    }
    else{
      NotificationManager.error("Limit can updated only with Number or with 'no limit'");
    }
  }
  return (
    <>
      <div style={{ marginTop: 16 }}>
        <Dropdown disabled={selectedRowKeys.length === 0}
                  text='Availability'
                  icon='angle down'
                  className='icon'
                  floating
                  labeled
                  button
        >
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => onApplyAvailabilityToAllSelected(0)}>Make Disable</Dropdown.Item>
            <Dropdown.Item onClick={() => onApplyAvailabilityToAllSelected(1)}>Make Deployed projects only</Dropdown.Item>
            <Dropdown.Item onClick={() => onApplyAvailabilityToAllSelected(2)}>Make Active</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Input
              type="text"
              min={0}
              onChange={(v) => {
                setLimitBulkValue(v.target.value)
              }
          }
              value={limitBulkValue}
              disabled={selectedRowKeys.length === 0}
              style={{ marginLeft:'10px', width:'180px'}}
              placeholder={"Limit- number or 'no limit'"}
          />
        <AntButton type="primary" style={{ fontWeight:'700' }} disabled={selectedRowKeys.length === 0} onClick={onUpdateLimitBulkClick}>Update</AntButton>
          <span style={{ marginLeft: 8 }}>
            {selectedRowKeys.length > 0 ? `Selected ${selectedRowKeys.length} items` : ''}
          </span>
        <br/>
      </div>
      {disableLegacyEnrichmentEdit && <div style={{paddingTop: "12px"}}><LegacyEnrichmentWarning/></div>}
      {disablePremiumEnrichmentEdit && <div style={{paddingTop: "12px"}}><PremiumEnrichmentWarning/></div>}
      <Table
        loading={loading}
        dataSource={tenantsSettings}
        columns={columns}
        rowSelection={!disableEdit ? rowSelection: null}
        rowKey={record => record.tenant_id}
        size='middle'
        tableLayout='fixed'
        pagination={{ defaultPageSize: 20, position: ['bottomRight', 'topRight'] }}
      />
    </>
  );
}
