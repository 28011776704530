import React from 'react';
import { Button, Icon, Transition } from 'semantic-ui-react';

export default function CancelButton(props) {
  const { disabled, loading, hideWhenDisabled, title } = props;

  return (
    <Transition visible={!(hideWhenDisabled && disabled)} animation='scale' duration={500}>
      <Button basic disabled={disabled} floated='right' onClick={props.onClick} loading={loading} size='mini'>
        <Icon name='cancel'/> {title || 'Cancel'}
      </Button>
    </Transition>
  );
}
