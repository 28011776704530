import React, {useEffect, useState} from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {useHistory, useParams} from "react-router-dom";
import apiClient from "../../api/apiClient";
import {NotificationManager} from "react-notifications";
import {Popconfirm, Checkbox} from "antd";
import {Button} from "semantic-ui-react";

export default function FeatureFlagTenants(){
    const { featureFlag} = useParams();
    const [data, setData] = useState([])
    const [removedTenants, setRemovedTenants] = useState([])
    const [selectedTenants, setSelectedTenants] = useState([]);
    const [editedTenants, setEditedTenants] = useState({});

    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory();

    useEffect(() => {
        async function fetchData() {
            try {
                const { data } = await apiClient.get(`/feature_tenants/${featureFlag}`);
                setData(data);
            } catch (error) {
                NotificationManager.error(error.message);
            }
        }
        fetchData();
    }, []);
    const setSelectedList = (isAdded,tenantID )=>{
        setSelectedTenants((prevSelectedTenants) => {
            if (isAdded) {
                return [...prevSelectedTenants, tenantID];
            } else {
                return prevSelectedTenants.filter((ID) => ID !== tenantID);
            }
        });
    };
    const TenantNameCellRender = ({ value }) => {
        const handleClick = () => {
            history.push(`/tenants/${value}`);
        };

        return (
            <div className="feature-flag-clickable-area" onClick={handleClick}>
                {value}
            </div>
        );
    };
const TenantIDCellRender = (params) => {
        const tenantID = params.api.getValue('tenantID', params.node)

        const onCheckboxClick = (e) => {
            const isChecked = e.target.checked;
            setSelectedList(isChecked, tenantID)
        };
        return (
            <Checkbox onClick={onCheckboxClick} disabled={isLoading||removedTenants.includes(tenantID)} checked={selectedTenants.includes(tenantID)&&!removedTenants.includes(tenantID)}>
                <div>
                    {tenantID}
                </div>
            </Checkbox>

        );
    };

    const ControlBtns = () => {
        const removeConfirm= async () => {
            setIsLoading(true)
            let feature_tenants_obj = {}
            feature_tenants_obj[featureFlag] = selectedTenants
            await removeFromTenants(feature_tenants_obj)
            for (const tenantID of selectedTenants){
                setSelectedList(false, tenantID)
            }
            setIsLoading(false)

        };

        return (
            <div style={{ display:'flex',marginLeft:'15%', marginTop:'10px'}}>
                <Popconfirm
                    placement="leftBottom"
                    title={`Are you sure you want to remove ${featureFlag} from selected tenants?`}
                    onConfirm={removeConfirm}
                >
                    <Button  style={{fontSize:"13px",background:'#d8e6f0'}} disabled={isLoading||!selectedTenants.length}>Remove feature from selected tenants</Button>
                </Popconfirm>
                <Popconfirm
                    placement="leftBottom"
                    title={`Are you sure you want to Edit ${featureFlag} for selected tenants?`}
                    onConfirm={saveEditedData}
                >
                    <Button  style={{fontSize:"13px",background:'#d8e6f0', marginLeft:'10px'}} disabled={isLoading||!Object.keys(editedTenants).length}>Save edit changes</Button>
                </Popconfirm>
            </div>
        );
    };

    const ValueCellRenderer = (params) => {
        const value = params.api.getValue('value', params.node);
        const type = typeof value;
        const tenantID = params.api.getValue('tenantID', params.node);
        return (
            <div className="feature-flag-clickable-area">
                {!removedTenants.includes(tenantID) ? `${value} `: 'Removed'}
                {value!=null &&!removedTenants.includes(tenantID)&& <span style={{ fontSize: '12px' }}>{`(${type})`}</span>}
            </div>
        );
    };

    const handleCellValueChanged= async (params) => {
        const tenantID = params.api.getValue('tenantID', params.node);
        let newValue = params.newValue
        if (params.newValue.toLowerCase() == "true" || params.newValue.toLowerCase() == "false") {
            newValue = params.newValue.toLowerCase() == "true"
        }
        await updateData(newValue,tenantID)

    }

    const updateData = async (newValue,tenantID) => {
        const updatedTransformedArray = data.map(rowData =>
            rowData.tenantID === tenantID
                ? {...rowData, value: newValue}
                : rowData
        );
        setEditedTenants((prevState)=>{
            prevState[tenantID] = newValue
            return prevState
        })
        setData(updatedTransformedArray)
    }
    const removeFromTenants = async(features_tenants_obj)=>{
        apiClient.put(`/delete_from_tenant_config`, features_tenants_obj).then(() => {
            NotificationManager.success(`The feature removed from selected tenants`);
            setRemovedTenants((prevTenants) => [...prevTenants,...selectedTenants]);
        }).catch((error) => {
            NotificationManager.error(error.message);
        });
    }
    const saveEditedData = async () => {
        apiClient.put(`/update_tenant_config/${featureFlag}`, editedTenants).then(() => {
            NotificationManager.success('Changes saved');
            setEditedTenants({})
        }).catch((error) => {
            NotificationManager.error(error.message);
        });
    }

    const rowData = data.map(obj => {
        return {
            'tenantID': obj['tenantID'],
            'tenantName':obj['tenantName'],
            'value':obj['value'],
        };
    });

    const columnDefs = [
        { headerName: 'Tenant ID', field: 'tenantID', flex:2, cellRendererFramework:TenantIDCellRender},
        { headerName: 'Tenant Name', field: 'tenantName', flex:2, cellRendererFramework:TenantNameCellRender, cellClass:'feature-flag-selectable'},
        { headerName: 'Value', field: 'value', flex:1, cellRendererFramework: ValueCellRenderer, cellClass:'feature-flag-selectable',editable: true,onCellValueChanged: handleCellValueChanged}
    ];


    return (
        <>
            <ControlBtns/>
            <div className="ag-theme-alpine">
                <AgGridReact rowData={rowData} columnDefs={columnDefs} getRowStyle={() => ({ fontSize: '16px', })} getRowHeight={() => 50} overlayNoRowsTemplate={'<span style="font-size: 16px" >Loading Data ...</span>'} alwaysShowVerticalScroll={false}  domLayout={'autoHeight'} suppressRowHoverHighlight={true} suppressCellFocus={true}></AgGridReact>
            </div>
        </>
    );
};

