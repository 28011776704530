import { combineReducers } from 'redux';

import taskReducer from './taskReducer';
import settingsReducer from './settingsReducer';
import enrichmentsReducer from './enrichmentsReducer';
import tenantsReducer from './tenantsReducer';
import configsReducer from './configsReducer';
import glueReducer from './glueReducer';

export default combineReducers({
  taskReducer,
  settingsReducer,
  enrichmentsReducer,
  tenantsReducer,
  configsReducer,
  glueReducer,
});
