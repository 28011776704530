import {
  FETCH_REQUEST,
  FETCH_FAILURE,
  FETCH_SUCCESS,
  FETCH_TENANT_ENRICHMENTS_SUCCESS,
  FETCH_CLIENTS_SUCCESS,
  FETCH_SAMPLE_PROJECTS_SUCCESS,
} from '../actions/tenantsActions';

export default (state = { isLoading: false, clientEnrichments: [], hasMoreClientEnrichments: true, clientEnrichmentsCount: 0, clients: [], sampleProjectLists: [] }, action) => {
  switch (action.type) {
    case FETCH_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case FETCH_TENANT_ENRICHMENTS_SUCCESS: {
      return {
        ...state,
        clientEnrichments: action.clientEnrichments,
        hasMoreClientEnrichments: action.hasMoreClientEnrichments,
        clientEnrichmentsCount: action.clientEnrichmentsCount,
        isLoading: false,
      };
    }
    case FETCH_CLIENTS_SUCCESS: {
      return {
        ...state,
        clients: action.clients,
        isLoading: false,
      };
    }
    case FETCH_SAMPLE_PROJECTS_SUCCESS: {
      return { ...state, sampleProjectLists: action.sampleProjectLists, isLoading: false };
    }
    default:
      return state;
  }
};
