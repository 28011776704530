import React from 'react';
import { Tag } from 'antd';
import styled from 'styled-components';

import { exploriumColors } from '../../../common/colors';

const StyledTag = styled(Tag)`
  color: ${exploriumColors.exploriumDarkGrey};
`;

const StyledDiv = styled.div`
  max-height: 150px;
`;

const StyledTotal = styled.span`
  max-height: 150px;
  font-size: 12px;
  color: ${exploriumColors.exploriumBlack};
`;

const SHOW_LISTS = 3;

function SubList({ value }) {
  const len = value ? value.length : 0;

  return (
    <StyledTag>
      {'{'}{value && value.slice(0, SHOW_LISTS).join(', ')}{'}'}
      {len > SHOW_LISTS && <StyledTotal> +{len - SHOW_LISTS}</StyledTotal>}
    </StyledTag>
  );
}

export default function ListOfListsView({ value }) {
  const len = value ? value.length : 0;

  return (
    <StyledDiv>
      {value && value.slice(0, SHOW_LISTS).map((sublist, i) => <>
        <SubList value={sublist} key={`list-${i}`} />
      </>)}
      {len > SHOW_LISTS && <StyledTotal><br/>+{len - SHOW_LISTS}</StyledTotal>}
    </StyledDiv>
  );
}
