import React from "react";
import ReactDOM from "react-dom";
import "./config";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import "semantic-ui-css/semantic.min.css";
import "antd/dist/antd.css";
import "react-keyed-file-browser/dist/react-keyed-file-browser.css";
import "react-notifications/lib/notifications.css";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/mode/python/python";
import { QueryClientProvider } from "react-query";
import {queryClient} from "./react-query/queryClient";
import "swagger-ui-react/swagger-ui.css";
import "./index.css";

const rootElement = document.getElementById("root");

ReactDOM.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </BrowserRouter>,
  rootElement
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
