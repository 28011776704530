import React, { useEffect } from 'react';
import { Modal, Button, Form, Input } from 'antd';
import { isEmpty } from 'lodash';

import AuthorsEditor from '../../shared/Components/AuthorsEditor';
import InfoTooltip from '../../shared/Components/InfoTooltip';

const layout = { labelCol: { span: 6 }, wrapperCol: { span: 16 } };

const defaultSecret = {
  key: null,
  value: null,
};

export default function SecretModalForm({ visible, onSubmit, onCancel, title, initialValues, okText = 'Submit', editing = false }) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialValues) {
      if (isEmpty(initialValues)) {
        form.setFieldsValue({ ...defaultSecret });
      } else {
        form.setFieldsValue({ ...initialValues });
      }
    }
  }, [initialValues]);

  function onFinish(values) {
    form.setFieldsValue(defaultSecret);
    onSubmit(Object.assign(defaultSecret, values));
  }

  const checkName = (_, value) => {
    if (editing) {
      return Promise.resolve();
    }

    if (!value) {
      return Promise.reject();
    }

    const regex = /^[0-9A-Z_]+$/;

    if (value.match(regex)) {
      return Promise.resolve();
    } else {
      return Promise.reject('Allowed symbols: A-Z 0-9 _');
    }
  };

  return (
    <Modal
      destroyOnClose
      onCancel={onCancel}
      title={title}
      visible={visible}
      footer={[
        <Button onClick={onCancel} key={'cancel'}>Cancel</Button>,
        <Button form='secret' key='submit' htmlType='submit' type='primary'>{okText}</Button>
      ]}
    >
      <Form name='secret' form={form} onFinish={onFinish} initialValues={defaultSecret}{...layout}>
        <Form.Item
          hidden={editing}
          label={<>Key&nbsp;<InfoTooltip title='The key that the secret can be found by'/></>}
          name='key'
          rules={[{validator: checkName}, {required: !editing, message: 'Please enter key'}]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label={<>Value&nbsp;<InfoTooltip title='Secret value'/></>}
          name='value'
          rules={[{required: !editing, message: 'Please enter value'}]}
        >
          <Input/>
        </Form.Item>

        {/*<Form.Item*/}
        {/*  label={<>Authors&nbsp;<InfoTooltip title='Share the permission to edit your secret by selecting emails'/></>}*/}
        {/*  name='authors'*/}
        {/*>*/}
        {/*  <AuthorsEditor/>*/}
        {/*</Form.Item>*/}
      </Form>
    </Modal>
  );
}
