import React from 'react';
import { Dropdown, Input } from 'semantic-ui-react';

import { enrichmentsMetadataStages } from './../../shared/data/tables';

const stagesOptions = enrichmentsMetadataStages.map((stage) => { return { value: stage, text: stage, key: stage }; });

export default function CatalogItemCopyParams(props) {
  const { destinationStages, destinationEnrichmentName } = props;

  function onDestinationStagesChange(e, { value }) {
    props.onChange(value, destinationEnrichmentName);
  }

  function onDestinationEnrichmentNameChange({ target }) {
    props.onChange(destinationStages, target.value);
  }

  return (
    <>
      <Dropdown placeholder='Stages' fluid multiple search selection options={stagesOptions} value={destinationStages} onChange={onDestinationStagesChange}/>
      <div style={{ paddingTop: 15 }}>
        <Input label={{ content: 'Destination enrichment name' }} value={destinationEnrichmentName} onChange={onDestinationEnrichmentNameChange}/>
      </div>
    </>
  )
}
