import React, { useState } from 'react';
import { Header, Confirm, Button } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';

import PopupHint from '../../shared/fields/PopupHint';
import CopyToTenantsParams from './CopyToTenantsParams';
import { updateTenants } from './../../redux/actions/configsActions';

export default function CopyTenantsSettings(props) {
  const { loading, disabled, tenantId, tenantName, config,
    floated, allowConfig, allowEnrichments, hint
  } = props;
  const [copyToTenantsParams, setCopyToTenantsParams] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const { destinationTenantIds } = copyToTenantsParams;
  const isMultipleCopyTo = (destinationTenantIds && destinationTenantIds.length > 1);
  const dispatch = useDispatch();

  function onConfirm() {
    setIsOpen(false);

    if (destinationTenantIds && destinationTenantIds.length > 0) {
      dispatch(updateTenants({...copyToTenantsParams, config, sourceTenantId: tenantId, sourceTenantName: tenantName }));
    }
  }

  return (
    <>
      <PopupHint content={hint}>
        <Button
          basic
          size='mini'
          icon='copy'
          floated={floated}
          disabled={disabled || loading}
          onClick={() => setIsOpen(true)}
        />
      </PopupHint>

      <Confirm
        header={(
          <>
            <Header size='tiny'>You are going to export "{tenantName}" settings to the following tenant{isMultipleCopyTo ? 's' : ''}:</Header>
            <CopyToTenantsParams
              allowConfig={allowConfig}
              allowEnrichments={allowEnrichments}
              loading={loading}
              onChange={setCopyToTenantsParams}
              tenantId={tenantId}
              tenantName={tenantName}
            />
          </>
        )}
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        onConfirm={onConfirm}
      />
    </>
  );
}
