import React from 'react';
import { Button } from 'semantic-ui-react';

import PopupHint from '../../shared/fields/PopupHint';

export default function ActionsButton(props) {
  const { icon, tooltip, onClick, loading, disabled } = props;

  return (
    <PopupHint content={tooltip}>
      <Button
        basic
        size='mini'
        floated='right'
        loading={loading}
        disabled={loading || disabled}
        onClick={onClick}
        icon={icon}
      />
    </PopupHint>
  );
}
