import React from 'react';
import { Tag } from 'antd';
import styled from 'styled-components';

import { exploriumColors } from '../../../common/colors';

const StyledTag = styled(Tag)`
  color: ${exploriumColors.exploriumDarkGrey};
`;

export default function TagsView({ value }) {
  return (
    <div style={{ overflow: 'auto' }}>
      <span>
        {value && value.map((tag, i) => (
          <StyledTag
            key={i}
            color={exploriumColors.exploriumLightGrey}
          >{tag ? tag.toUpperCase() : ''}</StyledTag>
        ))}
      </span>
    </div>
  );
}
