import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useHistory } from 'react-router-dom';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import apiClient from "../../api/apiClient";
import { NotificationManager } from "react-notifications";
import {Badge, Popconfirm, Tooltip, Checkbox} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import {Button} from "semantic-ui-react";

export default function FeatureFlags() {

    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(false)
    const [removedFlags, setRemovedFlags] = useState([]);
    const [selectedFlags, setSelectedFlags] = useState([]);
    const history = useHistory();

    const badgetStatusMap = {
        0: 'error',
        1: 'warning',
        2: 'success'
    }
    useEffect(() => {
        async function fetchData() {
            try {
                const { data } = await apiClient.get('/get_feature_flags_list');
                setData(data);
            } catch (error) {
                NotificationManager.error(error.message);
            }
        }
        fetchData();
    }, []);

    const setSelectedList = (isAdded,featureFlag,numToDelete)=>{
        setSelectedFlags((prevSelectedFlags) => {
            if (isAdded) {
                return [...prevSelectedFlags, {'featureFlag':featureFlag,'numToDelete':numToDelete}];
            } else {
                return prevSelectedFlags.filter((flag) => flag['featureFlag'] !== featureFlag);
            }
        });
    }
    const FindBudget = (arr, prop) => {
        const checkAllSameValue = (arr, prop) => {
            const firstValue = arr[0][prop];
            return arr.every((obj) => obj[prop] === firstValue);
        };
        const checkAllSameType = (arr, prop) => {
            return arr.every((obj) => typeof obj[prop] == "boolean");
        };
        const isSameType = checkAllSameType(arr, prop);
        return isSameType? checkAllSameValue(arr, prop)?0:2:1
    };
    const FeatureNameRender = (params) => {
        const numToDelete = params.api.getValue('numberOfUses', params.node)
        const featureFlag = params.api.getValue('featureFlag', params.node)
        const featureFlagTenantsList = data[featureFlag]['tenants_list']

        const handleNameClick = () => {
            history.push(`/feature-flags/${featureFlag}`);
        };
        const onCheckboxClick = (e) => {
            const isChecked = e.target.checked;
            setSelectedList(isChecked,featureFlag,numToDelete)
        };
        return (
            <Checkbox onClick={onCheckboxClick} disabled={isLoading||removedFlags.includes(featureFlag)} checked={selectedFlags.some((flag) => flag.featureFlag === featureFlag)&&!removedFlags.includes(featureFlag)}>
                <div className="feature-flag-clickable-area" onClick={handleNameClick}>
                    <Badge status={badgetStatusMap[FindBudget(featureFlagTenantsList, 'value')] } text={<span style={{fontSize:'16px'}} >{featureFlag}</span>} ></Badge>
                </div>
            </Checkbox>

        );
    };
    const removeFromTenants = async(features_tenants_obj)=>{
        apiClient.put(`/delete_from_tenant_config`, features_tenants_obj).then(() => {
            NotificationManager.success(`The selected features removed`);
            for (const flag of selectedFlags) {
                setRemovedFlags((prevRemovedFlags) => [...prevRemovedFlags, flag.featureFlag]);
            }
        }).catch((error) => {
            NotificationManager.error(error.message);
        });
    }

    const RemoveBtn = () => {
        const removeConfirm= async () => {
            setIsLoading(true)
            let features_tenants_list_obj = {}
            for (const flag of selectedFlags) {
                features_tenants_list_obj[flag.featureFlag] = Object.values(data[flag.featureFlag])[0].map(obj=>obj['tenantID'])
                setSelectedList(false,flag.featureFlag, flag.numToDelete)
            }
            await removeFromTenants(features_tenants_list_obj)

            setIsLoading(false)
        }
        return (
            <Popconfirm
                placement="leftBottom"
                title={`Are you sure you want to remove the selected featuresFlags from all tenants?`}
                onConfirm={removeConfirm}
            >
                <Button  style={{fontSize:"13px",background:'#d8e6f0', display:'flex',marginLeft:'15%',marginTop:'10px'}} disabled={isLoading || !selectedFlags.length}>Remove Selected From All Tenants</Button>
            </Popconfirm>
        );
    };

    const FeatureNumberTable = Object.entries(data).map(([key, value]) => ({
        'featureFlag': key,
        'numberOfUses': removedFlags.includes(key) ? 0 : value['tenants_list'].length,
    }));

    const rowData = FeatureNumberTable;
    const FeatureNameHeader = ({ tooltipContent }) => {
        return (
            <div style={{fontSize:'16px', fontWeight: 600}}>
                Feature Flag{' '}
                <Tooltip title={tooltipContent} placement='bottomLeft' autoAdjustOverflow={true} overlayStyle={{backgroundColor: 'cadetblue', paddingTop:'0'}}>
                    <InfoCircleOutlined style={{ marginLeft: 4 }} />
                </Tooltip>
            </div>
        );
    };


    const columnDefs = [
        {
            headerName: 'Feature Flag',
            field: 'featureFlag',
            flex: 2,
            cellRendererFramework: FeatureNameRender,
            cellClass: 'feature-flag-selectable',
            headerComponentFramework: FeatureNameHeader,
            headerComponentParams: { tooltipContent: (
                    <div>
                        <Badge style={{color:'white',padding:'7px'}} status="success" text="Boolean, different values" />
                        <Badge style={{color:'white',padding:'7px'}} status="warning" text="different types" />
                        <Badge style={{color:'white',padding:'7px'}} status="error" text="Boolean, same value" />
                    </div>
                )},
        },
        {
            headerName: 'Number Of Uses',
            field: 'numberOfUses',
            flex: 1,
            sortable:true,
            unSortIcon: true,
        }
    ];


    return (
        <>
            <RemoveBtn/>
            <div className="ag-theme-alpine">
                <AgGridReact rowData={rowData} columnDefs={columnDefs} getRowStyle={() => ({ fontSize: '16px'})} getRowHeight={() => 50} domLayout={'autoHeight'}
                             overlayNoRowsTemplate={'<span style="font-size: 16px" >Loading Data ...</span>'} suppressRowHoverHighlight={true} suppressCellFocus={true} />
            </div>

        </>
    );
}
