import auth0 from 'auth0-js';
import config from 'react-global-configuration';

import apiClient from '../api/apiClient';
import history from '../history';

const LOGIN_SUCCESS_PAGE = '/';

export default class Auth {
  constructor() {
    this.initAuth0 = this.initAuth0.bind(this);
    this.login = this.login.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.setSession = this.setSession.bind(this);
    this.renewSession = this.renewSession.bind(this);
    this.logout = this.logout.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);

    this.initAuth0();
  }

  initAuth0() {
    this.auth0 = new auth0.WebAuth({
      domain: config.get('auth0Domain'),
      clientID: config.get('auth0clientID'),
      redirectUri: `${window.origin}/callback`,
      audience: config.get('auth0Audience'),
      responseType: 'token id_token',
      scope: 'openid profile email',
    });
  }

  login() {
    this.auth0.authorize();
  }

  handleAuthentication() {
    this.auth0.parseHash((error, authResult, dResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult, true);
      } else if (error) {
        console.error(error);
      }
    });
  }

  setSession(authResult, useRedirect=false) {
    const expiresAt = (authResult.expiresIn * 1000) + new Date().getTime();

    apiClient.defaults.headers.common = { Authorization: `Bearer ${authResult.accessToken}` };
    localStorage.setItem('is_logged_in', 'true');
    localStorage.setItem('expires_at', expiresAt);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('access_token', authResult.accessToken);

    const { idTokenPayload } = authResult;
    if (idTokenPayload) {
      localStorage.setItem('email', idTokenPayload.email);
      localStorage.setItem('user', idTokenPayload.nickname);
      localStorage.setItem('picture', idTokenPayload.picture);
      localStorage.setItem('roles', idTokenPayload[config.get('rolesPayloadKey')].join());
    }

    apiClient.get(`/profile/user-permissions`)
    .then(response => {
      localStorage.setItem('permissions', response.data.permissions.join());
    })

    if (useRedirect) {
      const redirect = localStorage.getItem('redirect');
      localStorage.removeItem('redirect');
      history.replace(redirect);
    }
  }

  renewSession() {
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      } else if (err) {
        console.error(err);
        if (err.code === 'login_required') this.login();
      }
    });
  }

  logout() {
    localStorage.removeItem('is_logged_in');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('id_token');
    localStorage.removeItem('access_token');
    localStorage.removeItem('user');
    localStorage.removeItem('picture');
    localStorage.removeItem('email');
    localStorage.removeItem('roles');
    localStorage.removeItem('permissions');

    this.auth0.logout({ returnTo: window.location.origin });
    history.replace(LOGIN_SUCCESS_PAGE);
  }

  isAuthenticated() {
    const expiresAt = Number(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }

  get profile() {
    return {
      email: localStorage.getItem('email'),
      picture: localStorage.getItem('picture'),
      user: localStorage.getItem('user'),
      roles: localStorage.getItem('roles'),
      permissions: localStorage.getItem('permissions')
    };
  }

  get roles() {
    const roles = localStorage.getItem('roles');
    return roles ? roles.split(',') : [];
  }

  get permissions() {
    const permissions = localStorage.getItem('permissions');
    return permissions ? permissions.split(',') : [];
  }

}
