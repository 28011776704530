import React, { Component } from 'react';

class Logout extends Component {
  constructor(props) {
    super(props);
    this.props.auth.logout();
  }

  render() {
    return (
      <h5>loading...</h5>
    );
  }
}

export default Logout;
