import React from 'react';
import styled from 'styled-components';

import { exploriumColors } from '../../../common/colors';

const StyledLabel = styled.div`
  color: ${exploriumColors.exploriumBlack};
  font-size: 14px;
`;

export default function LabelsView({ value }) {
  return (
    <div style={{ overflow: 'auto' }}>
      <span>
        {value && value.map((tag, i) => (
          <StyledLabel key={i}>{tag ? tag : ''}</StyledLabel>
        ))}
      </span>
    </div>
  );
}
