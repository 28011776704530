import React from 'react';
import { Select, Menu } from 'antd';

import { getOntologyDefaultIcon } from '../../../common/utils';

export default function BulkActions({ ontologies, onChange }) {

  return (
    <Select
      dropdownStyle={{ minWidth: 200 }}
      placeholder='Bulk actions'
      bordered={false}
      dropdownRender={menu => (
        <Menu>
          <Menu.Item onClick={() => onChange({ signal_studio: true })}>Turn Signal Studio On</Menu.Item>
          <Menu.Item onClick={() => onChange({ signal_studio: false })}>Turn Signal Studio Off</Menu.Item>
          <Menu.Item>
            <Select
              style={{ minWidth: 160}}
              placeholder='Select an ontology'
              optionFilterProp='children'
              autoClearSearchValue
              showSearch
              onChange={(ontology) => onChange({ ontology, icon: getOntologyDefaultIcon(ontology) })}
              dropdownStyle={{ minWidth: 200}}
            >
              {ontologies.map(ontology => <Select.Option key={ontology} value={ontology}>{ontology}</Select.Option>)}
            </Select>
          </Menu.Item>
        </Menu>
      )}
    />
  );
}
