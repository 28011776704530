import React, { useEffect, useState, useRef } from 'react';
import Highlighter from 'react-highlight-words';
import { Table, Popover, Button, Popconfirm, Tag } from 'antd';
import { SearchOutlined, MoreOutlined, StopOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { NotificationManager } from 'react-notifications';

import { pagination } from '../../shared/Components/Table/parameters';
import apiProfile from '../../api/apiProfile';
import SearchFilterDropdown from '../../shared/Components/Table/SearchFilterDropdown';
import { parseAndFormatDate } from '../../common/utils';

export default function ApiTokens({}) {
  const searchInput = useRef();
  const searchIcon = useRef();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    document.title = 'API Tokens';
    fetchData();
  }, []);

  async function fetchData() {
    try {
      setLoading(true);

      const { data } = await apiProfile.getApiTokens();
      setData(data);
    } catch (error) {
      NotificationManager.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  function handleSearch(selectedKeys, confirm) {
    confirm();
    setSearchText(selectedKeys[0]);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  }

  function selectSearchFilter() {
    setTimeout(() => searchInput.current.select());
  }

  async function set_user_api_lock(user_id, lock_status) {
    setLoading(true);
    try {
      if (lock_status) {
        await apiProfile.lockUserApiUsage(user_id);
        NotificationManager.success("User's API usage has been locked");
      } else {
        await apiProfile.unlockUserApiUsage(user_id);
        NotificationManager.success("User's API usage has been unlocked");
      }
    } catch (error) {
      NotificationManager.error(error.message);
    } finally {
      setLoading(false);
    }

    await fetchData();
  }

  async function invalidate_user_token(user_id) {
    setLoading(true);
    try {
      await apiProfile.InvalidateUserToken(user_id);
      NotificationManager.success("User's token has been invalidated");
    } catch (error) {
      NotificationManager.error(error.message);
    } finally {
      setLoading(false);
    }

    await fetchData();
  }

  const columns = [
    {
      title: 'User Email',
      dataIndex: 'email',
      width: '30%',
      sorter: (a, b) => a.email.localeCompare(b.email),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilterDropdown
          multiple
          title='User Email'
          inputRef={searchInput}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          handleSearch={handleSearch}
          handleReset={handleReset}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filterIcon: filtered => <SearchOutlined ref={searchIcon} style={{ color: filtered ? '00c2b2' : '#454444', fontSize: 20 }}/>,
      onFilterDropdownVisibleChange: visible => { if (visible) selectSearchFilter(); },
      render: (key) => searchText ? <Highlighter
        title='User Email'
        autoEscape
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        textToHighlight={key ? key.toString() : ''}
      /> : <h4 title='User Email'>{key}</h4>,
      onFilter: (value, record) => record.email ? record.email.toString().toLowerCase().includes(value.toLowerCase()) : '',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '30%',
      sorter: (a, b) => a.name.localeCompare(b.name),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilterDropdown
          multiple
          title='Name'
          inputRef={searchInput}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          handleSearch={handleSearch}
          handleReset={handleReset}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filterIcon: filtered => <SearchOutlined ref={searchIcon} style={{ color: filtered ? '00c2b2' : '#454444', fontSize: 20 }}/>,
      onFilterDropdownVisibleChange: visible => { if (visible) selectSearchFilter(); },
      render: (key) => searchText ? <Highlighter
        title='Name'
        autoEscape
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        textToHighlight={key ? key.toString() : ''}
      /> : <h4 title='Name'>{key}</h4>,
      onFilter: (value, record) => record.name ? record.name.toString().toLowerCase().includes(value.toLowerCase()) : '',
    },
    {
      title: 'User ID',
      dataIndex: 'user_id',
      width: '30%',
      sorter: (a, b) => a.email.localeCompare(b.email),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilterDropdown
          multiple
          title='User ID'
          inputRef={searchInput}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          handleSearch={handleSearch}
          handleReset={handleReset}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filterIcon: filtered => <SearchOutlined ref={searchIcon} style={{ color: filtered ? '00c2b2' : '#454444', fontSize: 20 }}/>,
      onFilterDropdownVisibleChange: visible => { if (visible) selectSearchFilter(); },
      render: (key) => searchText ? <Highlighter
        title='User ID'
        autoEscape
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        textToHighlight={key ? key.toString() : ''}
      /> : <h4 title='User ID'>{key}</h4>,
      onFilter: (value, record) => record.user_id ? record.user_id.toString().toLowerCase().includes(value.toLowerCase()) : '',
    },
    {
      title: 'Expiration Date',
      dataIndex: 'api_token_expiration_date',
      width: '40%',
      render: (value, record) => value ? parseAndFormatDate(value) : '',
      sorter: (a, b) => (a.api_token_expiration_date || '').localeCompare(b.api_token_expiration_date || ''),
    },
    {
      title: 'API Allowed',
      dataIndex: 'api_token_locked',
      width: '40%',
      render: (value, record) => {
        if(value) {
          return <Tag color="volcano">LOCKED</Tag>
        } else if (parseAndFormatDate(Date.now(), true) >= record.api_token_expiration_date) {
          return <Tag color="orange">EXPIRED</Tag>
        } else {
          return <Tag color="green">ALLOWED</Tag>
        }
      },
      sorter: (a, b) => (a.api_token_locked  || 'false').toString().localeCompare(b.api_token_locked  || 'false'.toString())
    },
    {
      title: '',
      dataIndex: 'id',
      width: '4%',
      render: (id, record) => (
        <Popover content={(
          <>
            <Popconfirm
              placement="leftBottom"
              title={`Do you want to invalidate the token of "${record.email}"?`}
              onConfirm={() => invalidate_user_token(record.user_id)}
            >
              <Button title='Invalidate token' loading={loading}><StopOutlined/></Button>
            </Popconfirm>
            <Popconfirm
              placement="leftBottom"
              title={`Do you want to lock the api usage of "${record.email}"?`}
              onConfirm={() => set_user_api_lock(record.user_id, true)}
            >
              <Button title='Lock API usage' loading={loading} hidden={record.api_token_locked}><LockOutlined/></Button>
            </Popconfirm>
            <Popconfirm
              placement="leftBottom"
              title={`Do you want to unlock the api usage of "${record.email}"?`}
              onConfirm={() => set_user_api_lock(record.user_id, false)}
            >
              <Button title='Unlock API usage' loading={loading} hidden={!record.api_token_locked}><UnlockOutlined/></Button>
            </Popconfirm>
          </>
        )} title=''>
          <MoreOutlined style={{ cursor: 'pointer' }}/>
        </Popover>
      ),
    }
  ];

  return (
    <>
      <br/>
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        rowKey={record => record.email}
        size='middle'
        tableLayout='fixed'
        pagination={{ ...pagination}}
      />
    </>
  );
}
