import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';
import { Grid, Tab, Header } from 'semantic-ui-react';
import { invert } from 'lodash';

import { fetchTenants } from '../../redux/actions/configsActions';
import history from '../../history';
import TenantView from './TenantView';
import TenantsTable from './TenantsTable';
import TenantEnrichments from '../Enrichments/TenantEnrichments';

export default function TenantsPage({ match, auth }) {
  const { tenantName, 0: category } = match.params;
  const collapsing = tenantName === undefined;
  const subCategory = category ? category.replace(/\/\s*$/, '') : '';
  const panesUrls = {
    0: `/tenants/${tenantName}/`,
    1: `/tenants/${tenantName}/enrichments`,
  };
  const [activeIndex, setActiveIndex] = useState((invert(panesUrls))[`/tenants/${tenantName}/${subCategory.split('/')[0]}`]);
  const dispatch = useDispatch();

  const panes = [
    {
      menuItem: 'Info',
      render: () => <Tab.Pane>
        <Route exact path='/tenants/:tenantName' render={props => <TenantView auth={auth} {...props} />} auth={auth}/>
      </Tab.Pane>,
    },
    {
      menuItem: 'Enrichments',
      render: () => <Tab.Pane attached={false}>
        <Route exact path='/tenants/:tenantName/enrichments/' render={props => <TenantEnrichments auth={auth} {...props} />} auth={auth}/>
      </Tab.Pane>,
    },
  ]

  function handleTabChange(e, { activeIndex }) {
    history.push({ pathname: `${panesUrls[activeIndex]}` });
    setActiveIndex(activeIndex);
  }

  useEffect(() => {
    document.title = 'Tenants';
    if (collapsing) dispatch(fetchTenants());
  });

  return (<Grid celled>
    <Grid.Column
      width={collapsing ? null : 3}
      style={{ overflowY: 'scroll', maxHeight: '90vh' }}
    >
      <TenantsTable collapsing={collapsing} subCategory={subCategory}/>
    </Grid.Column>
    { !collapsing && (
      <Grid.Column width={13} style={{ overflowY: 'scroll', maxHeight: '90vh' }}>
        <Header>{tenantName}</Header>
        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          onTabChange={handleTabChange}
          activeIndex={activeIndex}/>
      </Grid.Column>
    )}
  </Grid>)
}
