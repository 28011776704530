import { NotificationManager } from 'react-notifications';

import apiClient from '../../api/apiClient';
import apiEnrichments from '../../api/apiEnrichments';

export const FETCH_ENRICHMENTS_METADATA_SUCCESS = 'FETCH_ENRICHMENTS_METADATA_SUCCESS';
export const FETCH_ENRICHMENTS_DATA_SUCCESS = 'FETCH_ENRICHMENTS_DATA_SUCCESS';
export const FETCH_ENRICHMENT_TENANTS_SETTINGS_SUCCESS = 'FETCH_ENRICHMENT_TENANTS_SETTINGS_SUCCESS';
export const FETCH_ENRICHMENTS_METADATA_FAILURE = 'FETCH_ENRICHMENTS_METADATA_FAILURE';
export const FETCH_ENRICHMENTS_DATA_FAILURE = 'FETCH_ENRICHMENTS_DATA_FAILURE';
export const FETCH_ENRICHMENTS_METADATA_REQUEST = 'FETCH_ENRICHMENTS_METADATA_REQUEST';
export const FETCH_ENRICHMENTS_DATA_REQUEST = 'FETCH_ENRICHMENTS_DATA_REQUEST';
export const FETCH_ENRICHMENTS_METADATAS_REQUEST = 'FETCH_ENRICHMENTS_METADATAS_REQUEST';
export const START_REQUEST = 'START_REQUEST';
export const FINISH_REQUEST = 'FINISH_REQUEST';

export const startRequest = () => ({ type: START_REQUEST });

export const finishRequest = () => ({ type: FINISH_REQUEST });

export const fetchEnrichmentsMetadataSuccess = metaData => ({ type: FETCH_ENRICHMENTS_METADATA_SUCCESS, metaData });

export const fetchEnrichmentsMetadataFailure = () => ({ type: FETCH_ENRICHMENTS_METADATA_FAILURE });

export const fetchEnrichmentsDataSuccess = response => ({ type: FETCH_ENRICHMENTS_DATA_SUCCESS, data: response.items, hasMoreData: response.hasMore });

export const fetchEnrichmentsDataFailure = () => ({ type: FETCH_ENRICHMENTS_DATA_FAILURE });

export const fetchEnrichmentsMetadataRequest = () => ({ type: FETCH_ENRICHMENTS_METADATA_REQUEST });

export const fetchEnrichmentsDataRequest = () => ({ type: FETCH_ENRICHMENTS_DATA_REQUEST });

export const fetchEnrichmentsMetadatasRequest = () => ({ type: FETCH_ENRICHMENTS_METADATAS_REQUEST });

export const fetchEnrichmentsMetadata = (metadataStage, keyTable) => (dispatch) => {
  dispatch(fetchEnrichmentsMetadataRequest());
  apiClient.get(`/enrichments_metadata/${metadataStage}/${keyTable}`).then((response) => {
    dispatch(fetchEnrichmentsMetadataSuccess(response.data));
  }).catch((error) => {
    NotificationManager.error(error.message);
    dispatch({ type: 'FAILURE', errorMessage: error.message });
    dispatch(fetchEnrichmentsMetadataFailure());
  });
};

export const fetchEnrichmentsData = (metadataStage, enrichmentName, pageSize = 10) => (dispatch) => {
  const params = {
    pageSize
  };

  dispatch(fetchEnrichmentsDataRequest());
  apiClient.get(`/enrichments/${metadataStage}/${enrichmentName}`, { params }).then((response) => {
    dispatch(fetchEnrichmentsDataSuccess(response.data));
  }).catch((error) => {
    NotificationManager.error(error.message);
    dispatch(fetchEnrichmentsDataFailure());
  });
};

export const removeEnrichmentsMetadata = (envTable, enrichmentId, removeData, removeTenansSettings, fetchEnrichmentsMetadatasAfter = false) => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiEnrichments.removeEnrichment(envTable, enrichmentId, removeData, removeTenansSettings);
    // if (fetchEnrichmentsMetadatasAfter) {
    //   dispatch(fetchEnrichmentsMetadatas(envTable));
    // }
    NotificationManager.success(data);
  } catch(error) {
    NotificationManager.error(error.message);
  } finally {
    dispatch(finishRequest());
  }
};

export const copyEnrichmentsMetadata = (metadataStage, keyTable, destinationStage, enrichmentNameTo) => (dispatch) => {
  apiClient.post(`/enrichments_metadata/${metadataStage}/${keyTable}`, { destinationStage, action: 'copy', enrichmentNameTo }).then((response) => {
    NotificationManager.success(response.data);
  }).catch((error) => {
    NotificationManager.error(error.message);
  });
};

export const deprecateEnrichment = (metadataStage, keyTable, restoreFlag) => (dispatch) => {
  apiClient.post(`/enrichments_metadata/${metadataStage}/${keyTable}`, { action: 'deprecate',  restoreFlag}).then((response) => {
    NotificationManager.success(response.data);
  }).catch((error) => {
    NotificationManager.error(error.message);
  });
};

export const fetchEnrichmentTenantsSettings = (enrichmentName) => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiClient.get(`/enrichments/${enrichmentName}/tenants_settings`);
    dispatch({ type: FETCH_ENRICHMENT_TENANTS_SETTINGS_SUCCESS, tenantsSettings: data.items });
  } catch(error) {
    NotificationManager.error(error.message);
  }
  dispatch(finishRequest());
};

export const updateEnrichmentTenantSettings = (enrichmentName, tenantId, tenantSettings) => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiClient.put(`/enrichments/${enrichmentName}/tenants_settings/${tenantId}`, tenantSettings);
    dispatch(fetchEnrichmentTenantsSettings(enrichmentName));
    NotificationManager.success(data);
  } catch(error) {
    NotificationManager.error(error.message);
  }
  dispatch(finishRequest());
};

export const createEnrichment = (enrichmentName, enrichment) => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiClient.post(`/enrichments_metadata/${enrichmentName}/${enrichment.name}`, { enrichment, action: 'create' });
    NotificationManager.success(data);
  } catch(error) {
    NotificationManager.error(error.message);
  }
  dispatch(finishRequest());
};

export const batchUpdateEnrichmentTenantSettings = (enrichmentName, settings) => async (dispatch) => {
  dispatch(startRequest());
  try {
    const { data } = await apiClient.patch(`/enrichments/${enrichmentName}/tenants_settings`, {settings});
    dispatch({ type: FETCH_ENRICHMENT_TENANTS_SETTINGS_SUCCESS, tenantsSettings: data.items });
  } catch(error) {
    NotificationManager.error(error.message);
  }
  dispatch(finishRequest());
};
