import { NotificationManager } from 'react-notifications';

import apiClient from '../../api/apiClient';

export const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS';
export const FETCH_TASK_SUCCESS = 'FETCH_TASK_SUCCESS';
export const FETCH_FAILURE = 'FETCH_FAILURE';
export const FETCH_REQUEST = 'FETCH_REQUEST';

export const fetchTasksSuccess = tasks => ({
  type: FETCH_TASKS_SUCCESS,
  tasks,
});

export const fetchFailure = () => ({
  type: FETCH_FAILURE,
});

export const fetchRequest = () => ({
  type: FETCH_REQUEST,
});

export const fetchTasks = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    const response = await apiClient.get('/tasks');
    dispatch(fetchTasksSuccess(response.data));
  } catch (error) {
    NotificationManager.error(error.message);
    dispatch(fetchFailure());
  }
};

export const fetchTask = taskId => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    const { data } = await apiClient.get(`/tasks/${taskId}`);
    dispatch({ type: FETCH_TASK_SUCCESS, task: data });
  } catch (error) {
    NotificationManager.error(error.message);
    dispatch(fetchFailure());
  }
};

export const terminateTask = taskId => async (dispatch) => {
  try {
    const response = await apiClient.delete(`/tasks/${taskId}`);
    dispatch(fetchTasks());
    NotificationManager.success(response.data);
  } catch (error) {
    NotificationManager.error(error.message);
    dispatch(fetchFailure());
  }
};
