import React from 'react';
import { Select, } from 'antd';

export default function ColumnsOntologies({ value, inputRef, selectableOntologies, onChange, onBlur }) {

  return (
    <Select
      ref={inputRef}
      placeholder='Select an ontology'
      optionFilterProp='children'
      showSearch
      allowClear
      autoClearSearchValue
      dropdownStyle={{ minWidth: 200}}
      mode='tags'
      onChange={(v) => onChange(v.length > 0 ? v[v.length - 1] : 'CATEGORY')}
      onBlur={onBlur}
      value={[value]}
    >
      {selectableOntologies.map(ontology => <Select.Option key={ontology} value={ontology}>{ontology}</Select.Option>)}
    </Select>
  );
}
