export default {
    apiDomain: 'https://api.backoffice.explorium.ai',
    s3DefaultFileUploadDir: 'enrichments/Static_Backup/',
    logoUrl: 'https://avatars3.githubusercontent.com/u/48208778',
    auth0Domain: 'explorium.auth0.com',
    auth0Audience: 'backoffice.api',
    auth0clientID: 'kSatgMGtvoonE1APEaPampIruISHTRyT',
    rolesPayloadKey: 'user_roles',
    primaryStage: 'production',
    defaultSampleProjectLists: ['project_list.json'],
    iconsCdnUrl: 'https://d6xed0kfxpt69.cloudfront.net'
}
