import { NotificationManager } from 'react-notifications';

import apiGlue from '../../api/apiGlue';

export const START_DATABASES_REQUEST = 'START_DATABASES_REQUEST';
export const FINISH_DATABASES_REQUEST = 'FINISH_DATABASES_REQUEST';
export const START_TABLES_REQUEST = 'START_TABLES_REQUEST';
export const FINISH_TABLES_REQUEST = 'FINISH_TABLES_REQUEST';
export const START_ITEMS_REQUEST = 'START_ITEMS_REQUEST';
export const FINISH_ITEMS_REQUEST = 'FINISH_ITEMS_REQUEST';
export const FETCH_DATABASES_SUCCESS = 'FETCH_DATABASES_SUCCESS';
export const FETCH_TABLES_SUCCESS = 'FETCH_TABLES_SUCCESS';
export const FETCH_ITEMS_SUCCESS = 'FETCH_ITEMS_SUCCESS';

export const fetchDatabases = () => async (dispatch) => {
  dispatch({ type: START_DATABASES_REQUEST });
  try {
    const { data } = await apiGlue.fetchDatabases();
    dispatch({ type: FETCH_DATABASES_SUCCESS, databases: data.items });
  } catch(error) {
    NotificationManager.error(error.message);
  }
  dispatch({ type: FINISH_DATABASES_REQUEST });
};

export const fetchTables = (database, params) => async (dispatch) => {
  dispatch({ type: START_TABLES_REQUEST });
  try {
    const { data } = await apiGlue.fetchTables(database, params);
    dispatch({ type: FETCH_TABLES_SUCCESS, tables: data.items });
  } catch(error) {
    NotificationManager.error(error.message);
  }
  dispatch({ type: FINISH_TABLES_REQUEST });
};

export const fetchItems = (database, table) => async (dispatch) => {
  dispatch({ type: START_ITEMS_REQUEST });
  try {
    const { data } = await apiGlue.fetchTableItems(database, table);
    dispatch({ type: FETCH_ITEMS_SUCCESS, items: data.items });
  } catch(error) {
    NotificationManager.error(error.message);
  }
  dispatch({ type: FINISH_ITEMS_REQUEST });
};

export const clearItems = () => async (dispatch) => {
  dispatch({ type: FETCH_ITEMS_SUCCESS, items: [] });
};
