import axios from 'axios';

import config from 'react-global-configuration';

export const guid = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
  }

  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};
export const CORRELATION_ID_HEADER_NAME = 'X-Correlation-ID';
export const guidWithNoDashes = () => guid().replace(/-/g, '');

axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const instance = axios.create({
  baseURL: `${config.get('apiDomain')}`,
});

instance.interceptors.request.use(
    (config) => {
      const newConfig = { ...config };
      newConfig.headers[CORRELATION_ID_HEADER_NAME] = guidWithNoDashes();

      console.log(`request: ${newConfig.url} | correlation: ${newConfig.headers[CORRELATION_ID_HEADER_NAME]}`);

      return newConfig;
    },
    (error) => Promise.reject(error)
);

instance.interceptors.response.use((response) => {
  if (response.data && response.data.error) {
    throw new Error(response.data.errorMessage);
  }
  return response;
}, (error) => {
  if (typeof error.response === 'undefined') {
    throw new Error(error.message);
  }

  if (error.response.status === 401) {
    window.location = `/login?redirect=${window.location.pathname}`;
  } else if (error.response.status === 403) {
    throw new Error(`Permission denied for [${localStorage.getItem('roles')}] user`);
  } else {
    throw new Error(`${error.response.data.errorMessage}`);
  }

  console.error(error);
  return error;
});

export default instance;
