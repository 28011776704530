import React, {useState} from 'react';
import { Tabs } from 'antd';

import CatalogItems from './CatalogItems';

const { TabPane } = Tabs;

export default function Catalog({ auth, history, match }) {
  const { params } = match;
  const { environment } = params;
  const [env, setEnv] = useState(environment);

  function handleTabClick(key) {
    history.push(`/catalog/${key}`);
    setEnv(key);
  }


  return (
    <>
      <Tabs defaultActiveKey={environment} onChange={handleTabClick}>
        <TabPane tab='Production' key='production'>
        </TabPane>
        <TabPane tab='Develop' key='develop'>
        </TabPane>
      </Tabs>
      <CatalogItems environment={env} auth={auth}/>
    </>
  );
};
