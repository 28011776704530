import React, { useEffect, useState } from 'react';
import { Select, Spin } from 'antd';
import { NotificationManager } from 'react-notifications';
import { last }  from 'lodash';

import apiUsers from '../../api/apiUsers';

const { Option } = Select;

export default function AuthorsEditor({ value, onChange, disabled, multiple= true }) {
  const [loading, setLoading] = useState(false);
  const [possibleAuthors, setPossibleAuthors] = useState([]);
  const [searchAuthorsTimeout, setSearchAuthorsTimeout] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  async function handleAuthorsSearch(text) {
    if (searchAuthorsTimeout) {
      clearTimeout(searchAuthorsTimeout);
    }

    setSearchAuthorsTimeout(setTimeout(() => {
      fetchData(text.length >= 3 ? text : '');
    }, 1300));
  }

  async function fetchData(value) {
    try {
      setLoading(true);
      const { data } = await apiUsers.getUsers({ query: value });
      setPossibleAuthors(data.items);
    } catch (error) {
      NotificationManager.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  function onAuthorChange(value){
    if (!multiple){
      value = last(value)
    }
    onChange(value)
  }

  return (
    <Select
      allowClear
      mode='multiple'
      value={value}
      disabled={disabled}
      placeholder='Enter author name or email'
      notFoundContent={loading ? <Spin size='small'/> : null}
      onSearch={handleAuthorsSearch}
      onChange={onAuthorChange}
      style={{ width: '100%' }}
    >
      {possibleAuthors.map(row => (<Option value={row.email} key={row.email}>{row.name} ({row.email})</Option>))}
    </Select>
  );
}
