import React from 'react';
import { Select, Avatar } from 'antd';
import { getIconFullUrl } from '../../../common/utils';

const { Option } = Select;

export default function IconSelector({ onSelect, onBlur, onChange, value, icons }) {

  return (
    <Select
      allowClear
      showSearch
      autoClearSearchValue
      dropdownStyle={{ minWidth: 250}}
      listHeight={450}
      onBlur={onBlur}
      onSelect={onSelect}
      onChange={onChange}
      size='small'
      value={value}
    >
      { icons.map(icon => <Option key={icon}><Avatar size='small' shape='square' src={getIconFullUrl(icon)}/>&nbsp;{icon}</Option>)}
  </Select>
  );
}
