import React from "react";
import { Button, TreeSelect } from "antd";

export default function FilterDropdown({
  placeholder,
  filters,
  selectedKeys,
  setSelectedKeys,
  handleSearch,
  clearFilters,
  confirm,
  loading,
  visible,
}) {
  const treeData = [
    ...filters.map((el) => {
      return { title: el.text, key: el.value };
    }),
    { title: "EMPTY", key: "__EMPTY__" },
  ];

  function handleChange(value) {
    setSelectedKeys(value);
  }

  function handleClearFilters() {
    setSelectedKeys([]);
    clearFilters();
  }

  return (
    <div style={{ padding: 8, width: 330 }}>
      <span>
        <TreeSelect
          showSearch
          treeCheckable
          loading={loading}
          placeholder={placeholder}
          style={{ width: "70%" }}
          value={selectedKeys}
          treeData={treeData}
          onChange={handleChange}
          showAction={["focus", "click"]}
          ref={(ref) => {
            if (!ref) return;
            setTimeout(() => {
              if (ref && visible) {
                ref.focus();
              }
            });
          }}
        />

        <Button
          size="small"
          type="link"
          disabled={selectedKeys.length === 0}
          onClick={handleClearFilters}
        >
          Reset
        </Button>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          size="small"
        >
          Ok
        </Button>
      </span>
    </div>
  );
}
