import React from 'react';
import { Input } from 'antd';
import styled from 'styled-components';

const StyledInput = styled(Input)`
  width: 90%;
  background-color: #f2f2f0;
  text-align: center;
  border: 1px;
`;

export default function CopyableInput({ value, emptyValue, className, onClick, loading }) {
  return (
    <StyledInput
      readOnly
      className={className}
      value={value ? value : emptyValue}
      style={{ cursor: value ? 'default' : 'pointer' }}
      disabled={loading}
      onClick={() => onClick(className)}
    />
  )
}
