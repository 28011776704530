import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { Table, Popover } from "antd";
import { NotificationManager } from "react-notifications";
import { SearchOutlined, MoreOutlined } from "@ant-design/icons";
import { saveAs } from "file-saver";
import history from "../../history";
import qs from "query-string";
import styled from "styled-components";
import config from "react-global-configuration";
import apiClient from "../../api/apiClient";

import { removeEmpty, quote } from "../../common/utils";
import apiEnrichments from "../../api/apiEnrichments";

import PrivateComponent from "../../common/PrivateComponent";
import CatalogItemActions from "./CatalogItemActions";
import ExportButton from "../../common/ExportButton";
import ListOfListsView from "../../shared/Components/Enrichments/ListOfListsView";
import LabelsView from "../../shared/Components/Enrichments/LabelsView";
import SearchFilterDropdown from "../../shared/Components/Table/SearchFilterDropdown";
import FilterDropdown from "../../shared/Components/Table/FilterDropdown";
import NameAndId from "../../shared/Components/Enrichments/Table/NameAndId";
import FiltersView from "../../shared/Components/FiltersView";
import Href from "../../shared/fields/Href";
import { exploriumColors } from "../../common/colors";
import LegacyEnrichmentWarning from "../Enrichments/LegacyEnrichmentWarning";
import { METADATA_READ } from "../../shared/data/permissions";
import { useQuery } from "react-query";
import moment from "moment";
import { flatten, get, isNil, uniq } from "lodash";
import { produce } from "immer";
import { CustomTableFilter } from "./metadata-new/custom-table-filter/CustomTableFilter";

const SourceName = styled.span`
  font-weight: 600;
  color: ${exploriumColors.exploriumBlack};
  font-size: 14px;
`;

export default function CatalogItems({ environment, auth = {} }) {
  const searchInput = useRef();
  const searchIcon = useRef();
  const [searchUrl, setSearchUrl] = useState(getDataSearchUrl());
  const [isExporting, setIsExporting] = useState(false);

  const filters = qs.parse(window.location.search);
  const { data: { items: allMetaDatas = [] } = {} } = useQuery({
    queryKey: [`/enrichments_metadata/${environment}__ALL`],
    queryFn: () =>
      apiClient.get(`/enrichments_metadata/${environment}`).then((response) => {
        return response.data;
      }),
    onError: (error) => {
      NotificationManager.error(error.message);
    },
  });

  const {
    data: { items: metaDatas = [], itemsCount = 0 } = {},
    isLoading: loading,
  } = useQuery({
    queryKey: [`/enrichments_metadata/${environment}`, filters],
    queryFn: () =>
      apiClient
        .get(`/enrichments_metadata/${environment}`, { params: filters })
        .then((response) => {
          return response.data;
        }),
    onError: (error) => {
      NotificationManager.error(error.message);
    },
  });

  const getTagValues = useCallback(
    (tagName) => {
      const result = uniq(
        allMetaDatas.reduce((acc, metadata) => {
          return [...acc, ...getTagRowValues(metadata, tagName)];
        }, [])
      );

      return result;
    },
    [allMetaDatas]
  );

  const possibleSources = useMemo(() => {
    const result = uniq(
      allMetaDatas.reduce((acc, metadata) => {
        return [...acc, get(metadata, ["properties", "source"])];
      }, [])
    );

    return result;
  }, [allMetaDatas]);

  const possibleRegionTags = useMemo(
    () => getTagValues("Regions"),
    [getTagValues]
  );
  const possibleMaturityTags = useMemo(
    () => getTagValues("Maturity"),
    [getTagValues]
  );
  const possibleHealthStatusTags = useMemo(
    () => getTagValues("Health Status"),
    [getTagValues]
  );
  const possibleCategoryTags = useMemo(
    () => getTagValues("Categories"),
    [getTagValues]
  );

  const possibleOntologies = useMemo(() => {
    const result = uniq(
      allMetaDatas.reduce((acc, metadata) => {
        return [
          ...acc,
          ...flatten(get(metadata, ["properties", "input_ontologies"]) ?? []),
        ];
      }, [])
    );

    return result;
  }, [allMetaDatas]);

  const allTags = useMemo(() => {
    const result = uniq(
      allMetaDatas.reduce((acc, metadata) => {
        return [...acc, ...metadata.tags.map((x) => Object.keys(x)[0])];
      }, [])
    );

    return result;
  }, [allMetaDatas]);

  function getDataSearchUrl() {
    return `${config.get("apiDomain")}/enrichments_search/${environment}`;
  }

  function getTagRowValues(record, tagName) {
    const values = record.tags.find((tag) => Object.keys(tag)[0] === tagName);
    return values?.[tagName] ?? [];
  }

  // type >> dateString | undefined
  function localSorter(valueGetter, type) {
    return function (rowA, rowB) {
      const valueA = valueGetter(rowA);
      const valueB = valueGetter(rowB);

      if (valueA === valueB) return 0;
      if (isNil(valueA)) return 1;
      if (isNil(valueB)) return -1;

      if (type !== "dateString") {
        return valueA > valueB ? 1 : -1;
      } else {
        return new Date(valueA) > new Date(valueB) ? 1 : -1;
      }
    };
  }

  const columns = [
    {
      title: "Name",
      dataIndex: ["properties", "name"],
      width: "400px",
      sorter: localSorter((row) => get(row, ["properties", "display_name"])),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <SearchFilterDropdown
          inputRef={searchInput}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          handleSearch={handleSearch}
          handleReset={handleReset}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          ref={searchIcon}
          style={{ color: filtered ? "#00c2b2" : "#454444", fontSize: 20 }}
        />
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) selectSearchFilter();
      },
      render: (name, record) => {
        return (
          <div style={{ display: "flex" }}>
            {record.enrichment_type === "legacy" && (
              <LegacyEnrichmentWarning asTooltip />
            )}
            <NameAndId
              id={name}
              name={record.properties.display_name}
              description={record.properties.description}
              href={`/catalog/${environment}/${quote(name)}`}
              signalsCount={record.properties.signalsCount}
            />
          </div>
        );
      },
      filteredValue: filters.__search_text__ || null,
    },
    {
      key: "Maturity",
      title: "Maturity",
      width: "100px",
      filterDropdown: ({
        selectedKeys,
        setSelectedKeys,
        confirm,
        clearFilters,
        visible,
      }) => (
        <FilterDropdown
          visible={visible}
          placeholder="Search"
          filters={possibleMaturityTags.map((tag) => {
            return { text: tag, value: tag };
          })}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          handleSearch={(_, confirm) => confirm()}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      render: (record) => {
        return <LabelsView value={getTagRowValues(record, "Maturity")} />;
      },
      filteredValue: filters.Maturity || null,
    },
    {
      key: "Health Status",
      title: "Health Status",
      width: "100px",
      filterDropdown: ({
        selectedKeys,
        setSelectedKeys,
        confirm,
        clearFilters,
        visible,
      }) => (
        <FilterDropdown
          visible={visible}
          placeholder="Search"
          filters={possibleHealthStatusTags.map((tag) => {
            return { text: tag, value: tag };
          })}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          handleSearch={(_, confirm) => confirm()}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      render: (record) => {
        return <LabelsView value={getTagRowValues(record, "Health Status")} />;
      },
      filteredValue: filters["Health Status"] || null,
    },
    {
      title: "Last Updated",
      width: "100px",
      dataIndex: ["properties", "last_updated"],
      sorter: localSorter(
        (row) => get(row, ["properties", "last_updated"]),
        "dateString"
      ),
      render: (item) => item && `${moment(item).format("YYYY/MM/DD HH:mm")}`,
    },
    {
      title: "Source",
      key: "source",
      width: "100px",
      sorter: localSorter((row) => get(row, ["properties", "source"])),
      filterDropdown: ({
        selectedKeys,
        setSelectedKeys,
        confirm,
        clearFilters,
        visible,
      }) => (
        <FilterDropdown
          visible={visible}
          placeholder="Search"
          filters={possibleSources.map((tag) => {
            return { text: tag ? tag : "EMPTY", value: tag || "EMPTY" };
          })}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          handleSearch={(_, confirm) => confirm()}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      render: (record) => {
        const { source, source_url } = record.properties;
        return (
          <>
            {source && (
              <SourceName>
                {source}
                <br />
              </SourceName>
            )}
            {source_url && source_url.includes("http") ? (
              <Href href={source_url}>{source_url}</Href>
            ) : (
              source_url
            )}
          </>
        );
      },
      filteredValue: filters.source || null,
    },
    {
      title: "Region",
      key: "Regions",
      width: "8%",
      filterDropdown: ({
        selectedKeys,
        setSelectedKeys,
        confirm,
        clearFilters,
        visible,
      }) => (
        <FilterDropdown
          visible={visible}
          placeholder="Search"
          filters={possibleRegionTags.map((tag) => {
            return { text: tag, value: tag };
          })}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          handleSearch={(_, confirm) => confirm()}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      render: (record) => {
        return <LabelsView value={getTagRowValues(record, "Regions")} />;
      },
      filteredValue: filters.Regions || null,
    },
    {
      title: "Supported ontologies",
      dataIndex: ["properties", "input_ontologies"],
      key: "input_ontologies",
      width: "200px",
      filterDropdown: ({
        selectedKeys,
        setSelectedKeys,
        confirm,
        clearFilters,
        visible,
      }) => (
        <FilterDropdown
          visible={visible}
          placeholder="Search"
          filters={[...possibleOntologies].map((record) => {
            return { text: record, value: record };
          })}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          handleSearch={(_, confirm) => confirm()}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      render: (input_ontologies) => (
        <ListOfListsView value={input_ontologies} />
      ),
      filteredValue: filters.input_ontologies || null,
    },
    {
      title: "Categories",
      width: "140px",
      key: "Categories",
      filterDropdown: ({
        selectedKeys,
        setSelectedKeys,
        confirm,
        clearFilters,
        visible,
      }) => (
        <FilterDropdown
          visible={visible}
          placeholder="Search"
          filters={possibleCategoryTags.map((record) => {
            return { text: record, value: record };
          })}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          handleSearch={(_, confirm) => confirm()}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      sorter: localSorter((row) => getTagRowValues(row, "Categories")[0]),
      render: (record) => {
        return <LabelsView value={getTagRowValues(record, "Categories")} />;
      },
      filteredValue: filters.Categories
        ? Array.isArray(filters.Categories)
          ? filters.Categories
          : [filters.Categories]
        : null,
    },
    {
      title: "",
      dataIndex: "name",
      width: "3%",
      render: (name, record) => (
        <Popover
          trigger="hover"
          content={
            <>
              <CatalogItemActions
                metadataStage={environment}
                enrichments_metadata={record}
                key={name}
                onRemove={onReload}
              />
              <br />
            </>
          }
        >
          <MoreOutlined />
        </Popover>
      ),
    },
  ];

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://widgets.explorium.ai/catalog-search/index.js`;
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = "https://widgets.explorium.ai/catalog-search/index.css";
    script.async = true;
    link.async = true;
    document.body.appendChild(script);
    document.body.appendChild(link);
    return () => {
      document.body.removeChild(script);
      document.body.removeChild(link);
    };
  }, []);

  useEffect(() => {
    document.title = `Catalog - ${environment} enrichments`;
    setSearchUrl(getDataSearchUrl());
  }, [environment]);

  useEffect(() => {
    if (window.renderSearchWidget)
      window.renderSearchWidget("explorium-search-widget");
  }, [searchUrl]);

  async function handleSearch(searchText, confirm) {
    const newFilters = { ...filters, __search_text__: searchText };
    updateFilters(newFilters);
    confirm();
  }

  function handleReset(clearFilters) {
    clearFilters();
  }

  function selectSearchFilter() {
    setTimeout(() => searchInput.current.select());
  }

  document.addEventListener("keydown", (e) => {
    let charCode = String.fromCharCode(e.which).toLowerCase();
    if ((e.metaKey || e.ctrlKey) && charCode === "f") onControlFPress(e);
  });

  function onControlFPress(e) {
    if (
      (!searchInput.current || !searchInput.current.state.focused) &&
      searchIcon.current
    ) {
      setTimeout(() => searchIcon.current.click());
      setTimeout(() => searchInput.current.focus(), 100);
      e.returnValue = false;
    }
  }

  function handleNewFilters(newFilters) {
    const filtersToApply = {
      ...filters,
      ...newFilters,
      __search_text__: newFilters.name,
    };

    removeEmpty(filtersToApply);

    handleFiltersChange(filtersToApply);
  }

  function handleTableChange(pagination, newFilters, sorter) {
    handleNewFilters(newFilters);
  }

  async function updateFilters(filters) {
    history.push({ search: qs.stringify(filters) });
  }

  async function onExport() {
    setIsExporting(true);
    try {
      const response = await apiEnrichments.exportEnrichments(
        environment,
        filters
      );
      saveAs(response.data, "enrichments.csv");
    } catch (error) {
      NotificationManager.error(error.message);
    } finally {
      setIsExporting(false);
    }
  }

  async function handleFiltersChange(newFilters) {
    updateFilters(
      produce(newFilters, (draft) => {
        const name = draft["properties.name"];
        delete draft["properties.name"];
        if (name) {
          draft["__search_text__"] = name;
        }
      })
    );
  }

  async function onReload() {
    // await fetchData(filters);
  }

  return (
    <>
      <div className="wrap-component-row">
        <FiltersView
          value={filters}
          onChange={handleFiltersChange}
          ignoredKeys={[]}
        />
        <CustomTableFilter
          allFieldNames={allTags}
          getFieldValues={(tagName) =>
            getTagValues(tagName).map((value) => ({ value, label: value }))
          }
          onApply={(fieldName, fieldValues) => {
            handleNewFilters({
              [fieldName]: fieldValues,
            });
          }}
        />
        <p style={{ fontSize: "18px", margin: "0 0 0 auto" }}>
          Total: {metaDatas.length}
        </p>
        {/* 
          YOTAM >> Uncomment this if you fix the search logic.
        <div
          id="explorium-search-widget"
          style={{ width: "30%", marginLeft: "auto" }}
          data-search-url={searchUrl}
          data-placeholder="Search for any bundle, description or signal"
          data-authorization={`Bearer ${localStorage.getItem("access_token")}`}
          data-result-url-func="function (searchObject) {
              if(searchObject.type=='signal') return `${searchObject.env}/${searchObject.parentBundleName}`;
              else return `${searchObject.env}/${searchObject.name}`;
              }"
          data-search-delay="500"
        /> */}
        <div>
          <ExportButton
            title="Export Enrichments"
            confirmTitle="Export enrichments?"
            onClick={onExport}
            loading={isExporting}
          />
          <PrivateComponent
            requiredPermissions={METADATA_READ}
            currentPermissions={auth.permissions}
          >
            {/* <CategoryHierarchyButton metadataStage={environment}/>
            <RegionTagsButton metadataStage={environment}/>
            <UseCasesButton metadataStage={environment}/> */}
          </PrivateComponent>
        </div>
      </div>
      <Table
        loading={loading}
        dataSource={metaDatas}
        columns={columns}
        rowKey={(record) => record.name}
        size="middle"
        tableLayout="fixed"
        scroll={{ x: 1300 }}
        onChange={handleTableChange}
        pagination={false}
      />
    </>
  );
}
