import React, { useState } from 'react';
import { Icon, Button, Transition, Confirm } from 'semantic-ui-react';

export default function SaveButton(props) {
  const { disabled, loading, hideWhenDisabled, confirmHeader } = props;
  const [isConfirm, setIsConfirm] = useState(false);

  function onConfirm() {
    props.onClick();
    setIsConfirm(false);
  }

  function onCancel() {
    setIsConfirm(false);
  }

  return (
    <>
      <Transition visible={!(hideWhenDisabled && disabled)} animation='scale' duration={500}>
        <Button basic disabled={disabled} floated='right' onClick={() => setIsConfirm(true)} loading={loading} size='mini' color='green'>
          <Icon name='save'/> Save
        </Button>
      </Transition>
      <Confirm open={isConfirm} co onCancel={onCancel} header={confirmHeader} onConfirm={onConfirm}/>
    </>
  );
}
