import React from 'react';
import {WarningTwoTone} from '@ant-design/icons';
import {Tooltip} from "antd";

export default function LegacyEnrichmentWarning(props) {
    const {asTooltip = false} = props;
    const text = "This is a premium enrichment. Only permitted users can edit its permissions";
    const warning = <WarningTwoTone style={{fontSize: '20px', paddingRight: '5px'}} twoToneColor="#eed202"/>;
    return asTooltip ? (<Tooltip title={text}>{warning}</Tooltip>) : (
        (<div>
            {warning}
            <span style={{fontSize: '16px', color: "#eed202"}}>{text}</span>
        </div>)
    )
}
