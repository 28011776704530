import { NotificationManager } from 'react-notifications';

export const START_REQUEST = 'START_REQUEST';
export const FINISH_REQUEST = 'FINISH_REQUEST';
export const FAILURE = 'FAILURE';

export const fetchFailure = errorMessage => {
  NotificationManager.error(errorMessage);
  return { type: FAILURE, errorMessage };
};

export const startRequest = () => ({ type: START_REQUEST });

