import {
  START_REQUEST,
  FINISH_REQUEST,
  FAILURE,
  FETCH_PROJECT_SUCCESS,
  FETCH_TENANT_SUCCESS,
  FETCH_TENANTS_SUCCESS,
  FETCH_TENANT_PROJECTS_SUCCESS,
} from '../actions/configsActions';

export default (state = { isLoading: false, project: {}, tenant: {}, tenantConfigs: [], hasMoreTenantConfigs: true, projectConfigs: [], hasMoreProjectConfigs: true }, action) => {
  switch (action.type) {
    case START_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FINISH_REQUEST: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case FETCH_PROJECT_SUCCESS: {
      return {
        ...state,
        project: action.project,
        isLoading: false,
      };
    }
    case FETCH_TENANT_SUCCESS: {
      return {
        ...state,
        tenant: action.tenant,
        isLoading: false,
      };
    }
    case FETCH_TENANTS_SUCCESS: {
      return {
        ...state,
        hasMoreTenantConfigs: action.hasMoreTenantConfigs,
        tenantConfigs: action.tenantConfigs,
        isLoading: false,
      };
    }
    case FETCH_TENANT_PROJECTS_SUCCESS: {
      return {
        ...state,
        hasMoreProjectConfigs: action.hasMoreProjectConfigs,
        projectConfigs: action.projectConfigs,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
