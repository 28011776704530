import { get } from 'lodash';
import config from 'react-global-configuration';
import packageJson from '../../package.json';

const hierarchyDelimiter = ' -> ';
const iconFullUrl = `${config.get('iconsCdnUrl')}/signals-icons`;

export function keyValueArrayToDropdownOptions(data, valueKeyName, displayKeyName, sort = false) {
  let options = data.map((r) => { return { value: r[valueKeyName], text: r[displayKeyName], key: r[valueKeyName] }; });
  if (sort) {
    options = options.sort((a, b) => (a.text > b.text) ? 1 : -1);
  }
  return options;
}

export function arrayToDropdownOptions(data, sort = false) {
  let options = data.map((r) => { return { value: r, text: r, key: r }; });
  if (sort) {
    options = options.sort((a, b) => (a.value > b.value) ? 1 : -1);
  }
  return options;
}

export function filterData(data, fields, filterText) {
  if (filterText === '') {
    return data;
  } else {
    filterText = filterText.toLowerCase();

    return data.filter((row) => {
      return fields.map((field) => {
        let value = get(row, field, '');
        if (value == null) value = '';

        return value.toLowerCase().includes(filterText);
      }).some((r) => { return r; });
    });
  }
}

export function arrayToOptions(array) {
  return array.map((el) => { return { value: el, text: el, key: el }; });
}

export function isNewVersionAvailable(metadata) {
  const latestVersion = parseInt(metadata.version.split(/\./g).join(''));
  const currentVersion = parseInt(packageJson.version.split(/\./g).join(''));

  if (latestVersion > currentVersion) {
    console.log('New version is available', latestVersion);
    return true;
  };

  return false;
}

export function reloadBrowserCache() {
  console.log('Clearing cache and hard reloading...');

  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then(function(names) {
      for (let name of names) caches.delete(name);
    });
  }

  // delete browser cache and hard reload
  window.location.reload(true);
}

export function convertCategoriesHierarchyData(data, parentKey = null) {
  if (!data) return [];
  return data.map(element => {
    const { id, name, sub_categories } = element;
    const key = parentKey ? [parentKey, name].join(hierarchyDelimiter) : id;

    return { title: name, value: key, key,
      children: convertCategoriesHierarchyData(sub_categories, key)
    };
  });
}

export function convertCategoriesHierarchyDataToList(data, parentKey = null) {
  if (!data) return [];
  return data.flatMap(element => {
    const { name, sub_categories } = element;
    const key = parentKey ? [parentKey, name].join(hierarchyDelimiter) : name;

    if (sub_categories && sub_categories.length > 0) {
      return [...convertCategoriesHierarchyDataToList(sub_categories, key)];
    } else {
      return key;
    }
  });
}

export function convertToCategoriesHierarchyData(categories, categoriesHierarchyData) {
  if (!categories) return[];
  return removeDataWithoutKey(categoriesHierarchyData, categories);
}

function removeDataWithoutKey(data, keys, parentKeys=[]) {
  const result = [];

  if (!data) return;

  data.forEach(element => {
    const { name, sub_categories } = element;

    const nameInKeys = keys.includes([...parentKeys, name].join(hierarchyDelimiter));

    if (nameInKeys) {
      result.push({...element, sub_categories: sub_categories ? [] : sub_categories });
    }

    if (sub_categories) {
      const processedSubCategories = removeDataWithoutKey(sub_categories, keys, [...parentKeys, name]);

      if (processedSubCategories && processedSubCategories.length > 0) {
        result.push({ ...element, sub_categories: processedSubCategories });
      }
    } else {
      element.sub_categories = [];
    }
  });

  return result;
}

const ontologiesDefaultIconNames = {
  'ADDRESS': 'geo',
  'CITY': 'geo',
  'COUNTRY': 'geo',
  'COUNTY': 'geo',
  'HOUSE NUMBER': 'geo',
  'LATITUDE': 'geo',
  'LONGITUDE': 'geo',
  'NEIGHBORHOOD': 'geo',
  'US State': 'geo',
  'POSTAL CODE': 'geo',
  'ZIP CODE': 'geo',
  'ZIP CODE PL': 'geo',
  'ZIP CODE AUS': 'geo',
  'ZIP CODE US': 'geo',
  'ZIP CODE ISRAEL': 'geo',
  'H3': 'geo',
  'SUBPREMISE': 'geo',
  'geo location': 'geo',
  'STREET': 'geo',

  'BOOLEAN': 'coding',
  'JSON': 'coding',

  'SEARCHABLE': 'searchable',

  'DISPLAY': 'display',

  'CONNECTABLE_ID': 'ID',
  'LA_ITEM_ID': 'ID',
  'UNIQUE_ID': 'ID',

  'NAICS Code': 'code',
  'SIC Code': 'code',
  'INSEE CODE': 'code',

  'COMPANY': 'company',
  'COMPANY CODE': 'company',
  'INDUSTRY': 'company',

  'DOMAIN': 'web',
  'IP Address': 'web',
  'URL': 'web',

  'EMAIL': 'email',
  'HASHED_EMAIL:SHA256': 'email',
  'HASHED_EMAIL:SHA1': 'email',
  'HASHED_EMAIL:MD5': 'email',

  'PHONE NUMBER': 'phone number',

  'First Name': 'name',
  'Last Name': 'name',
  'Full Name': 'name',

  'DATETIME': 'time_date',
  'First_Day_Of_Month': 'time_date',

  'PRICE': 'price',

  'PRODUCT': 'product',

  'CATEGORY': 'category',

  'NUMERIC': 'numeric',
  'NUMERIC_CONNECTABLE_ID': 'numeric',

  'TEXT': 'text',
};

export function getOntologyDefaultIcon(ontology) {
  return `${ontologiesDefaultIconNames[ontology] || ontology.toLowerCase()}.svg`;
}

export function getIconFullUrl(icon) {
  return `${iconFullUrl}/${icon}`;
}

export function quote(value) {
  return encodeURIComponent(encodeURIComponent(encodeURIComponent(value)));
}

export function unquote(value) {
  return decodeURIComponent(decodeURIComponent(decodeURIComponent(value)));
}

export function capitalize(s) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function getCurrentUser() {
  const email = getCurrentUserEmail();

  if (email) {
    const name = email.split('@')[0];
    const words = name.split('.');
    return words.map(word => capitalize(word)).join(' ');  
  }

  return null;
}

export function getCurrentUserEmail() {
  return localStorage.getItem('email');
}

export function removeEmpty(obj) {
  for (var propName in obj) { 
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
}

export function isStaticEnrichment(executorName) {
  return executorName === 'static_enrichment_service';
}

export function isAsConfigEnrichment(executorName) {
  return executorName === 'eac_sql_executor';
}

export function sortArray(array) {
  return array.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
}

export function getTreeChildrens(data) {
  const results = [];

  data.forEach((el, i) => {
    results.push(el.value);

    if (el.children && el.children.length > 0) {
      results.push( ...getTreeChildrens(el.children));
    }
  });

  return results;
}

export function intersects(array1, array2) {
  return array1.filter(v => array2.includes(v)).length > 0;
}

export function asArray(value) {
  if (value) {
    return Array.isArray(value) ? value : [value];
  } else {
    return value;
  }
}

export function parseAndFormatDate(dateInput, useISOFormat=false) {
  let date = new Date(dateInput);
  return useISOFormat ? date.toISOString() : date.toString();
}
