import React from 'react';
import { Menu, Icon } from 'semantic-ui-react';
import { Popconfirm } from 'antd';

export default function ExportButton(props) {
  const { loading, onClick, confirmTitle, title, children } = props;

  return (
    <Popconfirm
      placement='bottom'
      title={confirmTitle || 'Are you sure to export?'}
      onConfirm={onClick}
      okText='Yes'
      cancelText='No'
      disabled={loading}
    >
      { children ? children : (
        <Menu size='mini' floated='right'>
          <Menu.Item as='a' icon title={title} disabled={loading}>
            <Icon name={loading ? 'spinner' : 'download'} loading={loading}/>
          </Menu.Item>
        </Menu>
      )}
    </Popconfirm>
  );
}
