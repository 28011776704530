import React from 'react';
import { Route, Router, Redirect, Switch } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { Provider as ReduxProvider } from 'react-redux';

import configureStore from './redux/store';
import Callback from './auth/Callback';
import Auth from './auth/Auth';
import history from './history';
import Menu from './Components/Menu/Menu';
import Breadcrumbs from './Components/Menu/Breadcrumbs';
import Dashboard from './Components/Dashboard/Dashboard';
import Catalog from './Components/Catalog/Catalog';
import CatalogItem from './Components/Catalog/CatalogItem';
import EnrichmentTenantsSettings from './Components/Catalog/EnrichmentTenantsSettings/EnrichmentTenantsSettings';
import Profile from './Components/Profile/Profile';
import Login from './auth/Login';
import Logout from './auth/Logout';
import TenantsPage from './Components/Tenants/TenantsPage';
import Task from './Components/Dashboard/Task';
import Secrets from './Components/Secrets/Secrets';
import Ontologies from './Components/Ontologies/Ontologies';
import ChangeRequests from './Components/Ontologies/ChangeRequests';
import ApiDocs from './Components/ApiDocs/ApiDocs';
import EMStatusDashboard from './Components/StatusDashboard/StatusDashboard';
import ApiTokens from './Components/ApiTokens/ApiTokens';
import FeatureFlags from './Components/FeatureFlags/FeatureFlags';
import FeatureFlagTenants from './Components/FeatureFlags/FeatureFlagTenants';


const auth = new Auth();

const handleAuthentication = ({ location }) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication();
  }
}

export default function App() {
  return (
    <ReduxProvider store={configureStore()}>
      <Router history={history}>
        <div>
          <Route render={(props) => <Menu auth={auth} {...props} />}/>
            <Container>
                {auth.isAuthenticated() ? (<>
                  <Route path='*' component={Breadcrumbs}/>
                  <Route exact path='/'><Redirect to='/catalog/production'/></Route>
                  <Route exact path='/dashboard' component={Dashboard}/>
                  <Route exact path='/dashboard/:type' component={Dashboard}/>
                  <Route exact path='/dashboard/tasks/:taskId' component={Task}/>
                  <Route exact path='/catalog'><Redirect to='/catalog/production'/></Route>
                  <Route exact path='/catalog/:environment' render={(props) => <Catalog {...props} auth={auth}/>}/>
                  <Route exact path='/catalog/:metadataStage/:table' render={(props) => <CatalogItem {...props} auth={auth}/>}/>
                  <Route exact path='/catalog/:metadataStage/:table/tenants' render={(props) => <EnrichmentTenantsSettings {...props} auth={auth}/>}/>
                  <Route exact path='/feature-flags' component={FeatureFlags}/>
                  <Route exact path='/feature-flags/:featureFlag' component={FeatureFlagTenants}/>
                  <Route exact path='/ontologies' component={Ontologies}/>
                  <Route exact path='/ontologies/change-requests' render={(props) => <ChangeRequests auth={auth} {...props} />}/>
                  <Route exact path='/profile' render={(props) => <Profile auth={auth} {...props}/>}/>
                  <Route exact path='/secrets' render={(props) => <Secrets auth={auth} {...props}/>}/>
                  <Route exact path='/api-docs' component={ApiDocs}/>
                  <Route exact path='/api-tokens' render={(props) => <ApiTokens {...props}/>}/>
                  <Route exact path='/em-status' component={EMStatusDashboard}/>
                  <Switch>
                    <Route exact path='/tenants' render={(props) => <TenantsPage auth={auth} {...props}/>}/>
                    <Route exact path='/tenants/:tenantName' render={(props) => <TenantsPage auth={auth} {...props}/>}/>
                    <Route exact path='/tenants/:tenantName/*' render={(props) => <TenantsPage auth={auth} {...props}/>}/>
                  </Switch>
                  <Route exact path='/logout' render={(props) => <Logout auth={auth} {...props} />}/>
              </>) : (
                 <div>
                     <h3>Please Login</h3>
                 </div>
                )}
              <Route exact path='/login' render={(props) => <Login auth={auth} {...props} />}/>
              <Route path='/callback' render={(props) => {
                handleAuthentication(props);
                return <Callback {...props}/>
              }}/>
          </Container>
        </div>
      </Router>
    </ReduxProvider>
  );
}
