import React, { useEffect, useState } from 'react';
import { Card, Avatar } from 'antd';
import { NotificationManager } from 'react-notifications';

import apiProfile from '../../api/apiProfile';
import Token from './Token';
import { parseAndFormatDate } from '../../common/utils';

const { Meta } = Card;

export default function Profile({ auth }) {
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState({ ...auth.profile });
  const { picture, user, email, token, api_token_expiration_date, api_token_locked, roles = []} = profile;

  useEffect(() => {
    document.title = `${user}'s profile`;
    loadApiToken(false);
    // eslint-disable-next-line
  }, []);

  async function loadApiToken(force_new_token) {
    try {
      setLoading(true);
      const { data } = await apiProfile.getApiToken(force_new_token);
      setProfile({ ...profile, ...data });
    } catch (error) {
      NotificationManager.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <br/>
      <Card style={{ marginTop: 16 }}>
        <Meta
          avatar={<Avatar src={picture}/>}
          title={user}
          description={`${email} [${roles.split('.')}]`}
        />
        <br/><br/>
        <h2>API token</h2>
        <Token token={token} loading={loading} load_api_token={loadApiToken}
               api_token_expiration_date={(api_token_expiration_date ? parseAndFormatDate(api_token_expiration_date, true) : '')}
               api_token_locked={api_token_locked}/>
      </Card>
    </>
  )
}
