import React, { useState } from 'react';

import { Modal, Alert, Typography, Input } from 'antd';

const { Text } = Typography;

export default function DangerousConfirm({ title, onConfirm, onCancel, okText, cancelText, typeText, content, children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);

  function handleClick() {
    setIsOpen(!isOpen);
  }

  function handleType({ target }) {
    setIsAllowed(target.value === typeText);
  }

  function handleConfirm() {
    if (onConfirm) onConfirm();
    onReset();
  }

  function handleCancel() {
    if (onCancel) onCancel();
    onReset();
  }

  function onReset() {
    setIsOpen(false);
    setIsAllowed(false);
  }

  return (
    <>
      { React.cloneElement(children, { onClick: handleClick } ) }
      <Modal
        title={title}
        visible={isOpen}
        okButtonProps={{ disabled: !isAllowed, danger: true}}
        onOk={handleConfirm}
        okText={okText}
        onCancel={handleCancel}
        cancelText={cancelText}
      >
          {content && (
            <>
              <Alert message='Evil things will happen if you don’t read this!' type='warning'/>
              <br/>
              {content}
              <br/>
            </>
          )} 
          {typeText && (
            <>
              <br/>
              Please type <Text strong>{typeText}</Text> to confirm.
              <br/>
              <br/>
              <Input onChange={handleType}/>
              <br/>
            </>
          )}
      </Modal>
    </>
  );
}
