import config from 'react-global-configuration';
import prod from './prod';
import dev from './dev';
import local from './local';

if (window.location.hostname === 'localhost') {
    config.set(prod, {freeze: false});
    config.set(local, {assign: true});
} else if (window.location.hostname.includes('dev')) {
    config.set(prod, {freeze: false});
    config.set(dev, {assign: true});
} else {
    config.set(prod);
}