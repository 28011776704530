import React, { useState } from 'react';
import { Table, Accordion, Icon } from 'semantic-ui-react';
import moment from 'moment';

export default function TaskLogs(props) {
  const { logs } = props;
  const [activeIndex, setActiveIndex] = useState(-1);

  function handleClick(e, titleProps) {
    const { index } = titleProps;
    setActiveIndex(activeIndex === index ? -1 : index);
  }

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={2}>Task logs</Table.HeaderCell>
          <Table.HeaderCell width={10}></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        { logs && logs.map((row, index) => (
          <Table.Row verticalAlign='top' key={index} id={index} warning={row.level === 'warning' || row.details} error={row.level === 'error'}>
            <Table.Cell>
              {moment(row.created_at).format('YYYY/MM/DD HH:mm:ss')}
            </Table.Cell>
            <Table.Cell>
              { row.details ? (
                <Accordion>
                  <Accordion.Title
                    active={activeIndex === index}
                    index={index}
                    onClick={handleClick}
                  >
                    <Icon name='dropdown'/>
                    {row.message}
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === index}>
                    { row.details && row.details.map((detail, i) => (<>{detail}<br/></>))}
                  </Accordion.Content>
                </Accordion>
              ) : (<>{row.message}</>)}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
