import React, {useEffect, useRef, useState} from 'react';
import { Button, Popconfirm, Table } from 'antd';
import apiOntologies from '../../api/apiOntologies';
import { NotificationManager } from 'react-notifications';
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { ONTOLOGIES_MANAGEMENT } from "../../shared/data/permissions";
import SearchFilterDropdown from '../../shared/Components/Table/SearchFilterDropdown';

export default function ChangeRequests({ auth }) {
    const searchInput = useRef();
    const searchIcon = useRef(); 
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const userHasMergePermissions = auth.permissions.includes(ONTOLOGIES_MANAGEMENT);

    async function onRemoveChangeSet(name) {
      try {
        setLoading(true);
        const { data } = await apiOntologies.deleteEDMChangeRequest(name);
        const { items } = data;
        setData(items)
      } catch (error) {
        NotificationManager.error(error.message);
      } finally {
        setLoading(false);
      }
    }

    const openInNewTab = (url) => {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    }

    function selectSearchFilter() {
      setTimeout(() => searchInput.current.select());
    }

    function handleSearch(selectedKeys, confirm) {
      confirm();
    }

    function handleReset(clearFilters) {
      clearFilters();
    }

    function searchFilter(value, record) {
      const loweredValue = value.toLowerCase();
      return (record.name || '').toLowerCase().includes(loweredValue);
    }

    document.addEventListener('keydown', (e) => {
      let charCode = String.fromCharCode(e.which).toLowerCase();
      if ((e.metaKey || e.ctrlKey) && charCode === 'f') onControlFPress(e);
    });
  
    function onControlFPress(e) {
      if (!searchInput.current || !searchInput.current.state.focused) {
        setTimeout(() => searchIcon.current.click());
        setTimeout(() => searchInput.current.focus(), 100);
        e.returnValue = false;
      }
    }

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        width: '75%',
        sorter: (a, b) => a.name.localeCompare(b.name),
        onFilter: searchFilter,
        onFilterDropdownVisibleChange: visible => {
            if (visible) selectSearchFilter();
        },
        filterIcon: filtered => <SearchOutlined ref={searchIcon} style={{ color: filtered ? '00c2b2' : '#454444' }} />,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <SearchFilterDropdown
            multiple
            inputRef={searchInput}
            selectedKeys={selectedKeys}
            setSelectedKeys={setSelectedKeys}
            handleSearch={handleSearch}
            handleReset={handleReset}
            confirm={confirm}
            clearFilters={clearFilters}
          />
        ),
        render: (name => name)
      },
      {
        title: '',
        dataIndex: 'sourceCodeLink',
        width: '10%',
        render: (sourceCodeLink) => (
          <Button
            title='Open source code'
            disabled={!userHasMergePermissions}
            onClick={() => openInNewTab(sourceCodeLink)}
          >View</Button>
        ),
      },
      {
        title: '',
        dataIndex: 'changeRequestName',
        width: '5%',
        render: (changeRequestName, record) => (
          <>
            <Popconfirm disabled={!userHasMergePermissions} title={`Remove ${record['name']} change set?`}
              onConfirm={() => onRemoveChangeSet(changeRequestName)} okText='Remove'
              cancelText='Cancel'>
              <DeleteOutlined style={{cursor: 'pointer'}} title='Delete change request'/>
            </Popconfirm>
          </>
        ),
      }
    ];

    useEffect(() => {
        document.title = 'Change requests';
        fetchData();
        // eslint-disable-next-line
    }, []);

    async function fetchData() {
      try {
        setLoading(true);
        const { data } = await apiOntologies.getEDMChangeRequests();
        const { items } = data;
        setData(items);
      } catch (error) {
        NotificationManager.error(error.message);
      } finally {
        setLoading(false);
      }
    }

    return (
      <>
        <br/><br/>
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          rowKey={record => record.name}
          size='middle'
          tableLayout='fixed'
          pagination={{
              defaultPageSize: 10,
              pageSizeOptions: [10, 20, 50, 100],
              showSizeChanger: true,
              position: ['bottomRight']
          }}
        />
      </>
    )
}
