import React, { useState } from "react";
import apiEnrichments from "../../../api/apiEnrichments";
import ColumnsTableEditor from "./ColumnsTableEditor";
import { saveAs } from "file-saver";
import { NotificationManager } from "react-notifications";
import ExportButton from "../../../common/ExportButton";

export default function ColumnsEditor(props) {
  const { columns, metadataStage, enrichmentName } = props;

  const [isExporting, setIsExporting] = useState(false);

  async function onExport() {
    setIsExporting(true);
    try {
      const response = await apiEnrichments.exportSignals(
        metadataStage,
        enrichmentName
      );
      saveAs(response.data, `${enrichmentName}_signals.csv`);
    } catch (error) {
      NotificationManager.error(error.message);
    } finally {
      setIsExporting(false);
    }
  }

  return (
    <div>
      <div style={{ height: "40px", marginBottom: "10px" }}>
        <ExportButton
          title="Export Signals"
          confirmTitle="Export signals?"
          onClick={onExport}
          loading={isExporting}
        />
      </div>
      <ColumnsTableEditor {...props} />
    </div>
  );
}
