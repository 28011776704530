import React from 'react';
import { Select, Button, Row, Col } from 'antd';
import { Segment } from 'semantic-ui-react';
import { ClearOutlined } from '@ant-design/icons';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const SortableItem = SortableElement(({ value, id, options, placeholder, onRemove, onChange, key, isDisabled }) => (
    <li style={{padding:"5px",listStyle:"none"}}>
  <Segment id={id}>
    <Row id={id} key={key} style={{ padding: 3 }}>
      <Col span={23}>
        <Select
          size='small'
          key={key}
          style={{ width: '100%' }}
          allowClear
          mode='tags'
          placeholder={placeholder}
          optionFilterProp='children'
          onChange={(v) => onChange(v, id)}
          value={value}
          disabled={isDisabled}
        >
          {options.map(element => <Select.Option key={element} value={element}>{element}</Select.Option>)}
        </Select>
      </Col>
      <Col span={1} style={{ paddingLeft: 5 }}>
        <Button disabled={isDisabled} type='text' shape='circle' icon={<ClearOutlined/>} size='small' onClick={() => onRemove(id)}/>
      </Col>
    </Row>
  </Segment>
    </li>
));

const Sortable_Container = SortableContainer(({children}) => <ul>{children}</ul>);

export default function ListOfListsField({ selectableItems, value: lists, placeholder, addLabel, id, allowAdditions, onChange, disabled }) {
  function onItemChage(value, index) {
    const newLists = [...lists];
    newLists[index] = value;
    
    onChange(newLists);
  }

  function addEmptyList() {
    const newLists = [...lists];
    newLists.push([]);

    onChange(newLists);
  }

  function removeList(index) {
    let newLists = [...lists];

    if (newLists.length > 1) {
      newLists.splice(index, 1);
    } else {
      newLists = [];
      newLists.push([]);
    }

    onChange(newLists);
  }

  function arrayMove(array, from, to) {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;
  }

  function onSortStart() {
    document.body.style.cursor='s-resize';
  }

  function onSortEnd({ oldIndex, newIndex }) {
    document.body.style.cursor='default';

    const newLists = arrayMove(lists, oldIndex, newIndex);
    onChange(newLists);
  }

  function shouldCancelSort({ target }) {
    const { className } = target;
    return typeof className === 'string' ? !className.includes('segment') : true;
  }
  
  return (
    <>
      <Sortable_Container
        lockAxis='y'
        onSortStart={onSortStart}
        onSortEnd={onSortEnd}
        shouldCancelStart={shouldCancelSort}
        useWindowAsScrollContainer={true}
      >
        { lists.map((list, index) => (
          <SortableItem
            key={list.length > 0 ? list.join('') : index}
            index={index}
            id={index}
            value={list}
            options={selectableItems.sort((a, b) => (a > b) ? 1 : -1)}
            placeholder={placeholder}
            onRemove={removeList}
            onChange={onItemChage}
            allowAdditions={allowAdditions}
            isDisabled={disabled}
          />
        ))}
      </Sortable_Container>
      <Button disabled={disabled} size='small' onClick={addEmptyList}>{addLabel || 'Add list'}</Button>
    </>
  );
}
