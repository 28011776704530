/* eslint-disable import/no-anonymous-default-export */
import apiClient from "./apiClient";

export default {
  getEnrichmentMetadata: (stage, enrichmentName) =>
    apiClient.get(
      `/enrichments_metadata/${stage}/${encodeURIComponent(enrichmentName)}`
    ),
  emliteUpdateEnrichmentMetadata: async (
    stage,
    enrichmentName,
    user_name,
    user_mail,
    commit_message,
    metadata
  ) => {
    const { data } = await apiClient.put(
      `/enrichments_metadata/${stage}/${encodeURIComponent(enrichmentName)}`,
      {
        enrichment_name: enrichmentName,
        user_name,
        user_mail,
        commit_message,
        metadata,
      }
    );

    return data;
  },
  exportTenantEnrichments: (tenantName, params = {}) =>
    apiClient.get(
      `/client_enrichments/${tenantName}?type=csv&timestamp=${new Date().getTime()}`,
      { responseType: "blob", params }
    ),
  exportEnrichments: (metadataStage, params) =>
    apiClient.get(
      `/enrichments_metadata/${metadataStage}?fileResponse=true&timestamp=${new Date().getTime()}`,
      { responseType: "blob", params }
    ),
  exportSignals: (stage, enrichmentName) =>
    apiClient.get(
      `/enrichments_metadata/${stage}/${encodeURIComponent(
        enrichmentName
      )}/columns?fileResponse=true&timestamp=${new Date().getTime()}`,
      { responseType: "blob" }
    ),
  getEnrichmentsEstimate: (stage, params) =>
    apiClient.get(`/estimates/enrichments_metadata/${stage}`, { params }),
  exportEnrichmentsDataSample: (stage, service_id) =>
    apiClient.get(`/enrichments/${stage}/${service_id}?type=csv`, {
      responseType: "blob",
    }),
  removeEnrichment: (
    envTable,
    enrichmentId,
    removeData,
    removeTenansSettings
  ) =>
    apiClient.delete(`/enrichments_metadata/${envTable}/${enrichmentId}`, {
      params: {
        remove_data: removeData,
        remove_tenans_settings: removeTenansSettings,
      },
    }),
  getLabels: () => apiClient.get("/enrichments_labels"),
  getProjectUsecases: () => apiClient.get("/project_usecases"),
  clearCache: (envTable, enrichmentId) =>
    apiClient.delete(`/enrichments/${envTable}/${enrichmentId}/clear_cache`),
  productize: (envTable, enrichmentId) =>
    apiClient.put(
      `/enrichments_metadata/${envTable}/${enrichmentId}/productize`
    ),
  getAsConfigEnrichmentLogic: (stage, enrichmentName) =>
    apiClient.get(
      `/enrichments_metadata/${stage}/${encodeURIComponent(
        enrichmentName
      )}/logic`
    ),
};
