import React from 'react';
import { Popover } from 'antd';
import { MoreOutlined } from '@ant-design/icons';

export default function TableMore({ children }) {
  return (
    <Popover content={children}>
      <MoreOutlined style={{ cursor: 'pointer' }}/>
    </Popover>
  );
}
