import React from 'react';
import {Button, Popconfirm, Tag} from 'antd';
import { NotificationManager } from 'react-notifications';
import { CopyOutlined, ReloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import CopyableInput from '../../shared/Components/CopyableInput';
import { parseAndFormatDate } from '../../common/utils';


const buttonStyle = { marginLeft: 8 };
const expiredTagStyle = {marginLeft: 8, marginBottom: 5};

export default function Token({ token, loading, load_api_token, api_token_expiration_date, api_token_locked }) {
  function onInputClick() {
    onCopy();
  }

  function onCopy() {
    var apiToken = document.querySelector('.token');
    apiToken.select();
    document.execCommand('copy');

    NotificationManager.success('Token copied to your clipboard');
  }

  async function onNewToken() {
    await load_api_token(true);
    NotificationManager.success('New token has been created');
  }

  function is_token_expired() {
    return api_token_expiration_date && parseAndFormatDate(Date.now(), true) >= api_token_expiration_date;
  }

  return <>
    <div hidden={!api_token_locked}>
      <h3><ExclamationCircleOutlined /> API usage has been locked for this account.</h3>
    </div>

    <div hidden={api_token_locked}>
      <div>
        <h3 hidden={loading}>Expiration date: {parseAndFormatDate(api_token_expiration_date)}
          <Tag style={expiredTagStyle} color="volcano" visible={is_token_expired()}>EXPIRED</Tag>
        </h3>
      </div>
      <CopyableInput
        className='token'
        loading={loading}
        value={token}
        onClick={onInputClick}
        emptyValue='Click to generate token'
      />
      <Button
        title='Copy'
        style={buttonStyle}
        icon={<CopyOutlined/>}
        onClick={onCopy}
        disabled={loading || !token}
      />
      <Popconfirm
        placement='bottom'
        title={<div>Are you sure you want to create a new token?<br></br>This will invalidate your previous token</div>}
        onConfirm={onNewToken}
        okText='Yes'
        cancelText='No'
        disabled={loading || !token}
      >
          <Button
          title='Create New Token'
          style={buttonStyle}
          icon={<ReloadOutlined/>}
          disabled={loading || !token}
        />
      </Popconfirm>
    </div>
  </>;
}
