import React from 'react';
import { Container, Table } from 'semantic-ui-react';

export default function TaskErrors(props) {
  const { errors } = props;

  return (
    <Container>
      <Table celled>
        <Table.Header>
          <Table.Row>
              <Table.HeaderCell>Error</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          { errors && errors.filter((log) => log.level !== "info").map((error, index) => (
            <Table.Row key={index}>
              <Table.Cell>{error.message}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Container>
  );
}
