import { Form, Select, Button, Input, Checkbox } from "antd";
import { Icon, Modal } from "semantic-ui-react";
import { filter, find, last, map } from "lodash";
import { useEffect, useState } from "react";
import { TagEditor } from "./TagEditor";
import { useQuery } from "react-query";
import { tagsApi } from "./tagsApi";
import { produce } from "immer";

const intialAddTagsState = {
  open: false,
  data: {
    name: "",
    description: "",
    multiple_choice: false,
  },
};

export function TagsEditor({ metadataStage, tags, onChange, onDelete }) {
  const [addTagsModalState, setAddTagsModalState] = useState(intialAddTagsState);

  const { data: allTags, isLoading } = useQuery({
    queryKey: ["tagsApi.getAllTags", metadataStage],
    queryFn: () => tagsApi.getAllTags(metadataStage),
  });

  const handleOk = async () => {
    await tagsApi.createTag(metadataStage,addTagsModalState.data);
    onChange(addTagsModalState.data.name, []);
    handleClose();
  };

  const handleClose = () => {
    setAddTagsModalState(intialAddTagsState);
  };

  useEffect(() => {
    if (!allTags) return;


    const matchingTag = allTags[addTagsModalState.data.name];
    setAddTagsModalState(produce(d => {
      d.data.description = matchingTag?.description ?? "";
      d.data.multiple_choice = matchingTag?.multiple_choice ?? false;
    }));
  }, [allTags, addTagsModalState.data.name]);

  return (
    <div>
      {map(tags, (tag, i) => {
        const tagName = Object.keys(tag)[0];
        const values = tag[tagName];
        const tagType =  allTags?.[tagName]?.type;

        return (
          <TagEditor
            key={i}
            metadataStage={metadataStage}
            tagType={tagType}
            tagName={tagName}
            values={values}
            onChange={(nextValues) => {
              onChange(tagName, nextValues);
            }}
            onDelete={() => {
              if (
                window.confirm(`Remove tag "${tagName}" from this enrichment?`)
              ) {
                onDelete(tagName);
              }
            }}
          />
        );
      })}
      <Button
        onClick={() =>
          setAddTagsModalState(
            produce((d) => {
              d.open = true;
            })
          )
        }
      >
        Add tag
      </Button>
      <Modal
        title="Add Tag"
        open={addTagsModalState.open}
        onClose={handleClose}
      >
        <Modal.Header>Add Tag</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Item label="Name">
              <Select
                mode="tags"
                allowClear
                showSearch
                loading={isLoading}
                value={addTagsModalState.data.name ? [addTagsModalState.data.name] : []}
                onChange={(values) => {
                  setAddTagsModalState(
                    produce((d) => {
                      d.data.name = last(values);
                    })
                  );
                }}
              >
                {map(Object.keys(allTags ?? {}), (op) => (
                  <Select.Option key={op} value={op} label={op}>
                    {op}
                  </Select.Option>
                ))}
                </Select>
            </Form.Item>
            <Form.Item label="Description">
              <Input.TextArea
                rows={4}
                value={addTagsModalState.data.description}
                onChange={(e) => {
                  const value = e.target.value;
                  setAddTagsModalState(
                    produce((d) => {
                      d.data.description = value;
                    })
                  );
                }}
              />
            </Form.Item>
            <Form.Item label="Multiple Choice" tooltip="Enable if the tag might have more than a single value">
              <Checkbox
                checked={addTagsModalState.data.multiple_choice}
                onChange={(e) => {
                  const checked = e.target.checked;
                  setAddTagsModalState(
                    produce((d) => {
                      d.data.multiple_choice = checked;
                    })
                  );
                }}
              />
            </Form.Item>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color="grey" inverted onClick={handleClose}>
            <Icon name="cancel" /> Cancel
          </Button>
          <Button basic color="green" inverted onClick={handleOk}>
            <Icon name="checkmark" /> Apply
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}
