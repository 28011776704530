export default {
  apiDomain: "/",
  s3DefaultFileUploadDir: "enrichments/Static_Backup/",
  logoUrl: "https://avatars3.githubusercontent.com/u/48208778",
  auth0Domain: "explorium.auth0.com",
  auth0Audience: "backoffice.api",
  auth0clientID: "kSatgMGtvoonE1APEaPampIruISHTRyT",
  rolesPayloadKey: "user_roles",
  primaryStage: "develop",
  defaultSampleProjectLists: ["project_list.json"],
  iconsCdnUrl: "https://d6xed0kfxpt69.cloudfront.net",
};
