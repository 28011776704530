import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Label, Divider, Segment, Header, Confirm, Icon, Button, Message,Table,Loader } from 'semantic-ui-react';
import { Select, Form, Input } from 'antd';
import ReactJson from 'react-json-view';

import { NotificationManager } from 'react-notifications';

import {isProtectedTenant, tenantsTypes} from '../../shared/data/tenants';
import {METADATA_PROTECTED_WRITE} from "../../shared/data/permissions";
import history from '../../history';
import { updateTenant, removeTenant, fetchTenant, addTenantSettings } from './../../redux/actions/configsActions';
import SaveButton from '../../shared/fields/SaveButton';
import CancelButton from '../../shared/fields/CancelButton';
import RemoveButton from '../../shared/fields/RemoveButton';
import PopupHint from '../../shared/fields/PopupHint';
import CopyTenantsSettings from './CopyTenantsSettings';
import CopyableInput from "../../shared/Components/CopyableInput";



export default function TenantView(props) {
  const { tenantName } = props.match.params;
  const tenant = useSelector(state => state.configsReducer.tenant);
  const isLoading = useSelector(state => state.configsReducer.isLoading);
  const [changed, setChanged] = useState(false);
  const [tenantData, setTenantData] = useState({});
  const [isAddingSettings, setIsAddingSettings] = useState(false);
  const dispatch = useDispatch();
  const { config, tenant_id: tenantId, tags = [], tenant_type, users: users } = tenantData;
  const permissions = useMemo(() => props.auth.permissions, [props.auth.permissions]);
  const canEditInfo = useMemo(() => {
      if (permissions === undefined)
          return false;
      return !isProtectedTenant(tenant) || permissions.includes(METADATA_PROTECTED_WRITE);
  }, [tenantName, permissions]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [tenantName]);


  useEffect(() => {
    setTenantData(tenant);
    // eslint-disable-next-line
  }, [tenant]);

  function fetchData() {
    dispatch(fetchTenant(tenantName));
  }

  function onConfigEdit({ updated_src }) {
    setChanged(true);
    setTenantData({ ...tenantData, config: updated_src });
  }

  function onSave() {
    setChanged(false);
    dispatch(updateTenant(tenantName, tenantData));
  }

  function addSettings() {
    dispatch(addTenantSettings(tenantName));
    setIsAddingSettings(false);
  }

  function onRemoveConfirm() {
    dispatch(removeTenant(tenantName));
    setTimeout(() => { history.push('/tenants'); }, 1500);
  }

  function onCancel() {
    setChanged(false);
    fetchData();
  }

  function onTagsChange(tags) {
    setChanged(true);
    setTenantData({ ...tenantData, tags });
  }

  function onTenantTypeChange(tenant_type) {
    setChanged(true);
    setTenantData({ ...tenantData, tenant_type });
  }

  function getFormatDate(date){
     const dateObj= new Date(date);
      return `${dateObj.getDate()}/${dateObj.getMonth()+1}/${dateObj.getFullYear()} , ${dateObj.getUTCHours()}:${dateObj.getUTCMinutes()}`
  }
    function onCopy(className) {
        var valueElement = document.querySelector(`.${className}`);
        valueElement.select();
        document.execCommand('copy');

        NotificationManager.success('Secret value copied to your clipboard');
    }

  return (
    <Segment loading={isLoading}>
      <Label>
        Id: <Label.Detail>{tenantId}</Label.Detail>
      </Label>
      {(tenantName !== 'default_tenant' && canEditInfo) && (
        <RemoveButton onClick={onRemoveConfirm} loading={isLoading} confirmHeader={`You are going to remove tenant "${tenantName}"`}/>
      )}
      <CopyTenantsSettings
        allowConfig
        allowEnrichments
        floated='right'
        tenantId={tenantId}
        tenantName={tenantName}
        loading={isLoading}
        disabled={changed}
        config={config}
        hint='Copy tenant settings'
      />
        {canEditInfo && <PopupHint content='Update enrichments settings'>
            <Button
                basic
                size='mini'
                floated='right'
                disabled={isLoading}
                onClick={() => setIsAddingSettings(true)}
            ><Icon name='refresh'/> Update management settings</Button>
        </PopupHint>}
      <CancelButton title='Revert' disabled={!changed} onClick={onCancel} hideWhenDisabled/>
      <SaveButton disabled={!changed} onClick={onSave} loading={isLoading} hideWhenDisabled confirmHeader={`You are going to update tenant "${tenantName}"`}/>
      <Divider/>
      <Label>
        System Users:
      </Label>
        <Table>
            <Table.Header>

                <Table.Row>
                    <Table.HeaderCell width={2} className='name'>Name</Table.HeaderCell>
                    <Table.HeaderCell width={2} className='key'>Key</Table.HeaderCell>
                    <Table.HeaderCell width={2} className='createdAt'>Created At</Table.HeaderCell>

                </Table.Row>
            </Table.Header>

            <Table.Body>
                { users && users.map((user, index) => (
                    <Table.Row key={index} id={index}>
                        <Table.Cell className='name'>{user.name}</Table.Cell>
                        <Table.Cell className='key' ><CopyableInput title='Secret value' value={user.key} loading={false} className={`key-${index}`} onClick={onCopy} style={{backgroundColor:"white"}}/></Table.Cell>
                        <Table.Cell className='createdAt'>{getFormatDate(user.created_at)}</Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
      <Form.Item label='Tags'>
        <Select mode='tags' style={{ width: 240 }} placeholder='Enter Tags' value={tags} onChange={onTagsChange} disabled={!canEditInfo}>
          {tags.map(tag => <Select.Option key={tag}>{tag}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item label='Tenant Type'>
        <Select style={{ width: 240 }} placeholder='Select Type' value={tenant_type} onChange={onTenantTypeChange} disabled={!canEditInfo}>
          {tenantsTypes.map(el => <Select.Option key={el}>{el}</Select.Option>)}
        </Select>
      </Form.Item>
      <Divider/>

      <Label>
        Config:
      </Label>
      <Divider hidden/>
      <ReactJson
          disabled={true}
        src={config}
        collapsed={2}
        name={false}
        onEdit={canEditInfo ? onConfigEdit : false}
        onAdd={canEditInfo ? onConfigEdit : false}
        onDelete={canEditInfo ? onConfigEdit : false}
      />

      <Confirm
        header={(<Header size='tiny'>You are going to add enrichments settings to tenant: {tenantName}</Header>)}
        content={<Message warning><Message.Header>Are you sure?</Message.Header><p>New enrichments settings will be assigned to the tenant with default values. Existing settings will remain unchanged.</p></Message>}
        open={isAddingSettings}
        onCancel={() => setIsAddingSettings(false)}
        onConfirm={addSettings}
      />
    </Segment>
  );
}
