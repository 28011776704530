import React, { Component } from 'react'

class Callback extends Component {
  componentDidMount() {
    setTimeout(() => {
      window.location.href = '/catalog'
    }, 1000)
  }

  render() {
    return <h5>loading...</h5>
  }
}
export default Callback;
