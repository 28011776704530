import { Button, Select, Form } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Icon, Modal } from "semantic-ui-react";
import { useEffect, useState } from "react";
import { last, map } from "lodash";

// getFieldValues: (fieldName) => Array<{value: any, label: string}>;
export function CustomTableFilter({ allFieldNames, getFieldValues, onApply }) {
  const [open, setOpen] = useState(false);
  const [fieldName, setFieldName] = useState(null);
  const [fieldValues, setFieldValues] = useState([]);

  // Reset
  useEffect(() => {
    if (!open) {
      setFieldName(null);
      setFieldValues([]);
    }
  }, [open]);

  function handleCancel() {
    setOpen(false);
  }

  function handleOk() {
    onApply(fieldName, fieldValues);
    setOpen(false);
  }

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        icon={<PlusOutlined />}
      >
        Add Custom Filter
      </Button>
      <Modal title="Custom Filter" open={open} onClose={handleCancel}>
        <Modal.Header>Custom Filter</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Item label="Name">
              <Select
                mode="multiple"
                allowClear
                showSearch
                value={fieldName ? [fieldName] : []}
                onChange={(values) => {
                  setFieldName(last(values) ?? null);
                }}
              >
                {map(allFieldNames, (op) => (
                  <Select.Option key={op} value={op} label={op}>
                    {op}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Values">
              <Select
                mode="tags"
                allowClear
                showSearch
                value={fieldValues}
                onChange={(values) => {
                  setFieldValues(values);
                }}
              >
                {fieldName &&
                  map(getFieldValues(fieldName), (op) => (
                    <Select.Option key={op} value={op.value} label={op.label}>
                      {op.label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color="grey" inverted onClick={handleCancel}>
            <Icon name="cancel" /> Cancel
          </Button>
          <Button basic color="green" inverted onClick={handleOk}>
            <Icon name="checkmark" /> Apply
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}
