import {
  FETCH_REQUEST,
  FETCH_FAILURE,
  FETCH_TASKS_SUCCESS,
  FETCH_TASK_SUCCESS,
} from '../actions/taskActions';

export default (state = { tasks: [], task: {} }, action) => {
  switch (action.type) {
    case FETCH_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_TASKS_SUCCESS: {
      return {
        tasks: action.tasks,
        isLoading: false,
      };
    }
    case FETCH_TASK_SUCCESS: {
      return {
        task: action.task,
        isLoading: false,
      };
    }
    case FETCH_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
