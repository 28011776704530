import React from 'react';
import styled from 'styled-components';

import { exploriumColors } from '../../../../common/colors';

const ServiceName = styled.a`
  color: ${exploriumColors.exploriumGreenLink};
  font-size: 10px;
`;

const DisplayName = styled.a`
  color: ${exploriumColors.exploriumBlack};
  font-size: 14px;
  font-weight: 500;
`;

const DisplayNameBlock = styled.span`
  display: inline-block;
`;

const SignalsCount = styled.span`
  color: ${exploriumColors.exploriumDarkGrey};
  background-color: ${exploriumColors.exploriumLightGrey};
  font-size: 12px;
  margin: 4px 40px 2px 8px;
  padding: 0 4px;
  border-radius: 3px;
`;

const Description = styled.div`
  color: ${exploriumColors.exploriumGrey};
  font-size: 12px;
`;

export default function NameAndId({ name, id, description, href, signalsCount }) {

  return (
    <div>
      <DisplayNameBlock><DisplayName href={href}>{name}</DisplayName>{signalsCount && <SignalsCount>Signals ({signalsCount})</SignalsCount>}</DisplayNameBlock>
      {description && <Description>{description}</Description>}
        {href && <div><ServiceName href={href}>{id}</ServiceName></div>}
    </div>
  );
}
