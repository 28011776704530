import React from 'react';
import { Breadcrumb } from 'semantic-ui-react';

import { unquote, capitalize } from '../../common/utils';

export default function Breadcrumbs(props) {
  const locations = props.location.pathname.split('/').filter(Boolean);

  return (
    <Breadcrumb>
      {locations.map((path, index) => (
        <Breadcrumb.Section
          key={path}
          active={index === locations.length - 1}
          href={`/${locations.slice(0, index + 1).join('/')}`}
        >
          <>{unquote(capitalize(path))}</>
          {index < locations.length - 1 && <Breadcrumb.Divider icon='right angle'/>}
        </Breadcrumb.Section>
      ))}
    </Breadcrumb>
  )
}
