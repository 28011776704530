import React, { useEffect } from 'react';
import { Switch, Modal, Button, Select, Form, Input } from 'antd';

import InfoTooltip from '../../shared/Components/InfoTooltip';
import { ChangeSetSelector } from './ChangeSetSelector';

const layout = { labelCol: { span: 6 }, wrapperCol: { span: 16 } };

const defaultOntology = {
  name: null,
  parent: null,
  dtype: null,
  description: null,
  enrichable: false,
  displayable: false,
  pii: false,
  reduce: '',
  generic: false,
  changeSet: null
};

export default function OntologyModalForm({
                                            visible,
                                            onSubmit,
                                            onCancel,
                                            title,
                                            initialValues,
                                            okText = 'Submit',
                                            editing = false,
                                            parents,
                                            availableChangeRequests,
                                            setChangeRequest,
                                            currentChangeRequest,
                                          }) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({ ...initialValues, changeSet: currentChangeRequest });
    }

    // eslint-disable-next-line
  }, [initialValues]);

  function onFinish(values) {
    form.setFieldsValue(defaultOntology);
    onSubmit(Object.assign(defaultOntology, values));
  }

  const checkName = (_, value) => {
    if (editing) {
      return Promise.resolve();
    }

    if (!value) {
      return Promise.reject();
    }

    const regex = /^[0-9A-Z_]+$/;

    if (value.match(regex)) {
      return Promise.resolve();
    } else {
      return Promise.reject('Allowed symbols: A-Z 0-9 _');
    }
  };

  return (
    <Modal
      onCancel={onCancel}
      title={title}
      visible={visible}
      destroyOnClose
      footer={[
        <Button onClick={onCancel} key={'cancel'}>Cancel</Button>,
        <Button form='newOntology' key='submit' htmlType='submit' type='primary'>{okText}</Button>
      ]}
    >
      <Form name='newOntology' form={form} onFinish={onFinish} initialValues={defaultOntology}{...layout}>
        <Form.Item style={{flex: 2, float: 'center'}} name='changeSet' label={'Change Set'} rules={[{required: true, message: 'Please select change set'}]}>
          <ChangeSetSelector onChange={setChangeRequest} availableChangeRequests={availableChangeRequests}/>
        </Form.Item>
        <Form.Item
          hidden={editing}
          label='Name'
          name='name'
          rules={[{validator: checkName}, {required: !editing, message: 'Please enter ontology name'}]}
        >
          <Input/>
        </Form.Item>
        <Form.Item label='Description' name='description'>
          <Input.TextArea/>
        </Form.Item>
        <Form.Item label='Parent' name='parent' rules={[{required: true, message: 'Please select ontology parent'}]}>
          <Select>{parents && parents.map(ontologyName => <Select.Option key={`parent-${ontologyName}`}
                                                                         value={ontologyName}>{ontologyName}</Select.Option>)}</Select>
        </Form.Item>

        <Form.Item label='Dtype' name='dtype' rules={[{required: true, message: 'Please select ontology dtype'}]}>
          <Select>
            <Select.Option key='str' value='str'>str</Select.Option>
            <Select.Option key='int' value='int'>int</Select.Option>
            <Select.Option key='float' value='float'>float</Select.Option>
            <Select.Option key='datetime' value='datetime'>datetime</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <span>
              Enrichable&nbsp;
              <InfoTooltip title='Is a column with this ontology allowed to be further enriched?'/>
            </span>
          }
          name='enrichable'
          valuePropName='checked'
        >
          <Switch/>
        </Form.Item>

        <Form.Item
          label={
            <span>
              Displayable&nbsp;
              <InfoTooltip title='Can the user see this ontology in the platform, or is it just for internal usage?'/>
            </span>
          }
          name='displayable'
          valuePropName='checked'
        >
          <Switch/>
        </Form.Item>

        <Form.Item
          label={
            <span>
              PII&nbsp;
              <InfoTooltip title='This ontology represents Personally Identifiable Information'/>
            </span>
          }
          name='pii'
          valuePropName='checked'
        >
          <Switch/>
        </Form.Item>

        <Form.Item
          label={
            <span>
              Generic&nbsp;
            </span>
          }
          name='generic'
          valuePropName='checked'>
          <Switch/>
        </Form.Item>
      </Form>
    </Modal>
  );
}
