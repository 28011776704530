import React, { useState } from 'react';
import { Label } from 'semantic-ui-react';
import { InputNumber, Space } from 'antd';

export default function Limit(props) {
  const [limit, setLimit] = useState();
  const {
    onStartEditing, onCancelEditing, onValueChange,
    value, defaultLimit, loading, editing, disabled
  } = props;

  const handleBlur = (e) => {
    const currentTarget = e.currentTarget;

    // Check the newly focused element in the next tick of the event loop
    setTimeout(() => {
      // Check if the new activeElement is a child of the original container
      if (!currentTarget.contains(document.activeElement)) {
        if (limit !== value) {
          saveEditedValue();
        } else {
          cancelEditing();
        }
      }
    }, 0);
  };

  function cancelEditing() {
    setLimit(null);
    onCancelEditing();
  }

  function startEditing() {
    if (disabled) return
    setLimit(value);
    onStartEditing();
  }

  function saveEditedValue() {
    onValueChange(parseInt(limit));
    cancelEditing();
  }

  return (
    <div>
      { editing ? (
        <Space direction='vertical' onBlur={handleBlur}>
          <InputNumber
            autoFocus
            loading={loading}
            min={0}
            step={1000}
            placeholder='Limit'
            defaultValue={limit}
            onChange={setLimit}
            onKeyUp={(e) => e.key === 'Escape' && cancelEditing()}
            onKeyDown={(e) => e.key === 'Enter' && saveEditedValue()}
          />
        </Space>
      ) : (
        <Label as='a' size='medium' onClick={startEditing} style={{cursor: disabled ? 'not-allowed' : 'auto'}}>
          {value ? (value).toLocaleString() : defaultLimit ? `default: ${(defaultLimit).toLocaleString()}` : 'no limit'}
        </Label>
      )}
    </div>
  );
}
