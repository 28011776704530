import {Button, Form, Input, Modal} from "antd";
import React from "react";

export default function CreateChangeSetModalForm({onConfirm, onCancel, visible, editing = false}) {
  const [form] = Form.useForm();
  const checkName = (_, value) => {
    if (editing) {
      return Promise.resolve();
    }

    if (!value) {
      return Promise.reject();
    }

    const regex = /^[0-9A-Z_]+$/;

    if (value.match(regex)) {
      return Promise.resolve();
    } else {
      return Promise.reject('Allowed symbols: A-Z 0-9 _');
    }
  };

  return (
    <Modal onCancel={onCancel}
      title={'Create Change Set'}
      onSubmit={onConfirm}
      visible={visible}
      destroyOnClose
      footer={[
        <Button onClick={onCancel} key='cancel'>Cancel</Button>,
        <Button form='newChangeSet' key='submit' htmlType='submit' type='primary'>{'Create'}</Button>
      ]}>
      <Form name='newChangeSet' form={form} onFinish={onConfirm}>
        <Form.Item
          label='Change Set:'
          name='changeSet'
          rules={[{validator: checkName}, {required: !editing, message: 'Please enter change set name'}]}
        >
          <Input/>
        </Form.Item>
      </Form>
    </Modal>
  );
}
