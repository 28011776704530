import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Segment, Progress, Divider, Icon, Label, Header, Loader, Button } from 'semantic-ui-react';

import { fetchTask } from '../../redux/actions/taskActions';
import TaskLogs from './TaskLogs';

export default function Task(props) {
  const { taskId } = props.match.params;
  document.title = `Task: ${taskId}`;
  const dispatch = useDispatch();

  const task = useSelector(state => state.taskReducer.task);
  const { type, started, updated, progress, hasLogs, terminated,
    metadata, logs = [], problematic_rows_file_url, problematic_rows_count } = task ? task : {};
  const isLoading = useSelector(state => state.taskReducer.isLoading);

  useEffect(() => {
    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 5000);

    return () => {
      clearInterval(intervalId);
    }
    // eslint-disable-next-line
  }, []);

  function fetchData() {
    dispatch(fetchTask(taskId));
  }

  return (
    <Segment>
      <Loader active={isLoading}/>
      <Progress
        warning={terminated}
        success={!terminated && !hasLogs && progress === 100}
        error={hasLogs}
        percent={progress}
        active={progress !== 100}
        attached='top'
      />
      <Label ribbon><Header size='medium'>{`${type} [${progress} %]`}</Header></Label>
      <br/><br/>
      <Label>
        <Icon name='calendar'/>
        Created:
        <Label.Detail>{started}</Label.Detail>
      </Label>
      <Label>
        <Icon name='calendar plus'/>
        Updated:
        <Label.Detail>{updated}</Label.Detail>
      </Label>
      <Divider/>

      { metadata && Object.keys(metadata).map(key => (
          <><Label key={key}>
            {key}:
            <Label.Detail key={key}>{metadata[key]}</Label.Detail>
          </Label><br/><br/></>
        ))
      }

      {problematic_rows_count > 0 && (
        <a href={problematic_rows_file_url}>
          <Button
            basic
            icon='file'
            size='small'
            label={`Download problematic rows (${problematic_rows_count})`}
          />
        </a>
      )}
      <TaskLogs logs={logs}/>
    </Segment>
  );
}
