import { find, map, size } from "lodash";
import { Select, Form, Button } from "antd";
import { useQuery } from "react-query";
import { tagsApi } from "./tagsApi";
import { useEffect, useMemo, useState } from "react";
import { produce } from "immer";
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import styled from "styled-components";
import { Icon, Modal } from "semantic-ui-react";
import AuthorsEditor from "../../../../shared/Components/AuthorsEditor";

export function TagEditor({
  metadataStage,
  tagType,
  tagName,
  values,
  onChange,
  onDelete,
}) {
  const { data: options, isLoading } = useQuery({
    queryKey: ["tagsApi.getAllTags", metadataStage],
    queryFn: () => tagsApi.getAllTags(metadataStage),
    select: (data) => {
      return data[tagName]?.options ?? [];
    },
  });

  const [optionsModalState, setOptionsModalState] = useState({
    open: false,
    tags: options ?? [], // string[]
  });

  const handleOk = () => {
    tagsApi.saveOptions(metadataStage, tagName, optionsModalState.tags);
    handleClose();
  };

  const handleClose = () => {
    setOptionsModalState(
      produce((d) => {
        d.open = false;
      })
    );
  };

  useEffect(() => {
    if (options !== undefined) {
      setOptionsModalState(
        produce((d) => {
          d.tags = options;
        })
      );
    }
  }, [options]);

  return (
    <Root>
      <Form.Item
        className="form-item"
        extra={
          <>
            <Button
              shape="circle"
              icon={<DeleteFilled />}
              size="small"
              onClick={onDelete}
              style={{ margin: "0 4px" }}
            />
            <Button
              shape="circle"
              icon={
                <EditFilled
                  onClick={() =>
                    setOptionsModalState(
                      produce((d) => {
                        d.open = true;
                      })
                    )
                  }
                />
              }
              size="small"
              style={{ margin: "0 4px" }}
            />
          </>
        }
        label={tagName}
      >
        {
          tagType === "users" && <AuthorsEditor
            multiple
            value={values}
            onChange={(values) => {
              onChange(values);
            }}
          />
        }
        {tagType !== "users" &&  <Select
          mode="multiple"
          allowClear
          showSearch
          value={values}
          onChange={(values) => {
            onChange(values);
          }}
          loading={isLoading}
        >
          {map(options, (value) => (
            <Select.Option key={value} value={value} label={value}>
              {value}
            </Select.Option>
          ))}
        </Select>}
      </Form.Item>
      <Modal open={optionsModalState.open} onClose={handleClose}>
        <Modal.Header>Edit "{tagName}" options</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Item label="Tags">
              <Select
                mode="tags"
                allowClear
                showSearch
                value={optionsModalState.tags}
                onChange={(values) => {
                  setOptionsModalState(
                    produce((d) => {
                      d.tags = values;
                    })
                  );
                }}
                loading={isLoading}
              >
                {map(options, (op) => (
                  <Select.Option key={op} value={op} label={op}>
                    {op}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color="grey" inverted onClick={handleClose}>
            <Icon name="cancel" /> Cancel
          </Button>
          <Button basic color="green" inverted onClick={handleOk}>
            <Icon name="checkmark" /> Apply
          </Button>
        </Modal.Actions>
      </Modal>
    </Root>
  );
}

const Root = styled.div`
  > .form-item .ant-select-selection-item {
    height: 27px;
    align-items: center;
  }
  > .form-item .ant-form-item-extra {
    display: flex;
    justify-content: flex-end;
    padding: 4px 0 0;
  }
`;
