import {
  START_REQUEST,
  FINISH_REQUEST,
  FETCH_ENRICHMENTS_METADATA_REQUEST,
  FETCH_ENRICHMENTS_METADATA_SUCCESS,
  FETCH_ENRICHMENTS_METADATAS_REQUEST,
  FETCH_ENRICHMENTS_DATA_REQUEST,
  FETCH_ENRICHMENTS_DATA_SUCCESS,
  FETCH_ENRICHMENTS_DATA_FAILURE,
  FETCH_ENRICHMENT_TENANTS_SETTINGS_SUCCESS,

} from '../actions/enrichmentsActions';

export default (state = { metaData: {}, hasMoreData: true, data: [], tenantsSettings: [] , isDataLoading: false, isDataFetched: false}, action) => {
  switch (action.type) {
    case START_REQUEST: {
      return { ...state, isLoading: true };
    }
    case FINISH_REQUEST: {
      return { ...state, isLoading: false };
    }
    case FETCH_ENRICHMENTS_METADATA_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_ENRICHMENTS_DATA_REQUEST: {
      return {
        ...state,
        isDataLoading: true,
      };
    }
    case FETCH_ENRICHMENTS_METADATAS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_ENRICHMENTS_METADATA_SUCCESS: {
      return {
        ...state,
        metaData: action.metaData,
        isLoading: false,
      };
    }
    case FETCH_ENRICHMENTS_DATA_SUCCESS: {
      return {
        ...state,
        data: action.data,
        hasMoreData: action.hasMoreData,
        isDataFetched: true,
        isDataLoading: false,
      };
    }
    case FETCH_ENRICHMENTS_DATA_FAILURE: {
      return {
        ...state,
        isDataLoading: false,
        isDataFetched: true,
      };
    }
    case FETCH_ENRICHMENT_TENANTS_SETTINGS_SUCCESS: {
      return {
        ...state,
        tenantsSettings: action.tenantsSettings,
      };
    }
    default:
      return state;
  }
};
