import React, { useEffect, useState, useRef } from 'react';
import Highlighter from 'react-highlight-words';
import { Table, Popover, Button, Popconfirm } from 'antd';
import { SearchOutlined, MoreOutlined, EditOutlined, DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { NotificationManager } from 'react-notifications';

import { pagination } from '../../shared/Components/Table/parameters';
import apiSecrets from '../../api/apiSecrets';
import SearchFilterDropdown from '../../shared/Components/Table/SearchFilterDropdown';
import CopyableInput from '../../shared/Components/CopyableInput';
import TagsView from '../../shared/Components/Enrichments/TagsView';
import SecretModalForm from './SecretModalForm';

export default function Secrets({ auth }) {
  const searchInput = useRef();
  const searchIcon = useRef();

  const [editFormVisible, setEditFormVisible] = useState(false);
  const [createFormVisible, setCreateFormVisible] = useState(false);

  const [editingSecret, setEditingSecret] = useState({});

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    document.title = 'Secrets';
    fetchData();
  }, []);

  function onCopy(className) {
    var valueElement = document.querySelector(`.${className}`);
    valueElement.select();
    document.execCommand('copy');

    NotificationManager.success('Secret value copied to your clipboard');
  }

  async function fetchData() {
    try {
      setLoading(true);

      const { data } = await apiSecrets.getSecrets();
      const { items } = data;

      setData(items);
    } catch (error) {
      NotificationManager.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  function handleSearch(selectedKeys, confirm) {
    confirm();
    setSearchText(selectedKeys[0]);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  }

  function selectSearchFilter() {
    setTimeout(() => searchInput.current.select());
  }

  async function onRemove(key) {
    try {
      setLoading(true);

      await apiSecrets.removeSecret(key);
      fetchData();
    } catch (error) {
      NotificationManager.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  function onEdit(secret) {
    setEditingSecret(secret);
    setEditFormVisible(true);
  }

  function onCreate() {
    setCreateFormVisible(true);
    setEditingSecret({ authors: [auth.profile.email] });
  }

  async function onSubmitCreating(secret) {
    try {
      setLoading(true);
      setCreateFormVisible(false);
      setEditingSecret({});

      await apiSecrets.addSecret(secret.key, secret);
      fetchData();
    } catch (error) {
      NotificationManager.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  async function onSubmitEditing(secret) {
    try {
      setLoading(true);
      setEditFormVisible(false);
      setEditingSecret({});

      await apiSecrets.updateSecret(secret.key, secret);
      fetchData();
    } catch (error) {
      NotificationManager.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  function onCancelEditing() {
    setEditingSecret({});
    setEditFormVisible(false);
  }

  const columns = [
    {
      title: 'Key',
      dataIndex: 'key',
      width: '30%',
      sorter: (a, b) => a.key.localeCompare(b.key),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilterDropdown
          multiple
          title='Key'
          inputRef={searchInput}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          handleSearch={handleSearch}
          handleReset={handleReset}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filterIcon: filtered => <SearchOutlined ref={searchIcon} style={{ color: filtered ? '00c2b2' : '#454444', fontSize: 20 }}/>,
      onFilterDropdownVisibleChange: visible => { if (visible) selectSearchFilter(); },
      render: (key) => searchText ? <Highlighter
        title='Secret key'
        autoEscape
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        textToHighlight={key ? key.toString() : ''}
      /> : <h4 title='Secret key'>{key}</h4>,
      onFilter: (value, record) => record.key ? record.key.toString().toLowerCase().includes(value.toLowerCase()) : '',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      width: '40%',
      render: (value, record) => (<CopyableInput title='Secret value' value={value} loading={loading} className={`key-${record.key}`} onClick={onCopy}/>),
      sorter: (a, b) => a.value.localeCompare(b.value),
    },
    // {
    //   title: 'Authors',
    //   dataIndex: 'authors',
    //   width: '26%',
    //   render: (authors) => <TagsView value={authors}/>,
    // },
    {
      title: '',
      dataIndex: 'id',
      width: '4%',
      render: (id, record) => (
        <Popover content={(
          <>
            <Popconfirm
              placement="leftBottom"
              title={`You are going to remove secret "${record.key}"`}
              onConfirm={() => onRemove(record.key)}
            >
              <Button id='remove' title='Remove secret' loading={loading}><DeleteOutlined/></Button>
            </Popconfirm>
            <Button id='edit' title='Edit secret' loading={loading} onClick={() => onEdit(record)}><EditOutlined/></Button>
          </>
        )} title=''>
          <MoreOutlined style={{ cursor: 'pointer' }}/>
        </Popover>
      ),
    }
  ];

  return (
    <>
      <br/><br/>
      <Button
        type='text'
        style={{flex: 1, float: 'right'}}
        onClick={onCreate}
      >Add secret<PlusCircleOutlined/></Button>

      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        rowKey={record => record.key}
        size='middle'
        tableLayout='fixed'
        pagination={{ ...pagination}}
      />

      <SecretModalForm
        title={`Create secret`}
        okText='Create'
        visible={createFormVisible}
        initialValues={editingSecret}
        onCancel={() => setCreateFormVisible(false)}
        onSubmit={onSubmitCreating}
      />
      <SecretModalForm
        editing
        title={`Edit secret`}
        okText='Save'
        visible={editFormVisible}
        initialValues={editingSecret}
        onCancel={onCancelEditing}
        onSubmit={onSubmitEditing}
      />
    </>
  );
}
