import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

const { Option } = Select;

function choose_value_dropdown(with_deployed_projects_only,index){
    let value_update=index
    if(!with_deployed_projects_only){
        if(index===1) {
            value_update = 2
        }
    }
    return value_update
}

function Dropdown(props){
  return(
      <Select
          style={{ width: props.width }}
          value={props.value===null? undefined:props.value}
          onChange={props.onChange}
          disabled={props.disabled}
          mode={props.isMultMode? "multiple":"default" }

      >
          {props.options.map((option,index) => (
              <Option value={props.value_as_index?choose_value_dropdown(props.with_deployed_projects_only,index):option.name} label={option.name} key={index} title={`${props.resource_name} ${option.desc}`}>
              {option.name}
            </Option>))
        }
      </Select>
  )
}
Dropdown.propTypes = {
  value: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
export function TagsDropdown({ value, disabled, onChange, width=120, resource_name='resource',isMultMode = true , value_as_index=false}) {
  return (
      <Dropdown options={[{name: "Premium", desc: "Premium"}, {name: 'Beta', desc: 'Beta'}, {name: "To deprecate", desc: "To deprecate"}]}
                value={value} onChange={onChange} width={width} disabled={disabled} resource_name={resource_name}
                isMultMode={isMultMode} value_as_index={value_as_index}/>
  );
}



export default function AvailabilityDropdown({ value, disabled, onChange, width=120, resource_name='resource',isMultMode = false , with_deployed_projects_only = true,value_as_index=true }) {
    let options=[{name:"Disable",desc: "is unavailable"},{name:'Deployed projects only',desc:"is only available in existing enrich and predict projects"},{name:"Active",desc:"is accessible for new projects"}];
    if (!with_deployed_projects_only){
        options=[{name:"Disable",desc: "is unavailable"},{name:"Active",desc:"is accessible for new projects"}];
    }
    return (
        <Dropdown options={options} value={value} onChange={onChange} width={width} disabled={disabled} resource_name={resource_name} isMultMode={isMultMode} value_as_index={value_as_index} with_deployed_projects_only={with_deployed_projects_only}/>
    );
}